import React, { useState } from "react";
import "../dashHome.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Close } from "@mui/icons-material";
import Vfd from "./Vfd";
import { NumericFormat } from "react-number-format";
import { userRequest } from "../../../requestMethod";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import {
  closePaymentModal,
  useFlutterwave,
} from "flutterwave-react-v3";

function Balance({ wallet, vfd, loading }) {
  
  const [fund, setFund] = useState();
  const [tobeFunded, SetFunded] = useState(0);
  const interest = Number(Math.ceil(1.4/100 * tobeFunded))
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const token = useSelector(
    (state) => state?.user?.currentUser?.token?.access_token
  );

  
  const config = {
    public_key: "FLWPUBK-6b229c62f215ffaf8625ba9ee8b0f65a-X",
    tx_ref: fund,
    amount: Number(tobeFunded) + Number(interest),
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: "user@gmail.com",
      phone_number: "070****",
      name: "john doe",
    },
    customizations: {
      title: "Pennytree",
      description: "Fund Wallet",
      logo: "https://downloads.intercomcdn.com/i/o/370991/b1d2d385b5c9f9820864f1f6/4dd765d69f9fd9dedb4738d9888ed24a.png",
    },
  };
  
  
  const handleFlutterPayment = useFlutterwave(config);

  const handleFlutterWave = (transaction_reference) => {
    config.tx_ref = transaction_reference;
    if (transaction_reference) {
      handleFlutterPayment({
        callback: (response) => {
          closePaymentModal(); // this will close the modal programmatically
        },
        onClose: () => {},
      });
    }
  };

  const getTransRef = async () => {
    const response = await userRequest.post(
      "api/v2/customer/transaction/fund/initiate",
      {
        amount: Number(tobeFunded),
        currency: "NGN",
        fund_target: "individual",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          source: "web",
          "x-api-key": "94684537",
        },
      }
    );
    setFund(response.data.data.transaction_reference);
    handleFlutterWave(response.data.data.transaction_reference);
  };


  // useEffect(() => {
  //   getTransRef();
  // },[]);

  return (
    <div className='w-[216px] h-[176px] rounded-[12px] green-gradient flex flex-col justify-center pl-[19px]'>
      <p className='text-[15px] text-black mb-[22px]'>Wallet Balance</p>
      {loading ? <Spinner color="black" className="mb-[22px]"/> :  <p className='text-[24px] text-black mb-[22px]'>
        {(
          <NumericFormat
            value={wallet.available_balance || 0.00}
            thousandSeparator=','
            prefix='₦'
            className='text-black bg-transparent'
            disabled
          />
        ) || "Loading"}{" "}
      </p> }
     
      <div>
        <button
          onClick={handleShow}
          className='w-[129px] h-[31px] rounded-[32px] bg-white border-none outline-none'
        >
          <p className='text-[#070707] text-[12px]'>Fund Wallet</p>
        </button>

        <Offcanvas
          show={show}
          onHide={handleClose}
          placement='end'
          className='py-[20px] pl-[30px] flex flex-col w-[505px]'
        >
          <Offcanvas.Header closeButton className='w-[100%]'>
            <Offcanvas.Title className='mt-[60px]'>Fund Wallet</Offcanvas.Title>
            <Close onClick={handleClose} className='mr-[-200px]' />
          </Offcanvas.Header>
          <Offcanvas.Body>
            <input
              type='number'
              placeholder='Enter Amount'
              className='w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] my-[72px]'
              onChange={(e) => SetFunded(Number(e.target.value))}
            />
            <button
              className='bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px]'
              onClick={() => getTransRef()}
            >
              Fund with Card
            </button>
            <div className='flex items-center justify-center flex-col mt-[28px]'>
              <p>Or</p>
              <p>
                Copy your virtual account number and make a direct bank
                transfer.{" "}
              </p>
              <div className='w-[421px] h-[52px] my-[20px]'>
                <Vfd vfd={vfd} />
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </div>
  );
}

export default Balance;
