import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Close } from "@mui/icons-material";
import { Input, Label, Modal, ModalBody, Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { userRequest } from "../../../requestMethod";
import { useSelector } from "react-redux";


function VerifyCanvas({ showVerifyCanvas, handleVerifyCanvas }) {
  const [showMain, setShowMain] = useState(true);
  const [showBVN, setShowBVN] = useState(false);
  const [bvnNumber,setBvnNumber] = useState("")
  const [verifyData, setVerifyData] = useState();
  const [apiComp, setApiComp] = useState(false);
  const [dob,setDob] = useState("")
  const [gender, setGender] = useState("");
  const navigate = useNavigate();
  const token = useSelector(
    (state) => state?.user?.currentUser?.token?.access_token
  );
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const secondToggle = () => {
      setModal(!modal)
      navigate(0);
    }

  const handleShowBVN = () => {
    setShowBVN(!showBVN);
    setShowMain(false);
  };

  const handleShowMain = () => {
    setShowMain(!showMain);
    setShowBVN(false);
  };

  const verifyBvn = async () => {
    setLoading(true);
    try {
      const response = await userRequest.post(
        "api/v2/customer/verification/verify-me",
        {
          type: "BVN",
          value: bvnNumber,
          dob: dob,
          gender: gender
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            source: "web",
            "x-api-key": "94684537",
          },
        }
      );
      setVerifyData(response.data.message);
      setApiComp(true)
      setLoading(false);
      toggle()
    } catch (error) {
      setVerifyData(error.response.data.message);
      setApiComp(true)
      setLoading(false);
      toggle()
    }
  };

 
  return (
    <Offcanvas
      show={showVerifyCanvas}
      onHide={handleVerifyCanvas}
      placement="end"
      className="py-[20px] pl-[30px] flex flex-col w-[505px]"
    >
      <Offcanvas.Header closeButton className="w-[100%]">
        <Offcanvas.Title className="mt-[60px]">
          Verify Your Account
        </Offcanvas.Title>
        <Close onClick={handleVerifyCanvas} className="mr-[-200px]" />
      </Offcanvas.Header>
      <Offcanvas.Body>
        {showMain && (
          <div>
            <button
              className="w-[90%] h-[50px] bg-[#4CC800] text-[#fff] font-semibold mb-[10px] hover:bg-[#fff] hover:text-[#4cc800] hover:border-[1px]"
              onClick={() => handleShowBVN()}
            >
              Verify BVN
            </button>
            <button className="w-[90%] h-[50px] bg-[#4CC800] text-[#fff] font-semibold mb-[10px] hover:bg-[#fff] hover:text-[#4cc800] hover:border-[1px]">
              Verify NIN
            </button>
          </div>
        )}
        {showBVN && (
          <div>
            <p onClick={() => handleShowMain()} className="mb-[20px] cursor-pointer">Back</p>
            <h1 className="text-[24px] font-bold">Verify your Account</h1>
            <p className="text-[12px] font-normal mb-[20px]">
              Please complete the information below to
              confirm your account
            </p>
            <div>
            <Label for="bvn" className="text-black">
              Enter BVN
            </Label>
            <Input
              id="bvn"
              name="bvn"
              type="text"
              placeholder=""
              className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              onChange={e => setBvnNumber(e.target.value)}
            />
            <Label For="dob" className="text-black">
          Date of Birth
        </Label>
        <input
          type="date"
          id="dob"
          name="dob"
          className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] px-[20px] mb-[20px]"
          onChange={e => setDob(e.target.value)}
        />
        <Label for="gender" className="text-black">
            Gender
          </Label>
          <Input
            type="select"
            id="gender"
            name="gender"
            className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
            onChange={e => setGender(e.target.value)}
          >
            <option value="">Gender</option>
            <option value="MALE">Male</option>
            <option value="FEMALE">Female</option>
          </Input>
          <button className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[1rem]" onClick={() => verifyBvn()}>
            {loading ? (
              <Spinner color="light"> Loading...</Spinner>
            ) : (
              "Verify"
            )}
          </button>
          <Modal isOpen={modal} toggle={toggle} className='w-[400px] h-[500px] top-[20%] rounded-[14px]'>
              <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                  {apiComp && <p className="text-[black] test-[10px] text-center w-[350px] mb-[10px]">{verifyData}</p>}
                  <button onClick={() => {toggle();secondToggle()}} className='w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px] text-white'>Ok</button>
              </ModalBody>
            </Modal>   
            </div>
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default VerifyCanvas;
