import React, {useEffect} from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Dashboard from "../dashboard/Dashboard";
import { Routes, Route,Navigate,useNavigate } from "react-router-dom";
import Portfolio from "../portfolio/Portfolio";
import Beneficiary from "../Beneficiaries/Beneficiary";
import Transactions from "../Transactions/Transactions";
import Settings from "../Settings/Settings";
import Terms from "../../components/DashHome/DashboardComp/Terms";
import { useSelector } from "react-redux";

function Individual({ setUserType, userType}) {
  const navigate = useNavigate();
  const token = useSelector(
    (state) => state?.user?.currentUser?.token?.access_token
  );
  useEffect(() => {
    if (token === undefined) {
      navigate('/');
    }
  }, [token, navigate]);
  return (
    <>
    <div className="w-[100%]">
        <div className="flex">
          <Sidebar />
          <Routes>
          <Route path='/dashboard' element={<Dashboard setUserType={setUserType} userType={userType} />}/>
          <Route path='/portfolio' element={<Portfolio setUserType={setUserType} userType={userType} />}/>
          <Route path='/beneficiaries' element={<Beneficiary setUserType={setUserType} userType={userType} />}/>
          <Route path='/transactions' element={<Transactions setUserType={setUserType} userType={userType} />}/>
          <Route path='/settings' element={<Settings setUserType={setUserType} userType={userType} />}/>
          <Route path='/Terms&Conditions' element={<Terms />} />
         
         
          <Route path="*" element={<Navigate to='/dashboard'/>} />
          </Routes>
        </div>
    </div>
    </>
  );
}

export default Individual;
