import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FormGroup, Input, Label, Modal, ModalBody, Spinner } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./phoneInput.css";
import { signUp, userRequest } from "../../requestMethod";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import PasswordChecklist from "react-password-checklist";

function CreateAccount() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [acceptedPassword, setAcceptedPassword] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [refCode, setRefCode] = useState("");
  const [loginData, setLoginData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modal,setModal] = useState(false);
  const [errorState, setErrorState] = useState(false)
  const [error,setError] = useState(null)
  const toggle = () => {
    setModal(!modal);
  }
  const secondToggle = () => {
    setModal(!modal)
    setErrorState(false);
  }
 

  const navigate = useNavigate();

  const passwordChecker = async () => {
    if (password === confirmPassword) setAcceptedPassword(password);
  };

  useEffect(() => {
    passwordChecker();
  }, [confirmPassword]);



  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  const handleChangePasswordChange = (e) => {
    e.preventDefault();
    setConfirmPassword(e.target.value);
  };

  const createAccount = async () => {
    try {
      setLoading(true);
      const response = await signUp.post(
        "api/v2/customer/sign-up",
        {
          last_name: lastName,
          password: acceptedPassword,
          first_name: firstName,
          middle_name: middleName,
          email_address: email,
          mobile_number: "+" + phoneNumber,
          gender: selectedGender,
          referral_code: refCode,
        },
        {
          headers: {
            source: "web",
            "x-api-key": "94684537",
          },
        }
      );
      setLoginData(response?.data);
      setLoading(false);
    } catch (error) {
      setErrorState(true)
      setError(error?.response?.data?.message);
      setLoading(false);
      toggle();
    }
  };

  console.log(loginData)

 

  useEffect(() => {
    if (loginData?.status === "success") {
      navigate("/new-account-otp", { replace: true, state: { email: email } });
    }
  }, [navigate, email, loginData?.status]);

  return (
    <div className="flex flex-col justify-center items-center bg-white relative h-[auto] w-[100%]">
      <img
        src="/assets/blacktext.png"
        alt=""
        className="md:w-[200px] w-[212px] h-[40px] absolute top-[50px] left-[50px]"
      />
      <div className="mb-[41px]">
        <h1 className="text-black text-[37px] font-bold text-center mb-[18px] mt-[200px]">
          Create Account
        </h1>
        <p className="text-black text-[14px] text-center">
          Kindly enter your details to create an account
        </p>
      </div>
      <div className="bg-[#F1F5F9] w-[1055px] h-[680px] mb-[51px] rounded-[10px] flex justify-start items-start px-[40px] py-[40px]">
        <div className="basis-1/2 flex flex-col gap-[15px]">
          <Label for="fName" className="text-black mb-[-5px]">
            Enter First Name
          </Label>
          <Input
            id="fName"
            name="fName"
            placeholder=""
            type="text"
            className="md:w-[400px] w-[320px] h-[50px] rounded-[20px] pl-[20px] bg-white"
            onChange={(e) => setFirstName(e.target.value)}
          />
          <Label for="lName" className="text-black mb-[-5px]">
            Enter Last Name
          </Label>
          <Input
            id="lName"
            name="lName"
            placeholder=""
            type="text"
            className="md:w-[400px] w-[320px] h-[50px] rounded-[20px] pl-[20px] bg-white"
            onChange={(e) => setLastName(e.target.value)}
          />
          <Label for="mName" className="text-black mb-[-5px]">
            Enter Middle Name(optional)
          </Label>
          <Input
            id="mName"
            name="mName"
            placeholder=""
            type="text"
            className="md:w-[400px] w-[320px] h-[50px] rounded-[20px] pl-[20px] bg-white"
            onChange={(e) => setMiddleName(e.target.value)}
          />
          <Label for="email" className="text-black mb-[-5px]">
            Enter Email Address
          </Label>
          <Input
            id="email"
            name="email"
            placeholder=""
            type="email"
            className="md:w-[400px] w-[320px] h-[50px] rounded-[20px] pl-[20px] bg-white"
            onChange={(e) => setEmail(e.target.value)}
          />
          
          <PhoneInput
            country="ng"
            regions={"africa"}
            value={phoneNumber}
            onChange={setPhoneNumber}
          />
          <Label for="gender" className="text-black mb-[-5px]">
            Select Gender
          </Label>
          <Input
            id="gender"
            name="gender"
            className="md:w-[400px] w-[320px] h-[50px] rounded-[20px] pl-[20px] bg-white "
            type="select"
            onChange={(e) => setSelectedGender(e.target.value)}
          >
            <option value="">Select Gender</option>
            <option value="MALE">Male</option>
            <option value="FEMALE">Female</option>
          </Input>
        </div>
        <div className="basis-1/2 flex flex-col gap-[15px]">
          <FormGroup className="mt-[5px] relative">
            <Label for="Password" className="text-black">
              Enter Password
            </Label>
            <Input
              id="password"
              name="password"
              type={passwordType}
              onChange={handlePasswordChange}
              className="md:w-[400px] w-[320px] h-[50px] rounded-[20px] pl-[20px] bg-white "
            />
            <button
              onClick={togglePassword}
              className="absolute right-[115px] top-[50px]"
            >
              {passwordType === "password" ? (
                <FaRegEye className="text-[25px" />
              ) : (
                <FaRegEyeSlash className="text-[25px" />
              )}
            </button>
          </FormGroup>
          <PasswordChecklist
            rules={["minLength", "specialChar", "number", "capital"]}
            minLength={5}
            value={password}
            valueAgain={confirmPassword}
            messages={{
              minLength: "At Least 8 Characters",
              specialChar: "1 Special Character",
              number: "1 Numeric Character",
              capital: "1 Uppercase Letter",
            }}
            iconComponents = {{ValidIcon: <img src="./assets/valid.svg" alt="valid"/>, InvalidIcon: <img src="./assets/invalid.svg" alt='invalid' />}}
          />
          <FormGroup className="mt-[5px] relative mb-[0px]">
            <Label for="cPassword" className="text-black">
              Confirm Password
            </Label>
            <Input
              id="cPassword"
              name="cPassword"
              type={passwordType}
              onChange={handleChangePasswordChange}
              className="md:w-[400px] w-[320px] h-[50px] rounded-[20px] pl-[20px] bg-white "
            />
            <button
              onClick={togglePassword}
              className="absolute right-[115px] top-[50px]"
            >
              {passwordType === "password" ? (
                <FaRegEye className="text-[25px" />
              ) : (
                <FaRegEyeSlash className="text-[25px" />
              )}
            </button>
          </FormGroup>
          <Label for="refferal" className="text-black mb-[-5px]">
            Referral Code
          </Label>
          <Input
            id="referral"
            name="referral"
            placeholder=""
            type="password"
            className="md:w-[400px] w-[320px] h-[50px] rounded-[20px] pl-[20px] bg-white"
            onChange={(e) => setRefCode(e.target.value)}
          />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center pb-[20px]">
        <Link to="/">
          <p className="text-center text-[14px] font-normal text-[#1B1B1B]">
            Already have an account?{" "}
            <span className="font-bold text-[#4CC800]">Log in</span>
          </p>
        </Link>
        <p className="text-center text-[14px] font-normal text-[#1B1B1B]">
          By creating an account you agree to our{" "}
          <span className="font-bold text-[#4CC800]"> terms and condions</span>
        </p>
        <button
          className="text-white bg-[#4CC800] px-[60px] py-[10px] rounded-[136px] mt-[20px] w-[160px] text-center"
          onClick={() => createAccount()}
        >
          {loading ? (
            <Spinner color="light" size="sm">
              Loading...
            </Spinner>
          ) : (
            "Enter"
          )}
        </button>
        <Modal isOpen={modal} toggle={toggle} className='w-[400px] h-[500px] top-[20%] rounded-[14px]'>
              <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                  {errorState && <p className="text-[red] test-[10px] text-center w-[350px] mb-[10px]">{error}</p>}
                  <button onClick={() => {toggle();secondToggle()}} className='w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px] text-white'>Ok</button>
              </ModalBody>
            </Modal>
      </div>
      <img
        src="/assets/login-design2.png"
        alt=""
        className="absolute bottom-0 right-0 w-[300px] md:w-[auto]"
      />
    </div>
  );
}

export default CreateAccount;
