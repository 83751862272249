import { Close } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { Offcanvas, Tab, Tabs } from "react-bootstrap";
import { FormGroup, Input, Label, Modal, ModalBody, Spinner } from "reactstrap";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { userRequest } from "../../requestMethod";
import {
  FaRegEye,
  FaRegEyeSlash,
  FaArrowLeft,
  FaChevronDown,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import "./Transfer.css";

function TransferCanvas({ handleCloseTransfer, showTransfer, merchant }) {
  const [key, setKey] = useState("home");
  const [pennTag, setPenntag] = useState("");
  const [showBankSelector, setShowBankSelector] = useState(true);
  const [bankBeneficiary, setBankBeneficiary] = useState(null);
  const [PenBeneficiary, setPenBeneficiary] = useState(null);
  const [MerchBeneficiary, setMerchBeneficiary] = useState(null);
  const [merchTag, setMerchTag] = useState("");
  const [PennAmount, setPennAmount] = useState(0);
  const [bankAmount, setBankAmount] = useState(0);
  const [merchAmount, setMerchAmount] = useState(0);
  const [accountNumber, setAccountNumber] = useState("");
  const [penAccountNumber, setPenAccountNumber] = useState("");
  const [AccountName, setAccountName] = useState("");
  const [merchAccountNumber, setMerchAccountNumber] = useState("");
  const [pennName, setPennName] = useState("");
  const [bankName, setBankName] = useState("");
  const [merchName, setMerchName] = useState("");
  const [pennDesc, setPennDesc] = useState("");
  const [bankrealName, setBankrealName] = useState("");
  const navigate = useNavigate();
  const [bankTransferData, setBankTransferData] = useState(null);
  const [bankCode, setBankCode] = useState("");
  const [bankDesc, setbankDesc] = useState("");
  const [saveBenPenn, setSaveBenPenn] = useState(false);
  const [saveBenMerch, setSaveBenMerch] = useState(false);
  const [saveBenBank, setSaveBenBank] = useState(false);
  const [pin, setPin] = useState("");
  const [banks, setBanks] = useState(null);
  const [merchData, setMerchdata] = useState(null);
  const [merch2MerchData, setMerch2Merchdata] = useState(null);
  const [showM2BTrans, setShowM2BTrans] = useState(true);
  const [merchDesc, setMerchDesc] = useState("");
  const [showM2Bsummary, setShowM2Bsummary] = useState(false);
  const [M2Pmodal, setM2PModal] = useState(false);
  const [M2Bmodal, setM2BModal] = useState(false);
  const [M2Mmodal, setM2MModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openBeneficiaries, setOpenBeneficiaries] = useState(false);
  const toggleBeneficiaries = () => {
    setOpenBeneficiaries(!openBeneficiaries);
  };
  const user = useSelector((state) => state.user.currentUser.data.username);
  const toggleM2P = () => setM2PModal(!M2Pmodal);
  const toggleM2B = () => setM2BModal(!M2Bmodal);
  const toggleM2M = () => setM2MModal(!M2Mmodal);
  const closeModalM2P = () => {
    handleCloseTransfer();
    toggleM2P();
    handleM2PsummaryClose();
    navigate(0);
  };
  const closeModalM2B = () => {
    handleCloseTransfer();
    toggleM2B();
    handleM2PsummaryClose();
    navigate(0);
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const closeModalM2M = () => {
    handleCloseTransfer();
    toggleM2M();
    handleM2MsummaryClose();
    navigate(0);
  };
  const handleM2BsummaryShow = () => {
    setShowM2BTrans(false);
    setShowM2Bsummary(true);
  };

  const handleM2BsummaryClose = () => {
    setShowM2BTrans(true);
    setShowM2Bsummary(false);
  };

  const [showM2PTrans, setShowM2PTrans] = useState(true);
  const [showM2Psummary, setShowM2Psummary] = useState(false);
  const handleM2PsummaryShow = () => {
    setShowM2PTrans(false);
    setShowM2Psummary(true);
  };

  const handleM2PsummaryClose = () => {
    setShowM2PTrans(true);
    setShowM2Psummary(false);
  };

  const [showM2MTrans, setShowM2MTrans] = useState(true);
  const [showM2Msummary, setShowM2Msummary] = useState(false);
  const handleM2MsummaryShow = () => {
    setShowM2MTrans(false);
    setShowM2Msummary(true);
  };

  const handleM2MsummaryClose = () => {
    setShowM2MTrans(true);
    setShowM2Msummary(false);
  };
  const [passwordType, setPasswordType] = useState("password");
  const token = useSelector(
    (state) => state?.user?.currentUser?.token?.access_token
  );

  const getBeneficiaries = async () => {
    const response = await userRequest.get(
      "api/v2/customer/send-money/bank-account-beneficiaries",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setBankBeneficiary(response.data.data);
  };

  const getPenBeneficiaries = async () => {
    const response = await userRequest.get(
      "api/v2/customer/send-money/pen-account-beneficiaries?beneficiary_type=individual",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setPenBeneficiary(response.data.data);
  };

  const getMerchBeneficiaries = async () => {
    const response = await userRequest.get(
      "api/v2/customer/send-money/pen-account-beneficiaries?beneficiary_type=merchant",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setMerchBeneficiary(response.data.data);
  };
  useEffect(() => {
    getBeneficiaries();
    getMerchBeneficiaries();
    getPenBeneficiaries();
  }, []);

  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const getBanks = async () => {
    const response = await userRequest.get(
      "/api/v2/customer/send-money/banks",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setBanks(response.data.data);
  };

  useEffect(() => {
    getBanks();
  }, []);

  const BankResolve = async () => {
    const response = await userRequest.post(
      "api/v2/customer/send-money/bank-account-resolve",
      {
        bank_code: bankCode?.value || bankCode,
        account_number: accountNumber,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          source: "web",
          "x-api-key": "94684537",
        },
      }
    );
    setBankName(response.data.data.account_name);
  };

  useEffect(() => {
    BankResolve();
  }, [bankCode]);

  const PennResolve = async () => {
    setPennName("")
    try {
      const response = await userRequest.post(
        "api/v2/customer/send-money/pen-account-resolve",
        {
          identifier: pennTag,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            source: "web",
            "x-api-key": "94684537",
          },
        }
      );
      setPennName(response.data.data.account_name);
    } catch (error) {
      
    }
    
  };


  useEffect(() => {
    PennResolve();
  },[pennTag]);

  const MerchResolve = async () => {
    setMerchName("")
    try {
      const response = await userRequest.post(
        "api/v2/customer/send-money/pen-account-resolve",
        {
          identifier: merchTag,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            source: "web",
            "x-api-key": "94684537",
          },
        }
      );
      setMerchName(response.data.data.business_name);
    } catch (error) {
      setMerchName("")
    }
   
  };

  useEffect(() => {
    MerchResolve();
  },[merchTag]);

  const doMerch2Penn = async () => {
    setLoading(true);
    try {
      const response = await userRequest.post(
        "api/v2/customer/send-money/to-pennytree",
        {
          sender_tag: user,
          beneficiary_tag: pennTag,
          sender_type: "merchant",
          beneficiary_type: "individual",
          amount: Number(PennAmount),
          currency: "NGN",
          transaction_pin: pin,
          save_as_beneficiary: saveBenPenn,
          narration: pennDesc,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            source: "web",
            "x-api-key": "94684537",
          },
        }
      );
      setMerchdata(response.data);
      setLoading(false);
    } catch (error) {
      setMerchdata(error.response.data);
      setLoading(false);
    }
  };

  const doMerch2Bank = async () => {
    setLoading(true);
    try {
      const response = await userRequest.post(
        "api/v2/customer/send-money/to-bank",
        {
          amount: Number(bankAmount),
          currency: "NGN",
          bank_code: bankCode?.value,
          account_number: accountNumber,
          sender_type: "merchant",
          sender_tag: user,
          transaction_pin: pin,
          save_as_beneficiary: saveBenPenn,
          narration: bankDesc,
          provider_id: 0,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            source: "web",
            "x-api-key": "94684537",
          },
        }
      );
      setBankTransferData(response.data);
      setLoading(false);
    } catch (error) {
      setBankTransferData(error.response.data);
      setLoading(false);
    }
  };

  const doMerch2Merch = async () => {
    setLoading(true);
    try {
      const response = await userRequest.post(
        "api/v2/customer/send-money/to-pennytree",
        {
          amount: Number(merchAmount),
          currency: "NGN",
          beneficiary_tag: merchTag,
          transaction_pin: pin,
          save_as_beneficiary: saveBenPenn,
          sender_tag: user,
          sender_type: "merchant",
          beneficiary_type: "merchant",
          narration: merchDesc,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            source: "web",
            "x-api-key": "94684537",
          },
        }
      );
      setLoading(false);
      setMerch2Merchdata(response.data);
    } catch (error) {
      setLoading(false);
      setMerch2Merchdata(error.response.data);
    }
  };

  const options = banks?.map((item) => {
    return {
      label: item?.bank_name,
      value: item?.bank_code,
    };
  });
  return (
    <Offcanvas
      show={showTransfer}
      onHide={handleCloseTransfer}
      placement="end"
      className="py-[20px] pl-[30px] flex flex-col w-[505px] transfer"
    >
      <Offcanvas.Header closeButton className="w-[100%]">
        <Offcanvas.Title className="mt-[60px]">Transfer </Offcanvas.Title>
        <Close onClick={handleCloseTransfer} className="mr-[-200px]" />
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-[40px]"
        >
          <Tab eventKey="home" title="Merch-2-Bank">
            {showM2BTrans && (
              <>
                {/* <div
                  className="rounded-[32px] border-none outline-none bg-[#F5F6FA] w-[421px] h-[52px]  flex justify-between items-center px-[24px] mb-[20px]"
                  onClick={toggleBeneficiaries}
                >
                  <h1>Select Beneficiary</h1>
                  <FaChevronDown onClick={toggleBeneficiaries} />
                </div>
                {openBeneficiaries && <div className="w-[421px] min-h-[40px] px-[20px] bg-[#f5f6fa] py-[10px] relative z-10 mb-[20px]">
                  {bankBeneficiary?.map((single) => (
                    <option value={single?.id} className="text-black mb-[5px] cursor-pointer" onClick={(e) => {
                      const beneficiaryDetails = bankBeneficiary.find(
                        (x) => x.id === Number(e.target.value)
                      );
                      setAccountNumber(beneficiaryDetails?.beneficiary_account);
                      setBankCode(beneficiaryDetails?.bank_code);
                      setAccountName(beneficiaryDetails?.beneficiary_nick_name);
                      setShowBankSelector(false);
                      toggleBeneficiaries()
                    }}>
                      {single?.beneficiary_nick_name} ({single?.bank_name})
                    </option>
                  ))}
                  </div>} */}
                <Label for="ben">Select Beneficiary</Label>
                <Input
                  id="ben"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]  text-transparent"
                  type="select"
                  onChange={(e) => {
                    const beneficiaryDetails = bankBeneficiary.find(
                      (x) => x.id === Number(e.target.value)
                    );
                    setAccountNumber(beneficiaryDetails?.beneficiary_account);
                    setBankCode(beneficiaryDetails?.bank_code);
                    setAccountName(beneficiaryDetails?.beneficiary_nick_name);
                    setBankrealName(beneficiaryDetails?.bank_name);
                    setShowBankSelector(false);
                  }}
                >
                  <option value="">Select Beneficary</option>
                  {bankBeneficiary?.map((single) => (
                    <option value={single?.id} className="text-black">
                      {single?.beneficiary_nick_name} ({single?.bank_name})
                    </option>
                  ))}
                </Input>
                <input
                  type="text"
                  placeholder="Account Number"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                  onChange={(e) => {
                    setAccountNumber(e.target.value);
                    setShowBankSelector(true);
                  }}
                  value={accountNumber}
                />
                {/* <Input
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                  type="select"
                  onChange={(e) => setBankCode(e.target.value)}
                  value={bankCode}
                  isSearchable
                >
                  <option value="">Select Bank</option>
                  {banks?.map((bank) => (
                    <option value={bank.bank_code}>{bank.bank_name}</option>
                  ))}
                </Input> */}
                {showBankSelector && (
                  <Select
                    options={options}
                    placeholder="Select Bank"
                    onChange={setBankCode}
                    value={bankCode}
                  />
                )}
                <input
                  type="text"
                  placeholder="Name"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                  value={accountNumber?.length === 10 ? bankName : ""}
                  onChange={() => BankResolve()}
                  readOnly
                />
                <input
                  type="text"
                  placeholder="Amount"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                  onChange={(e) => setBankAmount(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Description"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                  onChange={(e) => setbankDesc(e.target.value)}
                />
                <p>
                  Wallet Balance:{" "}
                  <NumericFormat
                    value={merchant?.wallet?.available_balance}
                    thousandSeparator=","
                    prefix="₦"
                    disabled
                    className="text-black bg-transparent"
                  />
                </p>
                <button
                  className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[5rem]"
                  onClick={() => handleM2BsummaryShow()}
                >
                  Proceed
                </button>
              </>
            )}

            {showM2Bsummary && (
              <>
                <FaArrowLeft onClick={() => handleM2BsummaryClose()} />
                <h1 className="text-[24px] font-bold mb-[30px]">Summary</h1>
                <div className="w-[419px] h-[369px] bg-[#F5F6FA] rounded-[14px] flex flex-col items-start justify-start py-[40px] px-[15px]">
                  <div className="flex gap-[10px] items-center">
                    <div className="w-[10px] h-[10px] rounded-[50%] bg-[#4CC800]"></div>
                    <p>Details</p>
                  </div>
                  <div className="w-[100%] bg-[#535456] h-[1px] mt-[20px] mb-[15px] self-center"></div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">Amount</p>
                    <div className="flex justify-end items-end w-[200px]">
                      <NumericFormat
                        value={bankAmount}
                        thousandSeparator=","
                        prefix="₦"
                        disabled
                        className="text-[#535456] text-[14px] text-end bg-transparent"
                      />
                    </div>
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">
                      Transaction Charge
                    </p>
                    <NumericFormat
                      value={53.4}
                      thousandSeparator=","
                      prefix="₦"
                      disabled
                      className="text-[#535456] text-[14px]  text-end bg-transparent"
                    />
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">Bank Name</p>
                    <h4 className="text-[#535456] text-[14px]">
                      {bankCode.label || bankrealName}
                    </h4>
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">Bank Account</p>
                    <h4 className="text-[#535456] text-[14px]">
                      {accountNumber}
                    </h4>
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">
                      Beneficiary Name
                    </p>
                    <h4 className="text-[#535456] text-[14px]">{bankName}</h4>
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">Payment Method</p>
                    <h4 className="text-[#535456] text-[14px]">Wallet</h4>
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">Description</p>
                    <h4 className="text-[#535456] text-[14px]">{bankDesc}</h4>
                  </div>
                </div>
                <div className="flex items-center justify-start my-[20px]">
                  <input
                    type="checkbox"
                    id="benefit"
                    className="mr-[10px]"
                    onClick={() => setSaveBenBank(!saveBenBank)}
                  />
                  <label for="Benefit">Save as beneficiary</label>
                </div>
                <FormGroup className="relative">
                  <input
                    type={passwordType}
                    placeholder="Pin"
                    className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                    onChange={(e) => setPin(e.target.value)}
                  />
                  <button
                    onClick={togglePassword}
                    className="absolute right-[50px] top-[20px]"
                  >
                    {passwordType === "password" ? (
                      <FaRegEye className="text-[25px" />
                    ) : (
                      <FaRegEyeSlash className="text-[25px" />
                    )}
                  </button>
                </FormGroup>
                <button
                  className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[1rem]"
                  onClick={() => {
                    doMerch2Bank();
                    toggleM2B();
                  }}
                >
                  {loading ? (
                    <Spinner color="light">Loading...</Spinner>
                  ) : (
                    "Proceed"
                  )}
                </button>
              </>
            )}
            <Modal
              isOpen={M2Bmodal}
              toggle={toggleM2B}
              className="w-[400px] h-[500px] top-[20%] rounded-[14px]"
            >
              <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                {bankTransferData?.status === "success" ? (
                  <img src="/assets/checkmark.png" alt="" />
                ) : (
                  <img src="/assets/Cancel.png" alt="" />
                )}
                <p className="mt-[20px] text-center">
                  {bankTransferData?.message}
                </p>
                <button
                  onClick={() => {
                    toggleM2B();
                    closeModalM2B();
                  }}
                  className="w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px] text-white"
                >
                  Ok
                </button>
              </ModalBody>
            </Modal>
          </Tab>
          <Tab eventKey="profile" title="Merch-2-Penn" className="">
            {showM2PTrans && (
              <>
                <Label for="ben">Select Beneficiary</Label>
                <Input
                  id="ben"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-transparent"
                  type="select"
                  onChange={(e) => {
                    const beneficiaryDetails = PenBeneficiary.find(
                      (x) => x.id === Number(e.target.value)
                    );
                    setPenntag(beneficiaryDetails?.beneficiary_tag);
                    setPennName(beneficiaryDetails?.beneficiary_nick_name);
                  }}
                >
                  <option value="">Select Beneficary</option>
                  {PenBeneficiary?.map((single) => (
                    <option value={single.id} className="text-black">
                      {single.beneficiary_nick_name}
                    </option>
                  ))}
                </Input>
                <input
                  type="text"
                  placeholder="PennTag"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                  onChange={(e) => setPenntag(e.target.value)}
                  value={pennTag}
                />
                <input
                  type="text"
                  placeholder="Name"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                  value={pennName}
                  disabled
                />
                <input
                  type="text"
                  placeholder="Amount"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                  onChange={(e) => setPennAmount(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="4"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                  onChange={(e) => setPennDesc(e.target.value)}
                />
                <p>
                  Wallet Balance:{" "}
                  <NumericFormat
                    value={merchant?.wallet?.available_balance}
                    thousandSeparator=","
                    prefix="₦"
                    disabled
                    className="text-black bg-transparent"
                  />
                </p>
                <button
                  className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[5rem]"
                  onClick={() => handleM2PsummaryShow()}
                >
                  Proceed
                </button>
              </>
            )}
            {showM2Psummary && (
              <>
                <FaArrowLeft onClick={() => handleM2PsummaryClose()} />
                <h1 className="text-[24px] font-bold mb-[30px]">Summary</h1>
                <div className="w-[419px] h-[369px] bg-[#F5F6FA] rounded-[14px] flex flex-col items-start justify-start py-[40px] px-[15px]">
                  <div className="flex gap-[10px] items-center">
                    <div className="w-[10px] h-[10px] rounded-[50%] bg-[#4CC800]"></div>
                    <p>Details</p>
                  </div>
                  <div className="w-[100%] bg-[#535456] h-[1px] mt-[20px] mb-[15px] self-center"></div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">Amount</p>
                    <div className="flex justify-end items-end w-[200px]">
                      <NumericFormat
                        value={bankAmount}
                        thousandSeparator=","
                        prefix="₦"
                        disabled
                        className="text-[#535456] text-[14px] text-end bg-transparent"
                      />
                    </div>
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">
                      Transaction Charge
                    </p>
                    <NumericFormat
                      value={0.0}
                      thousandSeparator=","
                      prefix="₦"
                      disabled
                      className="text-[#535456] text-[14px]  text-end bg-transparent"
                    />
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">Penn Tag</p>
                    <h4 className="text-[#535456] text-[14px]">{pennTag}</h4>
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">
                      Beneficiary Name
                    </p>
                    <h4 className="text-[#535456] text-[14px]">{pennName}</h4>
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">Payment Method</p>
                    <h4 className="text-[#535456] text-[14px]">Wallet</h4>
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">Description</p>
                    <h4 className="text-[#535456] text-[14px]">{pennDesc}</h4>
                  </div>
                </div>
                <div className="flex items-center justify-start my-[20px]">
                  <input
                    type="checkbox"
                    id="benefit"
                    className="mr-[10px]"
                    onClick={() => setSaveBenBank(!saveBenBank)}
                  />
                  <label for="Benefit">Save as beneficiary</label>
                </div>
                <FormGroup className="relative">
                  <input
                    type={passwordType}
                    placeholder="Pin"
                    className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                    onChange={(e) => setPin(e.target.value)}
                  />
                  <button
                    onClick={togglePassword}
                    className="absolute right-[50px] top-[20px]"
                  >
                    {passwordType === "password" ? (
                      <FaRegEye className="text-[25px" />
                    ) : (
                      <FaRegEyeSlash className="text-[25px" />
                    )}
                  </button>
                </FormGroup>
                <button
                  className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[1rem]"
                  onClick={() => {
                    doMerch2Penn();
                    toggleM2P();
                  }}
                >
                  {loading ? (
                    <Spinner color="light">Loading...</Spinner>
                  ) : (
                    "Proceed"
                  )}
                </button>
              </>
            )}
            <Modal
              isOpen={M2Pmodal}
              toggle={toggleM2P}
              className="w-[400px] h-[500px] top-[20%] rounded-[14px]"
            >
              <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                {merchData?.status === "success" ? (
                  <img src="/assets/checkmark.png" alt="" />
                ) : (
                  <img src="/assets/Cancel.png" alt="" />
                )}
                <p className="mt-[20px] text-center">{merchData?.message}</p>
                <button
                  onClick={() => {
                    toggleM2P();
                    closeModalM2P();
                  }}
                  className="w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px] text-white"
                >
                  Ok
                </button>
              </ModalBody>
            </Modal>
          </Tab>
          <Tab eventKey="contact" title="Merch-2-Merch">
            {showM2MTrans && (
              <>
                <Label for="ben">Select Beneficiary</Label>
                <Input
                  id="ben"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-transparent"
                  type="select"
                  onChange={(e) => {
                    const beneficiaryDetails = MerchBeneficiary.find(
                      (x) => x.id === Number(e.target.value)
                    );
                    setMerchAccountNumber(beneficiaryDetails?.beneficiary_tag);
                    setMerchName(beneficiaryDetails?.beneficiary_nick_name);
                  }}
                >
                  <option value="">Select Beneficary</option>
                  {MerchBeneficiary?.map((single) => (
                    <option value={single.id} className="text-black">
                      {single.beneficiary_nick_name}
                    </option>
                  ))}
                </Input>
                <input
                  type="text"
                  placeholder="MerchTag"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                  onChange={(e) => setMerchTag(e.target.value)}
                  value={merchAccountNumber}
                />
                <input
                  type="text"
                  placeholder="Name"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                  value={merchName}
                  readOnly
                />
                <input
                  type="text"
                  placeholder="Amount"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                  onChange={(e) => setMerchAmount(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Description"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                  onChange={(e) => setMerchDesc(e.target.value)}
                />
                <p>
                  Wallet Balance:{" "}
                  <NumericFormat
                    value={merchant?.wallet?.available_balance}
                    thousandSeparator=","
                    prefix="₦"
                    disabled
                    className="text-black bg-transparent"
                  />
                </p>
                <button
                  className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[5rem]"
                  onClick={() => handleM2MsummaryShow()}
                >
                  Proceed
                </button>
              </>
            )}
            {showM2Msummary && (
              <>
                <FaArrowLeft onClick={() => handleM2MsummaryClose()} />
                <h1 className="text-[24px] font-bold mb-[30px]">Summary</h1>
                <div className="w-[419px] h-[369px] bg-[#F5F6FA] rounded-[14px] flex flex-col items-start justify-start py-[40px] px-[15px]">
                  <div className="flex gap-[10px] items-center">
                    <div className="w-[10px] h-[10px] rounded-[50%] bg-[#4CC800]"></div>
                    <p>Details</p>
                  </div>
                  <div className="w-[100%] bg-[#535456] h-[1px] mt-[20px] mb-[15px] self-center"></div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">Amount</p>
                    <div className="flex justify-end items-end w-[200px]">
                      <NumericFormat
                        value={bankAmount}
                        thousandSeparator=","
                        prefix="₦"
                        disabled
                        className="text-[#535456] text-[14px] text-end bg-transparent"
                      />
                    </div>
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">
                      Transaction Charge
                    </p>
                    <NumericFormat
                      value={53.4}
                      thousandSeparator=","
                      prefix="₦"
                      disabled
                      className="text-[#535456] text-[14px]  text-end bg-transparent"
                    />
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">Bank Name</p>
                    <h4 className="text-[#535456] text-[14px]">
                      {bankCode.label}
                    </h4>
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">Bank Account</p>
                    <h4 className="text-[#535456] text-[14px]">
                      {accountNumber}
                    </h4>
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">
                      Beneficiary Name
                    </p>
                    <h4 className="text-[#535456] text-[14px]">{bankName}</h4>
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">Payment Method</p>
                    <h4 className="text-[#535456] text-[14px]">Wallet</h4>
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">Description</p>
                    <h4 className="text-[#535456] text-[14px]">{merchDesc}</h4>
                  </div>
                </div>
                <div className="flex items-center justify-start my-[20px]">
                  <input
                    type="checkbox"
                    id="benefit"
                    className="mr-[10px]"
                    onClick={() => setSaveBenBank(!saveBenBank)}
                  />
                  <label for="Benefit">Save as beneficiary</label>
                </div>
                <FormGroup className="relative">
                  <input
                    type={passwordType}
                    placeholder="Pin"
                    className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                    onChange={(e) => setPin(e.target.value)}
                  />
                  <button
                    onClick={togglePassword}
                    className="absolute right-[50px] top-[20px]"
                  >
                    {passwordType === "password" ? (
                      <FaRegEye className="text-[25px" />
                    ) : (
                      <FaRegEyeSlash className="text-[25px" />
                    )}
                  </button>
                </FormGroup>
                <button
                  className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[1rem]"
                  onClick={() => {
                    doMerch2Merch();
                    toggleM2M();
                  }}
                >
                  {loading ? (
                    <Spinner color="light">Loading...</Spinner>
                  ) : (
                    "Proceed"
                  )}
                </button>
              </>
            )}
            <Modal
              isOpen={M2Mmodal}
              toggle={toggleM2M}
              className="w-[400px] h-[500px] top-[20%] rounded-[14px]"
            >
              <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                {merch2MerchData?.status === "success" ? (
                  <img src="/assets/checkmark.png" alt="" />
                ) : (
                  <img src="/assets/Cancel.png" alt="" />
                )}
                <p className="mt-[20px] text-center">
                  {merch2MerchData?.message}
                </p>
                <button
                  onClick={() => {
                    toggleM2M();
                    closeModalM2M();
                  }}
                  className="w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px] text-white"
                >
                  Ok
                </button>
              </ModalBody>
            </Modal>
          </Tab>
        </Tabs>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default TransferCanvas;
