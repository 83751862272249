import { Close } from '@mui/icons-material'
import React,{useEffect, useState} from 'react'
import Offcanvas from "react-bootstrap/Offcanvas";
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import {Input, Modal, ModalBody, Spinner} from 'reactstrap'
import { userRequest } from '../../../requestMethod';

function LockedFundsCanvas({showLockedFunds, handleCloseLockedFunds,wallet}) {
  const [nameVault, setNameVault] = useState(null);
  const [loading,setloading] = useState(false);
  const [vaultNames, setVaultNames] = useState(null);
  const [lockAmount, setLockAmount] = useState(Number);
  const [lockDuration, setLockDuration] = useState(null)
  const[lockedFor,setLockedFor] = useState(Number)
  const [lockPeriod, setlockPeriod] = useState(Number)
  const [lockFundsRes, setLockFundsRes] = useState(null);
  const[withdrawalDay,setWithdrawalDay] = useState("")
  var Today = new Date().toLocaleDateString();
  const MoneyReturn = Number(lockAmount) + Number(Number(lockedFor)/100 * Number(lockAmount))
  const token = useSelector(
    (state) => state?.user?.currentUser?.token?.access_token
  );

  const withdrawalDate = async () => {
  var date = new Date();
  date.setDate(date.getDate() + Number(lockPeriod)); 
  setWithdrawalDay(date);
  }

 
  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    setWithdrawalDay(result)
  }




  





  
  
  


  const lockFunds = async () => {
    setloading(true);
    try {
    const fundName = vaultNames.find(x => x.id === Number(nameVault));
    const fundDuration = lockDuration.find(x => x.id === Number(lockedFor));
    setlockPeriod(fundDuration.duration)
    const response = await userRequest.post(
      "api/v2/customer/investments/create",
      {
        amount: lockAmount,
        currency: "NGN",
        name: fundName.name,
        investment_id: fundDuration.id
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          source: "web",
          "x-api-key": "94684537",
        },
      }
    );
    setLockFundsRes(response.data)
    setloading(false);
    } catch (error) {
      setloading(false);
      setLockFundsRes(error)
    }
    
  };

  

  function convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat)
    return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
  }


  const getLockedFundsName = async () => {
    const response = await userRequest.get("api/v2/customer/investments/names",{
      headers: {"Authorization" : `Bearer ${token}`}
    })
    setVaultNames(response.data.data)
  }

  useEffect(() => {
    getLockedFundsName();
  }, []);

  useEffect(() => {
    withdrawalDate();
  }, []);

  const getLockedFundsDuration = async () => {
    const response = await userRequest.get("api/v2/customer/investments/types",{
      headers: {"Authorization" : `Bearer ${token}`}
    })
    setLockDuration(response.data.data)
  }

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    getLockedFundsDuration();
  }, []);
  
  return (
    <Offcanvas
          show={showLockedFunds}
          onHide={handleCloseLockedFunds}
          placement="end"
          className="py-[20px] pl-[30px] flex flex-col w-[505px]"
        >
          <Offcanvas.Header closeButton className="w-[100%]">
            <Offcanvas.Title className="mt-[60px]">Lock Funds</Offcanvas.Title>
            <Close onClick={handleCloseLockedFunds} className="mr-[-200px]" />
          </Offcanvas.Header>
          <Offcanvas.Body>
          <Input
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                type="select"
                onChange={(e) => setNameVault(e.target.value)}
                required
              >
                <option value="">Name Your Vault</option>
                {vaultNames?.map((names) => (
                  <option value={names.id}>{names.name}</option>
                ))}
              </Input>
            <input
              type="text"
              placeholder="How much do you want to Lock?"
              className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px]"
              onChange={(e) => Number(setLockAmount(e.target.value))}
              required
            />
            <Input
              className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] border-none outline-none]"
              type="select"
              onChange={(e) => {
                const fundDuration = lockDuration.find(x => x.interest_rate === Number(e.target.value));
                addDays(Today,Number(fundDuration?.duration))
                setLockedFor(Number(e.target.value))}}
              required
            >
              <option value=" ">How long do you want to lock your money for?</option>
              {lockDuration?.map((duration) => (
                  <option value={duration.interest_rate}>{duration.name} at {duration.interest_rate}% interest</option>
                ))}
            </Input>
            <p className='mt-[20px] ml-[10px]'>Wallet Balance:  <NumericFormat  
            value={wallet?.available_balance}
            thousandSeparator=','
            prefix='₦'
            className='text-black bg-transparent'
            disabled
          /></p>
            <p className="mt-[129px] text-[12px] text-left">
            The Estimated Amount at Maturity will be <span className="text-[#4CC800] font-bold">{MoneyReturn}</span> and your withdrawal date will be on <span className="text-[#4CC800]">{convertDate(withdrawalDay)}</span>. By proceeding, I agree that I have read and understood the terms and conditions set forth in. <br/><span className="font-bold">Read more</span>.
            </p>
            <button className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[5rem]" onClick={() => {lockFunds();toggle()}}>
              {loading ? <Spinner color='light'> Loading...</Spinner> : "Lock Funds"}
            </button>
            <Modal isOpen={modal} toggle={toggle} className='w-[400px] h-[500px] top-[20%] rounded-[14px]'>
              <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                {lockFundsRes?.status === "success" ? 
                  <img src="/assets/checkmark.png" alt="" />  : <img src="/assets/Cancel.png" alt="" />
              }
               <p className="mt-[20px] text-center">{lockFundsRes?.response?.data?.message}</p>
               <button onClick={() => {toggle();handleCloseLockedFunds()}} className='w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px] text-white'>Ok</button>
              </ModalBody>
            </Modal>
          </Offcanvas.Body>
        </Offcanvas>
  )
}

export default LockedFundsCanvas
