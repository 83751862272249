import React from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Input, Label, Modal, ModalBody, Spinner } from 'reactstrap'
import { roleRequest } from '../../../requestMethod';
import { useEffect } from 'react';

function Authorizer({merchant,merchantId}) {
  const[addCusData, setAddCusData] = useState(null);
  const [loading,setLoading] = useState(false)
  const [apiComp, setApiComp] = useState(false)
    const [email,setEmail] = useState("")
    const [modal,setModal] = useState(false)
    const [permissions, setPermissions] = useState(null)
  const toggle = () => {
    setModal(!modal)
  }
  const secondToggle = () => {
    setModal(!modal)
    setApiComp(false);
  }
  const token = useSelector(
    (state) => state?.user?.currentUser?.token?.access_token
  );

    const first_name = useSelector(
        (state) => state.user.currentUser.data.first_name
      );

      
      

    const addAuthToBus = async () => {
        try {
          setLoading(true)
            const response = await roleRequest.post(
                "api/v2/customer/customer-to-business/add",
                {
                  email_address: email,
                  first_name: first_name,
                  role_id: 3,
                  business_id: merchantId,
                  referal_code: ""
                },
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              );
              setAddCusData(response?.data?.message);
              setLoading(false)
              setApiComp(true)
              toggle();
        } catch (error) {
          setAddCusData(error?.response?.data?.message)
          setLoading(false)
          setApiComp(true)
          toggle();
        }
    };

    const getOperatorPermissions = async () => {
      const response = await roleRequest.get("api/v2/customer/utility/roles/permission/all?RoleId=3",
      {
        headers: {"Authorization" : `Bearer ${token}`}
      })
      setPermissions(response.data.data[0].permissions)
  }
  
  
  useEffect(() => {
    getOperatorPermissions()
  }, [])
  
  let newArray=permissions?.map(res=>{
    return {name: res.name, is_active:res.is_active}
  });

    
  return (
    <div className="w-[95%] h-[820px] bg-[#fff] rounded-[14px] px-[41px] py-[34px]">
            <div className="flex flex-col items-start justify-start ">
              <p className="font-semibold text-[16px]">Role Details</p>
            </div>
            <div className="flex items-start justify-center gap-[71px]">
              <div>
                <div className="mt-[28px]">
                  <div className="w-[100%] h-[2px] bg-[#b2b6bb] mt-[32px] mb-[20px]"></div>
                  <Label for="owner">Name of Role</Label>
                  <div className="w-[465px] h-[52px] bg-[#F5F6FA] rounded-[29px] flex items-center mt-[10px]">
                    <p className="ml-[20px] text-[#535456] text-[14px]">
                    Authorizer
                    </p>
                  </div>
                </div>
                <div className="mt-[28px]">
                  <Label for="role">Role description</Label>
                  <div className="w-[465px] h-[85px] bg-[#F5F6FA] rounded-[14px] flex items-center mt-[10px]">
                    <p className="ml-[20px] text-[#535456] text-[14px]">
                    This is a team member that can authorize transactions initiated. Basically a role similar to the owner’s.
                    </p>
                  </div>
                </div>
                <div className="w-[100%] h-[2px] bg-[#b2b6bb] mt-[32px] mb-[25px]"></div>
                <div>
                  <p className="text-[16px] text-[#1B1B1B] font-semibold mb-[10px]">
                    Team members with this role
                  </p>
                  <div className="flex items-center justify-start gap-[13px] w-[195px] h-[52px] rounded-[29px] border-solid border-[2px] border-[#B2B6BB] pl-[9px]">
                    <span className="w-[34px] h-[34px] rounded-[50%] text-[#fff] font-semibold text-[12px] bg-[#7BCEED] flex items-center justify-center">
                      EJ
                    </span>
                    <p>Ejoke Jolade</p>
                  </div>
                </div>
                <div className="w-[100%] h-[2px] bg-[#b2b6bb] mt-[32px] mb-[25px]"></div>
                <div>
                  <p className="text-[16px] text-[#1B1B1B] font-semibold mb-[10px]">
                    Invite a new team member to this role
                  </p>
                  <div className="relative">
                    <Input
                      className="w-[463px] h-[52px] rounded-[29px]"
                      placeholder="email address"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <button className="text-[#fff] border-solid border-2 rounded-[30px] px-[14px] py-[10px] border-[#4cc800] bg-[#4cc800] w-[153px] h-[44px] absolute right-[6px] top-[4px]" onClick={() => addAuthToBus()}>
                      {loading ? <Spinner size='sm' color='light'/> : "Send invite"}
                    </button>
                    <Modal isOpen={modal} toggle={toggle} className='w-[400px] h-[500px] top-[20%] rounded-[14px]'>
              <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                  {apiComp && <p className="text-[black] test-[10px] text-center w-[350px] mb-[10px]">{addCusData}</p>}
                  <button onClick={() => {toggle();secondToggle()}} className='w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px] text-white'>Ok</button>
              </ModalBody>
            </Modal>   
                  </div>
                </div>
              </div>
              <div className="w-[462px] h-[645px] border-[#B2B6BB] border-solid border-[1px] rounded-[14px] mt-[30px] px-[17px] py-[20px]">
                <p className="font-semibold text-[16px]">Role Permissions</p>
                <div className="w-[100%] h-[2px] bg-[#b2b6bb] mt-[16px] mb-[16px]"></div>
                <p className="text-[12px] text-[#B9BDC1] font-medium">
                  See the the list of availale permissions for this role
                </p>
                <div className="mt-[17px]">
                  {newArray?.map((news) => (
                    news?.is_active &&
                    <div className="flex items-center justify-start gap-[13px] mb-[12px]">
                    <div className="w-[11px] h-[11px] rounded-[2px] bg-[#4CC800]"></div>
                    <p className="text-[##535456] text-[12px]">
                      {news?.name}
                    </p>
                  </div>
                  ))}
              </div>
              </div>
            </div>
          </div>
  )
}

export default Authorizer
