import React, { useState } from "react";
import { BsChevronDoubleDown } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import { userRequest } from "../../../requestMethod";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NewBusiness from "./NewBusiness";
import { Spinner } from "reactstrap";

function MultiMerch({
  merchants,
  merchantName,
  setBusIndex,
  merchantVerif,
  linkedBus,
  setBusLinked,
  setBusMerch,
  loading,
  setUserType
}) {
  const [openMerch, setOpenMerch] = useState(false);
  

 
 
  const [showNewBusCanvas, setSHowNewBusCanvas] = useState(false);
 
  const toggleBus = () => {
    setSHowNewBusCanvas(!showNewBusCanvas);
  };
  const handleCloseBusinessCanvas = () => {
    setSHowNewBusCanvas(false);
  };
  const toggleMerchant = () => {
    setOpenMerch(!openMerch);
  };

  

  

  return (
    <div className="relative">
      <div
        className="w-[164px] h-[49px] rounded-[30px] border-solid border-[1px] border-[#1A1A1A] bg-[#1B1B1B] flex items-center justify-center gap-[3px] cursor-pointer"
        onClick={() => toggleMerchant()}
      >
        <p className="text-[#fff] w-[120px] overflow-hidden text-center">{loading ? <Spinner size='sm' color='light'/> : merchantName}</p>
        <BsChevronDoubleDown className="fill-[#fff]" />
      </div>
      {openMerch && (
        <div className="flex flex-col items-center justify-start pt-[15px] w-[164px] h-[auto] pb-[10px] bg-[#1B1B1B] absolute z-10 top-[30px] rounded-b-[20px]">
          {merchants?.map((singleMerch, index) => (
            <p
              className="text-[#fff] my-[8px] pl-[10px] text-[15px] cursor-pointer w-[120px] text-left overflow-hidden"
              key={index}
              onClick={() => {
                setBusIndex(index);
                toggleMerchant();
                setBusMerch()
              }}
            >
              {singleMerch.business_name}
            </p>
          ))}
          {linkedBus?.map((singleMerch, index) => (
            <p
              className="text-[#fff] my-[8px] pl-[10px] text-[15px] cursor-pointer w-[120px] text-left overflow-hidden"
              key={index}
              onClick={() => {
                setBusIndex(index);
                toggleMerchant();
                setBusLinked()
              }}
            >
              {singleMerch.business_name}
            </p>
          ))}
          <div className="w-[164px] h-[2px] bg-[#535456]"></div>
          <div className="flex items-center justify-center gap-[10px] py-[5px]">
            <FaPlus className="fill-[#4CC800]" />
            <p
              className="text-[#4CC800] my -[8px] text-[15px] cursor-pointer"
              onClick={() => {
                toggleBus();
                toggleMerchant();
              }}
            >
              Add Business
            </p>
          </div>
        </div>
      )}
      <NewBusiness merchantVerif={merchantVerif} handleCloseBusinessCanvas={handleCloseBusinessCanvas} showNewBusCanvas={showNewBusCanvas} setUserType={setUserType}/>
    </div>
  );
}

export default MultiMerch;
