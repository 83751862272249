import { Close } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Label } from "reactstrap";
import { userRequest } from "../../requestMethod";

function InvoiceCanvas({ handleCloseInvoice, showInvoice, merchantId,chooseBus }) {
  const [download, setDownload] = useState(false);
  const [item, setItem] = useState(null);
  const [note, setNote] = useState("");
  const [invoiceError, setInvoiceError] = useState(null)
  const [invoiceState, setInvoiceState] = useState(false)
  const [quantity, setQuantity] = useState(Number);
  const [customer, setCustomer] = useState(null);
  const [rate, setRate] = useState(Number);
  const subTotal = rate * quantity;
  const [invoiceData, setInvoiceData] = useState(null);
  const [downloadlink, setDownloadLink] = useState("");
  const [cAddress, setCaddress] = useState("");
  const [cFullName, setCFullName] = useState("");
  const [cPhone, setCPhone] = useState("");
  const date = new Date();
  var yr = date.getFullYear();
var mo = date.getMonth() + 1;
var day = date.getDate();
const dateToUse = yr + "-" + mo + "-" + day
  const [taxRate, setTaxRate] = useState(0);
  const [dueDate, setDueDate] = useState(date);
  const [profile, setProfile] = useState(null);
  const Total = subTotal + (taxRate / 100) * subTotal;
  const token = useSelector(
    (state) => state?.user?.currentUser?.token?.access_token
  );

  console.log("date",date)
  console.log(dateToUse)


  const getProfile = async () => {
    const response = await userRequest.get("api/v2/customer/me", {
      headers: { Authorization: `Bearer ${token}` },
    });
    setProfile(response.data.data);
  };
  useEffect(() => {
    getProfile();
  }, []);

  const apiUrl = chooseBus ? "api/v2/customer/merchant/invoice/create" : "api/v2/customer/merchant/invoice/create-for-business"
  const generateinvoice = async () => {
    try {
      setInvoiceState(false)
      const response = await userRequest.post(
        apiUrl,
        {
          business_id: merchantId,
          due_date: dateToUse,
          date_generated: dateToUse,
          recipients: [
            {
              email_address: customer,
              mobile_number: cPhone,
              full_name: cFullName,
              address: cAddress,
              invoice_recipient_id: 0,
            },
          ],
          items: [
            {
              description: "Payment Invoice",
              unit: "Naira",
              rate: rate,
              quantity: quantity,
            },
          ],
          notes: note,
          add_signature: false,
          add_logo: false,
          reference: `Payment for ${item}`,
          tax: taxRate,
          amount_received: rate * quantity,
          name: `Payment for ${item}`,
          is_receipt: false,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            source: "web",
            "x-api-key": "94684537",
          },
        }
      );
      setInvoiceData(response);
      setDownloadLink(response?.data?.data?.invoice?.document_link);
    } catch (error) {
      setInvoiceState(true)
      setInvoiceError(error.response.data)
    }
  };

  


  return (
    <Offcanvas
      show={showInvoice}
      onHide={() => {handleCloseInvoice(); setInvoiceData(null)}}
      placement="end"
      className="py-[20px] pl-[30px] flex flex-col w-[505px]"
    >
      <Offcanvas.Header closeButton className="w-[100%]">
        <Offcanvas.Title className="mt-[60px]">
          Generate Invoice
        </Offcanvas.Title>
        <Close onClick={() => {handleCloseInvoice(); setInvoiceData(null)}} className="mr-[-200px]" />
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Label for="customer_name" className="text-black">
          Customer Name
        </Label>
        <input
          type="text"
          id="customer_name"
          name="customer_name"
          placeholder=""
          className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px]"
          onChange={(e) => setCFullName(e.target.value)}
        />
        <Label for="telephone" className="text-black">
          Telephone Number
        </Label>
        <input
          type="text"
          id="telephone"
          name="telephone"
          placeholder=""
          className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px]"
          onChange={(e) => setCPhone(e.target.value)}
        />
        <Label for="Address" className="text-black">
          Address
        </Label>
        <input
          type="text"
          id="Address"
          name="Address"
          placeholder=""
          className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px]"
          onChange={(e) => setCaddress(e.target.value)}
        />
        <Label for="email" className="text-black">
          Email Address
        </Label>
        <input
          type="text"
          id="email"
          name="email"
          placeholder=""
          className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px]"
          onChange={(e) => setCustomer(e.target.value)}
        />
        <Label for="item" className="text-black">
          Item
        </Label>
        <input
          type="text"
          id="item"
          name="item"
          className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px]"
          onChange={(e) => setItem(e.target.value)}
        />
        <Label for="Rate" className="text-black">
          Rate
        </Label>
        <input
          type="text"
          id="Rate"
          name="Rate"
          className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px]"
          onChange={(e) => setRate(e.target.value)}
        />
        <Label for="Quantity" className="text-black">
          Quantity
        </Label>
        <input
          type="text"
          id="Quantity"
          name="Quantity"
          className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px]"
          onChange={(e) => setQuantity(e.target.value)}
        />
        <Label for="Tax" className="text-black">
          Tax
        </Label>
        <input
          type="text"
          id="Tax"
          name="Tax"
          className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px]"
          onChange={(e) => setTaxRate(e.target.value)}
        />
        <div className="w-[421px] h-[150px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] flex flex-col justify-center">
          <p className="mb-[5px]">Summary</p>
          <p className="mb-[5px]">Subtotal: {subTotal}</p>
          <p className="mb-[5px]">Tax({taxRate}%)</p>
          <p className="mb-[5px]">Total: {Total}</p>
        </div>
        {/* <Label For="DueDate" className="text-black">
          Due Date
        </Label>
        <input
          type="date"
          id="DueDate"
          name="DueDate"
          placeholder="Due Date"
          className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px]"
          onChange={(e) => setDueDate(e.target.value)}
        /> */}
        <Label for="item" className="text-black">
          Add Note
        </Label>
        <input
          type="text"
          id="Add Note"
          name="Add Note"
          className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px]"
          onChange={(e) => setNote(e.target.value)}
        />
        <Label for="item" className="text-black">
          Add Signature
        </Label>
        <input
          type="text"
          id="Add Signature"
          name="Add Signature"
          className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[60px]"
        />
        <p className="text-[red]">{invoiceState && invoiceError.message}</p>
        {/* <button className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[20px] mb-[2rem]" onClick={() => {generateinvoice();setDownload(!download)}}>Generate</button> */}
        {invoiceData?.data?.status === "success" ? (
          <a
            className="bg-[#fff] text-center text-[#4CC800] border-solid border-1 border-[#4CC800] px-[168px] py-[20px]  rounded-[30px] text-[20px] mt-[2rem]"
            rel="noreferrer"
            target="_blank"
            href={downloadlink}
            download={invoiceData?.invoice?.document_link}
          >
            Download
          </a>
        ) : (
          <button
            className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[20px] mb-[2rem]"
            onClick={() => {
              generateinvoice();
              setDownload(!download);
            }}
          >
            Generate
          </button>
        )}
        
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default InvoiceCanvas;
