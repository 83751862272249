import React, { useEffect, useState } from "react";
import Topbar from "../../Topbar/Topbar";
import Owner from "./Owner";
import Operator from "./Operator";
import Authorizer from "./Authorizer";
import Initiator from "./Initiator";
import { userRequest } from "../../../requestMethod";
import { useSelector } from "react-redux";

function UserManagement({ userType, setUserType, merchant, merchantId }) {
  const [showOwner, setShowOwner] = useState(false);
  const [showOperator, setShowOperator] = useState(false);
  const [showInitiator, setShowInitiator] = useState(false);
  const [showAuthorizer, setShowAuthorizer] = useState(false);
  const [UserManagement, setUserManagement] = useState(true);
  const [storedRoles,setStoredRoles] = useState()
  const token = useSelector(
    (state) => state?.user?.currentUser?.token?.access_token
  );

  const handleShowOwner = () => {
    setShowOwner(true);
    setUserManagement(false);
  };

  const handleShowUser = () => {
    setShowOwner(false);
    setShowOperator(false);
    setShowAuthorizer(false)
    setShowInitiator(false)
    setUserManagement(true);
  };

  const handleShowOperator = () => {
    setShowOperator(true);
    setUserManagement(false);
  };

  const handleShowInitiator = () => {
    setShowInitiator(true);
    setUserManagement(false);
  };

  const handleShowAuthorizer = () => {
    setShowAuthorizer(true);
    setUserManagement(false);
  };

  const getAdminRoles = async () => {
    const response = await userRequest.get(
      `api/v2/customer/get-admin/${merchantId}/all`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setStoredRoles(response.data.data);
  };

  

  useEffect(() => {
    getAdminRoles()
  },[])

 

  return (
    <div className="dashboard relative">
      <Topbar setUserType={setUserType} userType={userType} />
      <div className="bg-[#F0F0F0] w-[100%] min-h-[100vh] px-[40px] pt-[30px] overflow-scroll">
        <h1 className="text-[18px] font-bold pb-[15px] mb-[26px] flex items-center justify-start gap-[5px] cursor-pointer">
          <span onClick={() => handleShowUser()}>Settings</span>{" "}
          <span className="flex"> &gt;&gt; User Management & Permission</span>
          {(showOperator || showOwner || showAuthorizer || showInitiator) && <span>&gt;&gt; Role Details</span>}
        </h1>
        {UserManagement && (
          <div className="w-[95%] h-[430px] bg-[#fff] rounded-[14px] px-[41px] py-[34px]">
            <div className="flex items-center justify-between">
              <p className="font-semibold text-[16px]">Management Roles</p>
              {/* <button className="text-[#4cc800] border-solid border-2 rounded-[30px] px-[17px] py-[15px] border-[#4cc800]">
                Create custom role
              </button> */}
            </div>
            <div className="w-[100%] h-[2px] bg-[#b2b6bb] mt-[32px]"></div>
            <div className="flex flex-col items-start justify-between w-[100%]">
              <div className="flex items-center justify-center w-[100%] h-[261px] p-[20px] gap-[90px]">
                <div className="w-[465px] h-[241px] flex flex-col items-start justify-center gap-[24px]">
                  {/* <p className="text-[#B9BDC1] text-[12px] mt-[20px]">
                    Last modified on May 11 2022, 01:56 PM
                  </p> */}
                  <div className="w-[465px] h-[145px] rounded-[7px] flex flex-col border-solid border-[1px] border-[#B2B6BB] pl-[30px] py-[25px]">
                    <h1 className="text-[24px] text-[#000] mb-[13px]">Owner</h1>
                    <p className="text-[14px] text-[#535456] w-[399px]">
                      This role is best suited for the business owner or an
                      executive team member that wil require full admin access.
                    </p>
                  </div>
                  <div className="flex items-center justify-end w-[100%]">
                    {/* <span className="w-[34px] h-[34px] rounded-[50%] text-[#fff] font-semibold text-[12px] bg-[#FD7900] flex items-center justify-center">
                      TS
                    </span> */}
                    <button
                      className="text-[#fff] border-solid border-2 rounded-[30px] px-[14px] py-[10px] border-[#4cc800] bg-[#4cc800]"
                      onClick={() => {handleShowOwner();getAdminRoles()}}
                    >
                      See permissions
                    </button>
                  </div>
                </div>
                <div className="w-[465px] h-[241px] flex flex-col items-start justify-center gap-[24px]">
                  {/* <p className="text-[#B9BDC1] text-[12px] mt-[20px]">
                    Last modified on May 11 2022, 01:56 PM
                  </p> */}
                  <div className="w-[465px] h-[145px] rounded-[7px] flex flex-col border-solid border-[1px] border-[#B2B6BB] pl-[30px] py-[25px]">
                    <h1 className="text-[24px] text-[#000] mb-[13px]">
                      Operations + Data Analysis
                    </h1>
                    <p className="text-[14px] text-[#535456] w-[399px]">
                      This works best for team members who need visibility on
                      transactions, download reports, and provide customer
                      support.
                    </p>
                  </div>
                  <div className="flex items-center justify-end w-[100%]">
                    <div className="flex">
                      {/* <span className="w-[34px] h-[34px] rounded-[50%] text-[#fff] font-semibold text-[12px] bg-[#E792EE] flex items-center justify-center">
                        TS
                      </span>
                      <span className="w-[34px] h-[34px] rounded-[50%] text-[#fff] font-semibold text-[12px] bg-[#E792EE] flex items-center justify-center ml-[-12px]">
                        TS
                      </span>
                      <span className="w-[34px] h-[34px] rounded-[50%] text-[#fff] font-semibold text-[12px] bg-[#E792EE] flex items-center justify-center ml-[-12px]">
                        TS
                      </span> */}
                    </div>
                    <button
                      className="text-[#fff] border-solid border-2 rounded-[30px] px-[14px] py-[10px] border-[#4cc800] bg-[#4cc800]"
                      onClick={() => {handleShowOperator(); getAdminRoles()}}
                    >
                      See permissions
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="w-[100%] h-[2px] bg-[#b2b6bb] mt-[32px]"></div> */}
              {/* <div className="flex items-center justify-center w-[100%] h-[261px] p-[20px] gap-[90px]">
                <div className="w-[465px] h-[241px] flex flex-col items-start justify-center gap-[24px]">
                  <p className="text-[#B9BDC1] text-[12px] mt-[20px]">
                    Last modified on May 11 2022, 01:56 PM
                  </p>
                  <div className="w-[465px] h-[145px] rounded-[7px] flex flex-col border-solid border-[1px] border-[#B2B6BB] pl-[30px] py-[25px]">
                    <h1 className="text-[24px] text-[#000] mb-[13px]">
                      Initiator
                    </h1>
                    <p className="text-[14px] text-[#535456] w-[399px]">
                      This is a team member that can initiate transactions for
                      approval by the owner.
                    </p>
                  </div>
                  <div className="flex items-center justify-between w-[100%]">
                    <span className="w-[34px] h-[34px] rounded-[50%] text-[#fff] font-semibold text-[12px] bg-[#ECBED0] flex items-center justify-center">
                      TS
                    </span>
                    <button className="text-[#fff] border-solid border-2 rounded-[30px] px-[14px] py-[10px] border-[#4cc800] bg-[#4cc800]" onClick={() => handleShowInitiator()}>
                      See permissions
                    </button>
                  </div>
                </div>
                <div className="w-[465px] h-[241px] flex flex-col items-start justify-center gap-[24px]">
                  <p className="text-[#B9BDC1] text-[12px] mt-[20px]">
                    Last modified on May 11 2022, 01:56 PM
                  </p>
                  <div className="w-[465px] h-[145px] rounded-[7px] flex flex-col border-solid border-[1px] border-[#B2B6BB] pl-[30px] py-[25px]">
                    <h1 className="text-[24px] text-[#000] mb-[13px]">
                      Authorizer
                    </h1>
                    <p className="text-[14px] text-[#535456] w-[399px]">
                      This is a team member that can authorize transactions
                      initiated. Basically a role similar to the owner’s.
                    </p>
                  </div>
                  <div className="flex items-center justify-between w-[100%]">
                    <span className="w-[34px] h-[34px] rounded-[50%] text-[#fff] font-semibold text-[12px] bg-[#7BCEED] flex items-center justify-center">
                      TS
                    </span>
                    <button className="text-[#fff] border-solid border-2 rounded-[30px] px-[14px] py-[10px] border-[#4cc800] bg-[#4cc800]" onClick={() => handleShowAuthorizer()}>
                      See permissions
                    </button>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        )}
        {showOwner && <Owner merchant={merchant} merchantId={merchantId} handleShowUser={handleShowUser} storedRoles={storedRoles} handleShowOwner={handleShowOwner}/>}
        {showOperator && <Operator  merchant={merchant} merchantId={merchantId} handleShowUser={handleShowUser} storedRoles={storedRoles} getAdminRoles={getAdminRoles} handleShowOperator={handleShowOperator}/> }
        {showAuthorizer && <Authorizer merchant={merchant} merchantId={merchantId}/>}
        {showInitiator && <Initiator merchant={merchant} merchantId={merchantId}/>}
      </div>
    </div>
  );
}

export default UserManagement;
