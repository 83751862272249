/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import "../DashHome/DashboardComp/Transfer.css";
import './Transfer.css';
import TransferCanvas from "./TransferCanvas";

function Transfer({ merchant }) {
  const [showTransfer, setShowTransfer] = useState(false);
  const handleCloseTransfer = () => setShowTransfer(false);
  const handleShowTransfer = () => setShowTransfer(true);
  

  return (
    <div className="">
      <button
        onClick={handleShowTransfer}
        className="group flex items-center justify-center gap-[5px] border-solid border-1 border-black px-[25px] py-[8px] rounded-[30px] hover:bg-[#4CC800] hover:border-[#4CC800] w-[164px] h-[49px]"
      >
        <FaPlus className="group-hover:fill-white" />
        <p className="group-hover:text-white">Transfer</p>
      </button>
      <TransferCanvas handleCloseTransfer={handleCloseTransfer} showTransfer={showTransfer} handleShowTransfer={handleShowTransfer} merchant={merchant}/>
    </div>
  );
}

export default Transfer;
