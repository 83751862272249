import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userRequest } from "../../../requestMethod";
import Transfer from "../Transfer";
import WidgetLg from "../WidgetLg";
import WidgetSm from "../WidgetSm";
import { useNavigate } from "react-router-dom";
import { LogOut } from "../../../redux/userRedux";
import MultiMerch from "./MultiMerch";

function MerchantHome({
  setBusIndex,
  merchantVerif,
  merchants,
  merchant,
  merchantName,
  linkedBus,
  linkBusData,
  setBusLinked,
  setBusMerch,
  chooseBus,
  merchantId,
  roleName,
  loading,
  setUserType
}) {
  const [profile, setProfile] = useState(null);

  var date = new Date();
  var time = date.getHours();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(
    (state) => state?.user?.currentUser?.token?.access_token
  );

  const getProfile = async () => {
    try {
      const response = await userRequest.get("api/v2/customer/me", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProfile(response.data.data);
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(LogOut());
        window.localStorage.clear();
        navigate("/");
      }
    }
  };
  useEffect(() => {
    getProfile();
  }, []);

  console.log("chooseBus", chooseBus)

  

  return (
    <div className="bg-[#F0F0F0] w-[100%] h-[auto] px-[40px] pt-[10px]">
      <h1 className="text-[24px] font-bold pb-[15px]">
        Good{" "}
        {time > 5 && time < 12
          ? "Morning"
          : time > 12 && time < 18
          ? "Afternoon"
          : "Evening"}
        , {merchant?.business_name}
      </h1>
      <div className="w-[100%] flex items-end justify-end mb-[10px] gap-[14px]">
        <MultiMerch
          merchants={merchants}
          merchant={merchant}
          merchantName={merchantName}
          setBusIndex={setBusIndex}
          merchantVerif={merchantVerif}
          linkedBus={linkedBus}
          linkBusData={linkBusData}
          setBusLinked={setBusLinked}
          setBusMerch={setBusMerch}
          loading={loading}
          setUserType={setUserType}
        />
        {chooseBus || roleName === "Owner" ? <Transfer merchant={merchant} /> : ""}
      </div>
      <div className="flex gap-[20px] justify-center">
        <WidgetLg merchant={merchant} chooseBus={chooseBus} roleName={roleName} loading={loading}/>
        <WidgetSm merchant={merchant} chooseBus={chooseBus} merchantId={merchantId} loading={loading}/>
      </div>
    </div>
  );
}

export default MerchantHome;
