import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, FormGroup, Label, Input, Spinner, Modal, ModalBody } from "reactstrap";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { login } from "../../redux/apiCalls";
import { useDispatch, useSelector } from "react-redux";

function Login() {
  const [passwordType, setPasswordType] = useState("password");
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [loginError,setLoginError] = useState(null)
  const [modal,setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  }
  const secondToggle = () => {
    setModal(!modal)
    setErrorState(false);
  }
  const [errorState, setErrorState] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);


  const { success, isFetching, error } = user;


  const handlePasswordChange = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };
  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  useEffect(() => {
    if (success) {
      navigate("/otp", { replace: true, state: { email: identifier } });
    }
  }, [success, navigate, identifier]);

  const submitHandler = async(e) => {
    e.preventDefault();
    try {
     await login(dispatch, { identifier, password });
    } catch (error) {
      setErrorState(true)
      setLoginError(error.response.data.message)
      toggle()
    }
   
  };


  return (
    <div className="flex justify-center items-center bg-white relative h-[100vh] w-[100%]">
      <Link to="/create-account" className="absolute top-[32px] right-[46px] text-[18px] text-white bg-black px-[40px] py-[18px] rounded-[49px]">Create Account</Link>
      <img
        src="/assets/login-design.png"
        alt=""
        className="absolute top-0 left-0 md:w-[auto] w-[300px]"
      />
      <div className="mt-[0px] text-white flex flex-col items-center justify-center">
        <img src="/assets/blacktext.png" alt="" className="md:w-[200px] w-[150px]" />
        <h1 className="text-black text-[24px] font-bold text-center my-[10px]">
          Login
        </h1>
        <p className="text-black text-[14px] text-center">
          Kindly enter your details to Login
        </p>
        <Form className="md:w-[450px] w-[350px] bg-slate-100 py-[40px] rounded-[30px] flex flex-col items-center justify-center mt-[20px]">
          <FormGroup className="mt-[10px]">
            <Label for="email" className="text-black">
              Enter Email Address
            </Label>
            <Input
              id="email"
              name="email"
              placeholder="Enter Email Address"
              type="email"
              onChange={(e) => setIdentifier(e.target.value)}
              className="md:w-[400px] w-[320px] h-[50px] rounded-[20px] pl-[20px] bg-white"
            />
          </FormGroup>
          <FormGroup className="mt-[10px] relative">
            <Label for="Password" className="text-black">
              Enter Password
            </Label>
            <Input
              id="password"
              name="password"
              placeholder="Enter password"
              type={passwordType}
              onChange={handlePasswordChange}
              className="md:w-[400px] w-[320px] h-[50px] rounded-[20px] pl-[20px] bg-white "
            />
            {error && <p className="text-[14px] text-red-600 mt-[8px]"></p>}
            <button
              onClick={togglePassword}
              className="absolute right-[20px] top-[50px]"
            >
              {passwordType === "password" ? (
                <FaRegEye className="text-[25px" />
              ) : (
                <FaRegEyeSlash className="text-[25px" />
              )}
            </button>
            <Modal isOpen={modal} toggle={toggle} className='w-[400px] h-[500px] top-[20%] rounded-[14px]'>
              <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                  {errorState && <p className="text-[red] test-[10px] text-center w-[350px] mb-[10px]">{loginError}</p>}
                  <button onClick={() => {toggle();secondToggle()}} className='w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px] text-white'>Ok</button>
              </ModalBody>
            </Modal>            
          </FormGroup>
          {/* <div className="flex items-center justify-center">
            {errorState && <p className="text-[red] test-[10px] text-center w-[350px] mb-[10px]">{loginError}</p>}
            </div> */}
          <Link to='/forgot-password'>
            <p className="text-black self-end mr-[30px]">Forgot Password?</p>
          </Link>
         
          <div className="flex justify-center">
            <button
              onClick={submitHandler}
              className="text-white bg-[#4CC800] px-[60px] py-[10px] rounded-[136px] mt-[20px] w-[200px]"
            >
              {isFetching ? <Spinner color="light" size="sm">Loading...</Spinner> : "Proceed"}
            </button>
          </div>
        </Form>
      </div>
      <img
        src="/assets/login-design2.png"
        alt=""
        className="absolute bottom-0 right-0 w-[300px] md:w-[auto]"
      />
    </div>
  );
}

export default Login;
