import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate,useNavigate } from "react-router-dom";
import Mdashboard from "../../components/Merchant/MerchantDashboard/Mdashboard";
import Msidebar from "../../components/sidebar/Msidebar";
import MerchantBeneficiaries from "../Beneficiaries/MerchantBeneficiaries";
import Customer from "../Customers/Customers";
import Invoice from "../Invoice/Invoice";
import Receipts from "../Receipts/Receipts";
import MerchantTransactions from "../Transactions/MerchantTransactions";
import MerchSettings from "../Settings/MerchSettings";
import UserManagement from "../../components/Merchant/UserManagement/UserManagement";
import { useSelector } from "react-redux";
import { userRequest } from "../../requestMethod";
import MerchantTerms from "../../components/Merchant/MerchantDashboard/MerchantTerms";


function Merchant({ setUserType, userType }) {
  const [profile, setProfile] = useState(null);
  const [busIndex, setBusIndex] = useState(0);
  const [chooseBus, setChooseBus] = useState(true);
  const [loading,setLoading] = useState(false)
  const navigate = useNavigate();
  const setBusMerch = () => {
    setChooseBus(true);
  };

  const setBusLinked = () => {
    setChooseBus(false);
  };
  const token = useSelector(
    (state) => state?.user?.currentUser?.token?.access_token
  );

  useEffect(() => {
    if (token === undefined || token === null) {
      navigate('/');
    }
  }, [token, navigate]);

  const getProfile = async () => {
    setLoading(true)
    try {
      const response = await userRequest.get("api/v2/customer/me", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProfile(response.data.data);
      setLoading(false)
    } catch (error) {
      setProfile(error.response.data.data)
      setLoading(false)
    }
  };
  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    if(profile?.businesses.length === 0){
      setChooseBus(false)
    }
  },[profile?.businesses.length])

  console.log("chooseBus", chooseBus)

 

  const merchants = profile?.businesses;
  const merchant = chooseBus
    ? profile?.businesses[busIndex]
    : profile?.business_accesses[busIndex];
  const merchantId = chooseBus ? profile?.businesses[busIndex]?.business_id : profile?.business_accesses[busIndex]?.business_id;
  const merchantName = chooseBus ? profile?.businesses[busIndex]?.business_name : profile?.business_accesses[busIndex]?.business_name;
  const merchantVerif = profile?.verification[2];
  const linkedBus = profile?.business_accesses;
  const linkBusData = profile?.business_accesses[0];
  const roleName = profile?.business_accesses[busIndex]?.role_name

  console.log("roleName", chooseBus)

 


  return (
    <div>
      <div className="w-[100%]">
        <div className="flex">
          <Msidebar chooseBus={chooseBus} roleName={roleName}/>
          <Routes>
            <Route
              path="/dashboard"
              element={
                <Mdashboard
                  setUserType={setUserType}
                  userType={userType}
                  setBusIndex={setBusIndex}
                  merchantName={merchantName}
                  merchantVerif={merchantVerif}
                  merchants={merchants}
                  busIndex={busIndex}
                  merchant={merchant}
                  linkedBus={linkedBus}
                  linkBusData={linkBusData}
                  setBusLinked={setBusLinked}
                  setBusMerch={setBusMerch}
                  chooseBus={chooseBus}
                  merchantId={merchantId}
                  roleName={roleName}
                  loading={loading}
                />
              }
            />
            <Route
              path="/transactions"
              element={
                <MerchantTransactions
                  setUserType={setUserType}
                  userType={userType}
                  merchantId={merchantId}
                  chooseBus={chooseBus}
                />
              }
            />
            <Route
              path="/beneficiaries"
              element={
                <MerchantBeneficiaries
                  setUserType={setUserType}
                  userType={userType}
                />
              }
            />
            <Route
              path="/customers"
              element={
                <Customer setUserType={setUserType} userType={userType} />
              }
            />
            <Route
              path="/invoice"
              element={
                <Invoice setUserType={setUserType} userType={userType} merchantId={merchantId} chooseBus={chooseBus}/>
              }
            />
            <Route
              path="/receipt"
              element={
                <Receipts setUserType={setUserType} userType={userType} merchantId={merchantId} chooseBus={chooseBus}/>
              }
            />
            <Route
              path="/settings"
              element={
                <MerchSettings setUserType={setUserType} userType={userType}  chooseBus={chooseBus} merchantVerif={merchantVerif} merchant={merchant}/>
              }
            />
            <Route path="*" element={<Navigate to="/dashboard" />} />
            <Route
              path="/user-management"
              element={
                <UserManagement
                  setUserType={setUserType}
                  userType={userType}
                  merchant={merchant}
                  merchantId={merchantId}
                />
              }
            />
            <Route path='/Terms&Conditions' element={<MerchantTerms />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default Merchant;
