import React,{useEffect, useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {Form, FormGroup, Label, Input, Spinner,Modal, ModalBody} from 'reactstrap'
import { OtpApi } from '../../redux/apiCalls'
import { useDispatch, useSelector } from 'react-redux'
import {FaArrowLeft} from 'react-icons/fa'
import { LogOut } from '../../redux/userRedux'

function Otp() {
    const dispatch = useDispatch();
    const location = useLocation();
    const identifier = location.state.email
    const[otp,setOtp] = useState("")
    const navigate = useNavigate();
    const [errorState, setErrorState] = useState(false)
    const [otpErrMessage, setOtpErrMessage] = useState(null)
    const [modal,setModal] = useState(false);
    const toggle = () => {
      setModal(!modal);
    }
    const secondToggle = () => {
      setModal(!modal)
      setErrorState(false);
      setOtp("")
    }

    const user = useSelector((state) => state.user)
    const {
      otpSuccess,
      otpFetching,
      otpError,
      
    } = user


  

    useEffect(() => {
        if(otpSuccess) {
        navigate('/dashboard', {replace: true, state:{email: identifier}})
        }
        }, [otpSuccess, navigate, identifier])
   

    const submitHandler = async(e) => {
        e.preventDefault();
        try {
           OtpApi(dispatch,{identifier, otp})
        } catch (error) {
          setErrorState(true)
          setOtpErrMessage(error.response.data.message)
          toggle();
        }
       
      
    }

    const backHandler = (e) =>{
      e.preventDefault();
      dispatch(LogOut())
      window.localStorage.clear();
      navigate('/')
    }
  return (
    <div className='flex justify-center items-center bg-white relative h-[100vh] w-[100%]'>
        <img src="/assets/login-design.png" alt="" className='absolute top-0 left-0 md:w-[auto] w-[300px]' />
        <div className='absolute top-[150px] left-[50px] flex gap-[15px] items-center justify-center cursor-pointer' onClick={backHandler}>
          <FaArrowLeft />
          <p className='text-[16px]'>Go Back</p>
        </div>
        <div className="mt-[0px]text-white flex flex-col items-center justify-center">
            <img src="/assets/blacktext.png" alt="" className='md:w-[200px] w-[150px]'/>
            <p className='text-black text-[16px] text-center mt-[20px]'>Kindly check your mail for OTP</p>
            <Form className='md:w-[450px] w-[350px] bg-slate-100 py-[40px] rounded-[30px] flex flex-col items-center justify-center mt-[20px]'>
            <FormGroup className='mt-[10px]'>
                    <Label for="email" className='text-black'>
                    Enter Otp
                    </Label>
                    <Input
                    id="otp"
                    name="otp"
                    placeholder="Enter OTP"
                    type="text"
                    className='md:w-[400px] w-[320px] rounded-[20px] pl-[20px] bg-white'
                    onChange={(e) => setOtp(e.target.value)}
                    />
                                    {otpError && <p className="text-[14px] text-red-600 mt-[8px] text-left">{otpError}</p>}
                </FormGroup>
                <div className="flex justify-center">
                <button
              onClick={submitHandler}
              className="text-white bg-[#4CC800] px-[60px] py-[10px] rounded-[136px] mt-[20px] w-[200px]"
            >
              {otpFetching ? <Spinner color="light" size="sm">Loading...</Spinner> : "Login"}
            </button>
            <Modal isOpen={modal} toggle={toggle} className='w-[400px] h-[500px] top-[20%] rounded-[14px]'>
              <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                  {errorState && <p className="text-[red] test-[10px] text-center w-[350px] mb-[10px]">{otpErrMessage}</p>}
                  <button onClick={() => {toggle();secondToggle()}} className='w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px] text-white'>Ok</button>
              </ModalBody>
            </Modal>  
                </div>
            </Form>
        </div>
        <img src="/assets/login-design2.png" alt="" className='absolute bottom-0 right-0 w-[300px] md:w-[auto]' />
    </div>
  )
}

export default Otp

