import React from 'react'
import './sidebar.css'
import {RiDashboardFill} from 'react-icons/ri'
// import {FaUserFriends} from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { LogOut } from '../../redux/userRedux'
import { FiSettings } from "react-icons/fi";

function Msidebar({chooseBus, roleName}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const logoutHandler = () => {
        dispatch(LogOut())
        window.localStorage.clear();
        navigate('/')
    }
  return (
    <div>
      <div className='Sidebar h-[100vh] flex flex-col items-center z-1 sticky top-0'>
        <img src="/assets/penn_logo.png" alt="Pennytree_logo" className='w-[180px] h-[32px] mt-[83px] mb-[43px]' />
        <div className="mr-[20px]">
            <Link to='/dashboard'>
                <div className="flex mb-[40px] hover:bg-sky-700">
                    <RiDashboardFill className='fill-white mr-[17px] text-[20px]' style={{fill:"#FFFFFF"}}/>
                    <p className='text-[#D9DBDC] text-[14px]'>Dashboard</p>
                </div>
            </Link>
            <Link to='/transactions'>
                <div className="flex mb-[40px]">
                    <img src="/assets/transaction.svg" alt="" className='mr-[17px] text-[20px]' />
                    <p className='text-[#D9DBDC] text-[14px]'>Transactions</p>
                </div>
            </Link>
            {/* <Link to='/beneficiaries'>
            <div className="flex mb-[40px]">
                <FaUserFriends className='fill-[#D9DBDC] mr-[17px] text-[20px]'/>
                <p className='text-[#D9DBDC] text-[14px]'>Beneficiaries</p>
            </div>
            </Link> */}
            <Link to='/receipt'>
                <div className="flex mb-[40px]">
                <img src="/assets/receipt.svg" alt="" className='fill-[#D9DBDC] mr-[17px] text-[20px]' />
                    <p className='text-[#D9DBDC] text-[14px]'>Receipts</p>
                </div>
            </Link>
            <Link to='/invoice'>
                <div className="flex mb-[40px]">
                <img src="/assets/invoice.svg" alt="" className='fill-[#D9DBDC] mr-[17px] text-[20px]' />
                    <p className='text-[#D9DBDC] text-[14px]'>Invoices</p>
                </div>
            </Link>
            <Link to="/settings">
                {(chooseBus || roleName === "Owner") && 
          <div className="flex mb-[40px]">
            <FiSettings className="mr-[17px] text-[20px] stroke-white" />
            <p className="text-[#D9DBDC] text-[14px]">Settings</p>
          </div>
}
        </Link>
            {/* <Link to='/customers'>
                <div className="flex mb-[40px]">
                    <FaUserFriends className='fill-[#D9DBDC] mr-[17px] text-[20px]'/>
                    <p className='text-[#D9DBDC] text-[14px]'>Customers</p>
                </div>
            </Link> */}
            <div className="flex mb-[40px] cursor-pointer" onClick={logoutHandler}>
            <img src="/assets/logout.svg" alt="" className='mr-[17px] text-[20px]' />
                <p className='text-[#D9DBDC] text-[14px]'>Logout</p>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Msidebar
