import React, { useEffect, useState } from 'react'
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { userRequest } from '../../../requestMethod';

function RecentTrans() {
  const [trans, setTrans] = useState(null);
  const token = useSelector(
    (state) => state?.user?.currentUser?.token?.access_token
  );
  const getTransactions = async () => {
    const response = await userRequest.get("api/v2/customer/transaction/all?size=5&page=&from=&to=&transaction_reference=&product_id=&customer_type=individual",{
      headers: {"Authorization" : `Bearer ${token}`}
    });
    setTrans(response.data.data.items)
}

useEffect(() => {
  getTransactions();
},[]);

  return (
    <div className='w-[674px] min-h-[300px] bg-white mt-[14px] rounded-[14px] flex flex-col px-[46px] py-[41px]'>
      <h1 className='mb-[55px] font-bold'>Recent Transactions</h1>
      {trans?.map((transaction) => (
        <div className='flex items-center justify-between mb-[28px]' key={transaction.id}>
        <div className='w-[45px] h-[45px] rounded-[12px] bg-[#1B1B1B] flex items-center justify-center'>
            {transaction.direction === "Debit" ? <img src="/assets/debit.png" alt="" /> : <img src="/assets/credit.png" alt="" />}
        </div>
        <div className='w-[150px]'>
            <p className='text-[14px] text-[#070707] font-semibold'>{transaction.product}</p>
            <p className='text-[12px] text-[#9B9A9A]'>{new Date (transaction?.date_created).toLocaleDateString()}  {new Date (transaction.date_created).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</p>
        </div>
        <p className='text-[14px] text-[#070707] w-[80px]'>{transaction.direction}</p>
        <p className='text-[14px] text-[#070707] font-bold w-[80px]'><NumericFormat value={transaction.transaction_amount}  thousandSeparator="," disabled prefix='₦' className='text-black bg-transparent'/></p>
        <p className={transaction.status === "Successful" ? "text-[12px] text-[#4CC800] px-[20px] py-[8px] bg-[#4CC800]/[0.1] w-[130px] rounded-[33px] text-center" : transaction.status === "Reversed" ? "text-[12px] text-[#333] px-[20px] py-[8px] bg-[#333]/[0.1] w-[130px] rounded-[33px] text-center" : transaction.status === "Processing" ? "text-[12px] text-[#FFC025] px-[20px] py-[8px] bg-[#FFC025]/[0.1] w-[130px] rounded-[33px] text-center" : "text-[12px] text-red-600 px-[20px] py-[8px] bg-red-100 w-[130px] rounded-[33px] text-center"}>
          {transaction.status}
        </p>
      </div>
      ))
}
      {/* <div className='flex items-center justify-between mb-[28px]'>
        <div className='w-[45px] h-[45px] rounded-[12px] bg-[#1B1B1B] flex items-center justify-center'>
            <img src="/assets/credit.png" alt="" />
        </div>
        <div>
            <p className='text-[14px] text-[#070707] font-semibold'>Balance Funding</p>
            <p className='text-[12px] text-[#9B9A9A]'>25 Apr 2023, 14:40 pm</p>
        </div>
        <p className='text-[14px] text-[#070707]'>Credit</p>
        <p className='text-[14px] text-[#070707] font-bold'>₦986.00</p>
        <p className='text-[14px] text-[#FFC025] bg-[#FFC025]/[0.1] px-[20px] py-[8px] rounded-[33px]'>Initiated</p>
      </div>
      <div className='flex items-center justify-between mb-[28px]'>
        <div className='w-[45px] h-[45px] rounded-[12px] bg-[#1B1B1B] flex items-center justify-center'>
            <img src="/assets/credit.png" alt="" />
        </div>
        <div>
            <p className='text-[14px] text-[#070707] font-semibold'>Balance Funding</p>
            <p className='text-[12px] text-[#9B9A9A]'>25 Apr 2023, 14:40 pm</p>
        </div>
        <p className='text-[14px] text-[#070707]'>Credit</p>
        <p className='text-[14px] text-[#070707] font-bold'>₦986.00</p>
        <p className='text-[14px] text-[#4CC800] bg-[#4CC800]/[0.1] px-[20px] py-[8px] rounded-[33px]'>Successful</p>
      </div>
      <div className='flex items-center justify-between mb-[28px]'>
        <div className='w-[45px] h-[45px] rounded-[12px] bg-[#1B1B1B] flex items-center justify-center'>
            <img src="/assets/credit.png" alt="" />
        </div>
        <div>
            <p className='text-[14px] text-[#070707] font-semibold'>Balance Funding</p>
            <p className='text-[12px] text-[#9B9A9A]'>25 Apr 2023, 14:40 pm</p>
        </div>
        <p className='text-[14px] text-[#070707]'>Credit</p>
        <p className='text-[14px] text-[#070707] font-bold'>₦986.00</p>
        <p className='text-[14px] text-[#4CC800] bg-[#4CC800]/[0.1] px-[20px] py-[8px] rounded-[33px]'>Successful</p>
      </div> */}
      <Link to='/transactions'>
        <p className='text-black text-center font-bold'>View More</p>
      </Link>
    </div>
  )
}

export default RecentTrans
