import React from 'react'
import { Label } from "reactstrap";

function FileUpload({title,handleChange,filename}) {
  return (
    <div className='mb-[21px]'>
      <Label for={title} className="text-black">
            {title}
          </Label>
          <div className="flex items-center justify-center w-[421px] h-[52px]">
            <label
              for={title}
              className="flex flex-col items-center justify-center w-[421px] h-[52px] border-2 border-gray-300 border-dashed cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 rounded-[32px]"
            >
              <div className="flex flex-col items-center justify-center w-[421px] h-[52px] rounded-[32px]">
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {filename === "" ? <span className="font-semibold text-[#B9BDC1]">Click to upload</span> : <span className="font-semibold text-[#B9BDC1]">Document Uploaded</span> }
                </p>
                
              </div>
              <input id={title} type="file" className="hidden" onChange={handleChange}/>
            </label>
          </div>
    </div>
  )
}

export default FileUpload
