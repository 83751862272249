import React,{useState,useEffect} from 'react'
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { userRequest } from '../../requestMethod';

function UserInvoice() {
  const [invoiceHistory,seInvoiceHistory] = useState(null)
  

  const token = useSelector(
    (state) => state?.user?.currentUser?.token?.access_token
  );

  

  const getInvoicehistory = async () => {
    const response = await userRequest.get("api/v2/customer/merchant/invoice/history?size=5",{
      headers: {"Authorization" : `Bearer ${token}`}
    })
    seInvoiceHistory(response.data.data.items)
  }

  useEffect(() => {
    getInvoicehistory();
  }, []);
  return (
    <div className='flex flex-col mt-[42px]'>
      <h1 className='text-[14px] font-bold mb-[24px]'>Recent Invoices</h1>
      {invoiceHistory?.length > 0 ?
        invoiceHistory?.map((history) => (
          <div className='flex justify-between items-center mb-[23px] gap-[30px] w-[100%]'>
          <div className='w-[45px] h-[45px] bg-white flex items-center justify-center rounded-[12px] shadow-[0px 14px 54px rgba(0, 0, 0, 0.03);]'>
              <img src="/assets/receipt.png" alt="" className='w-[16px] h-[24px]' />
          </div>
          <div className='w-[150px] text-left'>
          <p className='text-[14px] font-bold text-left'>Invoice</p>
          <p className='text-[14px] font-normal text-left'>{history.recipient_name || history.recipient_email}</p>
          <p className='text-[12px] font-normal text-[#a7a7a7] text-left'>{new Date (history.date_created).toLocaleDateString()}  {new Date (history.date_created).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</p>
      </div>
      <div>
          <p  className='text-[16px] font-bold w-[100px] text-right'>
          <NumericFormat
                    value={history.amount}
                    thousandSeparator=","
                    disabled
                    prefix="₦"
                    className="text-black bg-transparent w-[100px] text-right"
                  />
          </p>
      </div>
    </div>
      ))
      : (
        <div className='w-[100%] h-[100px] bg-[#fff] flex items-center justify-center'>
             <p>No Recent Invoices</p>
        </div>
      )
      
      }
        
    </div>
  )
}

export default UserInvoice
