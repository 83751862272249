import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { LogOut } from '../../redux/userRedux'
import { useDispatch } from 'react-redux'
import {  useNavigate, useLocation } from "react-router-dom";
import { userRequest } from "../../requestMethod";
import { useEffect } from "react";

function ForgotPasswordComplete() {
  const dispatch = useDispatch();
    const[otp,setOtp] = useState("")
    const [password, setPassword] = useState("")
    const[loading,setLoading] = useState(false);
    const[passwordChangeData,setPasswordChangeData] = useState(null)
    const navigate = useNavigate();
    const location = useLocation();
    const identifier = location.state.email
  const backHandler = (e) =>{
    e.preventDefault();
    dispatch(LogOut())
    window.localStorage.clear();
    navigate('/')
  }

    const forgotPasswordComplete = async () => {
        const response = await userRequest.post("api/v2/customer/forgot-password/complete",
        {
          identifier: identifier,
          otp: otp,
          new_password: password
        },);
        setPasswordChangeData(response.data)
      }

      useEffect(() => {
        if (passwordChangeData?.status === 'success') {
          navigate("/");
        }
      }, [passwordChangeData?.status, navigate]);

  return (
    <div className='flex justify-center items-center bg-white relative h-[100vh] w-[100%]'>
        <img src="/assets/login-design.png" alt="" className='absolute top-0 left-0 md:w-[auto] w-[300px]' />
        <div className='absolute top-[150px] left-[50px] flex gap-[15px] items-center justify-center cursor-pointer' onClick={backHandler}>
          <FaArrowLeft />
          <p className='text-[16px]'>Go Back</p>
        </div>
        <div className="mt-[0px]text-white flex flex-col items-center justify-center">
            <img src="/assets/blacktext.png" alt="" className='md:w-[200px] w-[150px]'/>
            <p className='text-black text-[16px] text-center mt-[20px]'>Kindly check your mail for OTP</p>
            <div className='md:w-[450px] w-[350px] bg-slate-100 py-[40px] rounded-[30px] flex flex-col items-center justify-center mt-[20px]'>
            <FormGroup className='mt-[10px]'>
                    <Label for="otp" className='text-black'>
                    OTP
                    </Label>
                    <Input
                    id="otp"
                    name="otp"
                    type="text"
                    className='md:w-[400px] w-[320px] rounded-[20px] pl-[20px] bg-white'
                    onChange={e => setOtp(e.target.value)}
                    />
                                   
                </FormGroup>
                <FormGroup className='mt-[10px]'>
                    <Label for="password" className='text-black'>
                    New Password
                    </Label>
                    <Input
                    id="password"
                    name="password"
                    type="text"
                    className='md:w-[400px] w-[320px] rounded-[20px] pl-[20px] bg-white'
                    onChange={e => setPassword(e.target.value)}
                    />
                                   
                </FormGroup>
                <div className="flex justify-center">
                <button
              className="text-white bg-[#4CC800] px-[60px] py-[10px] rounded-[136px] mt-[20px] w-[300px]"
              onClick={() => forgotPasswordComplete()}
            >
              Change Password
            </button>
                </div>
            </div>
        </div>
        <img src="/assets/login-design2.png" alt="" className='absolute bottom-0 right-0 w-[300px] md:w-[auto]' />
    </div>
  );
}

export default ForgotPasswordComplete;

