import React, { useState, useEffect } from "react";
import Topbar from "../../components/Topbar/Topbar";
import { FaChevronRight } from "react-icons/fa";
import { userRequest } from "../../requestMethod";
import { useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";

function Portfolio({ setUserType, userType }) {
  const [targetSavings, setTargetSavings] = useState(null);
  const [lockedFunds, setLockedFunds] = useState(null);
  const [filterValue, setFilterValue] = useState("All");
  const token = useSelector(
    (state) => state?.user?.currentUser?.token?.access_token
  );
  const getPortFolio = async () => {
    const response = await userRequest.get("api/v2/customer/portfolio/get", {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (filterValue === "All") {
      setTargetSavings(response?.data?.data?.target_savings);
      setLockedFunds(response.data.data.investments);
    } else if (filterValue === "Target") {
      setTargetSavings(response?.data?.data?.target_savings);
      setLockedFunds(null);
    } else if (filterValue === "locked") {
      setTargetSavings(null);
      setLockedFunds(response.data.data.investments);
    }
  };

  console.log("target", targetSavings);
  console.log("lock", lockedFunds);

  useEffect(() => {
    getPortFolio();
  }, [filterValue]);
  return (
    <div style={{ flex: 4 }}>
      <Topbar setUserType={setUserType} userType={userType} />
      <div className="bg-[#F0F0F0] w-[100%] min-h-[100vh] px-[40px] pt-[30px]">
        <h1 className="text-[24px] font-bold pb-[15px] mb-[112px]">
          Your Portfolio
        </h1>
        <div className="w-[100%] h-[66px] bg-white rounded-tl-[14px] rounded-tr-[14px] flex items-center justify-end mb-[20px]">
          <select
            name="filter"
            id="portfolio"
            className="w-[176px] h-[43px] rounded-[10px] mr-[27px] pl-[10px]"
            onChange={(e) => setFilterValue(e.target.value)}
          >
            <option value="" disabled>
              Filter
            </option>
            <option value="All">All</option>
            <option value="Target">Target Savings</option>
            <option value="locked">Locked Funds</option>
          </select>
        </div>
        {filterValue === "All" &&
        targetSavings?.length === 0 &&
        lockedFunds.length === 0 ? (
          <div className="flex items-center justify-center bg-[#fff] w-[100%] h-[300px]">
            <p className="font-bold text-[24px]">No active Portfolio</p>
          </div>
        ) : filterValue === "Target" && targetSavings.length === 0 ? (
          <div className="flex items-center justify-center bg-[#fff] w-[100%] h-[300px]">
            <p className="font-bold text-[24px]">No active Target Savings</p>
          </div>
        ) : ( filterValue === "locked" && lockedFunds.length === 0 ? (
          <div className="flex items-center justify-center bg-[#fff] w-[100%] h-[300px]">
            <p className="font-bold text-[24px]">No active lock funds</p>
          </div>
        ) : (
          <div className="w-[100%]">
          <table className="w-[100%] px-[10px]">
            <thead>
              <tr className="flex items-center justify-center gap-[50px] h-[52px]">
                <th className="text-[12px] w-[130px]"></th>
                <th className="text-[12px] w-[100px]">START DATE</th>
                <th className="text-[12px] w-[100px]">TOTAL AMOUNT</th>
                <th className="text-[12px] w-[80px]">DURATION</th>
                <th className="text-[12px] w-[100px]">MATURITY DATE</th>
                <th className="text-[12px] w-[120px]">INTEREST EARNED</th>
                <th className="text-[12px] w-[150px]">AMOUNT AT MATURITY</th>
              </tr>
            </thead>
            <tbody>
              {lockedFunds?.map((portfolio) => (
                <tr className="flex items-center justify-center gap-[50px] h-[52px] bg-white px-[15px] border-b-[1px]">
                  <td className="text-[12px] text-[#FFC025] px-[20px] py-[8px] bg-[#FFC025]/[0.1] w-[130px] rounded-[33px]">
                    Locked Funds
                  </td>
                  <td className="text-[12px] w-[100px]">
                    {" "}
                    {new Date(portfolio?.start_date).toLocaleDateString()}{" "}
                  </td>
                  <td className="text-[12px] w-[100px]">
                    <NumericFormat
                      value={portfolio.amount}
                      thousandSeparator=","
                      disabled
                      prefix="₦"
                      className="text-black bg-transparent"
                    />
                  </td>
                  <td className="text-[12px] w-[80px]">
                    {portfolio.duration} days
                  </td>
                  <td className="text-[12px] w-[100px]">
                    {" "}
                    {new Date(portfolio?.end_date).toLocaleDateString()}{" "}
                  </td>
                  <td className="text-[12px] w-[120px]">
                    <NumericFormat
                      value={portfolio.interest_accrued}
                      thousandSeparator=","
                      disabled
                      prefix="₦"
                      className="text-black bg-transparent"
                    />
                  </td>
                  <td className="text-[12px] w-[150px] text-left">
                    <NumericFormat
                      value={portfolio.withdrawable_amount}
                      thousandSeparator=","
                      disabled
                      prefix="₦"
                      className="text-black bg-transparent"
                    />
                  </td>
                </tr>
              ))}
              {targetSavings?.map((portfolio) => (
                <tr className="flex items-center justify-center gap-[50px] h-[52px] bg-white border-b-[1px]">
                  <td className="text-[12px] text-[#4CC800] px-[20px] py-[8px] bg-[#4CC800]/[0.1] w-[130px] rounded-[33px]">
                    Target Savings
                  </td>
                  <td className="text-[12px] w-[100px]">
                    {" "}
                    {new Date(portfolio?.start_date).toLocaleDateString()}{" "}
                  </td>
                  <td className="text-[12px] w-[100px]">
                    <NumericFormat
                      value={portfolio.target_amount}
                      thousandSeparator=","
                      disabled
                      prefix="₦"
                      className="text-black bg-transparent"
                    />
                  </td>
                  <td className="text-[12px] w-[80px]">
                    {portfolio.savings_duration} days
                  </td>
                  <td className="text-[12px] w-[100px]">
                    {new Date(portfolio?.end_date).toLocaleDateString()}{" "}
                  </td>
                  <td className="text-[12px] w-[120px]">
                    <NumericFormat
                      value={portfolio.interest}
                      thousandSeparator=","
                      disabled
                      prefix="₦"
                      className="text-black bg-transparent"
                    />
                  </td>
                  <td className="text-[12px] w-[150px]">
                    <NumericFormat
                      value={portfolio.target_amount}
                      thousandSeparator=","
                      disabled
                      prefix="₦"
                      className="text-black bg-transparent"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        )
        )}
        
      </div>
    </div>
  );
}

export default Portfolio;
