import React from 'react'
import QuickTransactions from './QuickTransactions'
import AccountBalance from './AccountBalance'
import './merchant.css'
import MerchantTag from './MerchantTag'
import MerchantTrans from './MerchantTrans'
import MerchantVfd from './MerchantVfd'

function WidgetLg({ merchant_tag, merchantBank, merchant ,chooseBus, roleName, loading}) {
  return (
    <div className='widgetLg'>
      <div className='flex'>
        <AccountBalance merchantBank={merchantBank} merchant={merchant} loading={loading}/>
        <div className="flex flex-col ml-[24px]">
            <MerchantVfd merchantBank={merchantBank} merchant={merchant} loading={loading}/>
            <MerchantTag merchant_tag={merchant_tag} merchant={merchant} loading={loading}/>
        </div>
      </div>
      <QuickTransactions  merchant={merchant} chooseBus={chooseBus} roleName={roleName}/>
      <MerchantTrans/>
    </div>
  )
}

export default WidgetLg
