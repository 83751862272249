import React,{useState} from 'react'
import VerifyCanvas from './VerifyCanvas'

function CompleteVerif() {
    const [showVerifyCanvas, setShowVerifyCanvas] = useState(false);
    const handleVerifyCanvas = () => {
        setShowVerifyCanvas(!showVerifyCanvas)
    }

  return (
    <div className="cursor-pointer mb-[20px]" >
      <div
        className="w-[373px] h-[66px] bg-[#1B1B1B] rounded-[14px] flex items-center justify-between px-[20px]"
        onClick={() =>handleVerifyCanvas()}
      >
        <div className="flex items-center justify-center gap-[12px]">
          <div className="w-[13px] h-[13px] bg-[#4CC800] rounded-[50%] text-white"></div>
          <p className="text-[#fff]">Complete Account Verification</p>
        </div>
        <div>
          <img src="/assets/arrow.png" alt="" />
        </div>
      </div>
      <VerifyCanvas  showVerifyCanvas={showVerifyCanvas} handleVerifyCanvas={handleVerifyCanvas}/>
    </div>
  )
}

export default CompleteVerif
