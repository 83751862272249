import React, { useState } from "react";
import "../dashHome.css";
import { NumericFormat } from "react-number-format";
import LockedFundsCanvas from "./LockedFundsCanvas";
import { userRequest } from "../../../requestMethod";
import { useSelector } from "react-redux";

function LockFunds({locked_funds,wallet}) {
  const [showLockedFunds, setShowLockedFunds] = useState(false);
  const handleCloseLockedFunds = () => setShowLockedFunds(false);
  const handleShowLockedFunds = () => setShowLockedFunds(true);
  const token = useSelector(
    (state) => state?.user?.currentUser?.token?.access_token
  );

  
  return (
    <div className="w-[216px] h-[176px] rounded-[12px] green-gradient flex flex-col justify-center pl-[19px]">
      <p className="text-[15px] text-black mb-[22px]">Locked Funds</p>
      <p className="text-[24px] text-black mb-[22px]">{<NumericFormat value={locked_funds}  thousandSeparator="," prefix='₦' disabled className='text-black bg-transparent'/>|| 0.00} </p>
      <div>
        <button
          onClick={handleShowLockedFunds}
          className="w-[129px] h-[31px] rounded-[32px] bg-white border-none outline-none"
        >
          <p className="text-[#070707] text-[12px]">Lock Funds</p>
        </button>

        <LockedFundsCanvas showLockedFunds={showLockedFunds} handleCloseLockedFunds={handleCloseLockedFunds} handleShowLockedFunds={handleShowLockedFunds} wallet={wallet}/>
      </div>
    </div>
  );
}

export default LockFunds;
