import { Close } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Offcanvas, Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import { FaCopy, FaCheckCircle, FaEdit, FaArrowLeft } from "react-icons/fa";
import { FormGroup, Input, Label } from "reactstrap";
import { userRequest } from '../../requestMethod';

function Profile({ showProfile, handleCloseProfile }) {
  const [key, setKey] = useState("profile");
  const [showProfileMain, setShowProfileMain] = useState(true);
  const [showSecurityPage, setShowSecurityPage] = useState(true);
  const [pinChangePage, setPinChangePage] = useState(false)
  const [passChangePage, setPassChangePage] = useState(false)
  const [show2Fa, setShow2Fa] = useState(false);
  const [profile,setProfile] = useState(null)
  const [accountData, setAccountData] = useState(null);
  const [firstName,setFirstName] = useState("")
  const [lastName,setLastName] = useState("")
  const [pickGender,setPickGender] = useState("")
  const [Rel,setRel] = useState("")
  const [emailAddress,setEmailAddress] = useState("")
  const [PhoneNumber, setPhoneNumber] = useState("")
  const [pin, setPin] = useState("")
  const [changePasswordOtp,setChangePasswordOtp] = useState("")
  const [newPassword,setNewPassword] = useState("")
  const [passwordChangeData,setPasswordChangeData] = useState(null)
  const [pinChangeData,setPinChangeData] = useState(null)
  const [pinDetails,setPinDetails] = useState(null);
  const [nextofKinData,setNextofKinData] = useState(null)
  const[ppin,setPPin] = useState("")
  const[NewPin,setNewPin]= useState("")
  const[pinChangeOTP,setPinChangeOTP]= useState("")
  const [confirmNewPin, setConfirmNewPin] = useState("")
  const email = profile?.emailaddress



  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const show2faPage = () => {
    setShow2Fa(true);
    setShowProfileMain(false);
  };

  const showPinChangePage = () => {
    setPinChangePage(true);
    setShowSecurityPage(false);
  };

  const showPassChangePage = () => {
    setPassChangePage(true);
    setShowSecurityPage(false);
  };

  const handleShowSecurity = () => {
    setPinChangePage(false);
    setPassChangePage(false)
    setShowSecurityPage(true);
  };

  const handleShowProfile = () => {
    setShow2Fa(false);
    setShowProfileMain(true);
  };

  

  const token = useSelector(
    (state) => state?.user?.currentUser?.token?.access_token
  );
  const getProfile = async () => {
    const response = await userRequest.get("api/v2/customer/me",{
      headers: {"Authorization" : `Bearer ${token}`}
    });
    setProfile(response?.data?.data?.customer)
    // setAccountData(response?.data?.data?.account_numbers[0])
}

console.log(profile)





const forgotPassword = async () => {
  const response = await userRequest.post("api/v2/customer/forgot-password",
  {
    identifier: profile?.email_address
  },
  {
    headers: {"Authorization" : `Bearer ${token}`}
  });
  
}

const forgotPin = async () => {
  const response = await userRequest.post("api/v2/customer/forgot-pin",
  {
    
  },
  {
    headers: {"Authorization" : `Bearer ${token}`}
  });
  setPinDetails(response.data)
}


const forgotPasswordComplete = async () => {
  const response = await userRequest.post("api/v1/customer/forgot-password/complete",
  {
    identifier: profile?.email_address,
    otp: changePasswordOtp,
    new_password: newPassword
  },
  {
    headers: {"Authorization" : `Bearer ${token}`}
  });
  setPasswordChangeData(response.data)
}

const forgotPinComplete = async () => {
  const response = await userRequest.post("api/v1/customer/forgot-pin/complete",
  {
    auth_token: pinDetails?.data?.auth_tokn,
    otp: pinChangeOTP,
    new_pin: NewPin
  },
  {
    headers: {"Authorization" : `Bearer ${token}`}
  });
  setPinChangeData(response.data)
}


useEffect(() => {
  getProfile();
},[]);

const addNextOfKin = async (e) => {
  const response = await userRequest.post(
    "api/v2/customer/next-of-kin/add",
    {
      gender: pickGender,
      last_name: lastName,
      first_name: firstName,
      email_address: emailAddress,
      mobile_number: PhoneNumber,
      relationship: Rel,
      transaction_pin:pin
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        source: "web",
        "x-api-key": "94684537",
      },
    }
  );
  setNextofKinData(response.data)
};


  return (
    <Offcanvas
      show={showProfile}
      onHide={handleCloseProfile}
      placement="end"
      className="py-[20px] pl-[30px] flex flex-col w-[505px]"
    >
      <Offcanvas.Header closeButton className="w-[100%]">
        <Offcanvas.Title className="mt-[60px]">
          Profile & Security
        </Offcanvas.Title>
        <Close onClick={handleCloseProfile} className="mr-[-200px]" />
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-[40px]"
        >
          <Tab eventKey="profile" title="Profile">
            {showProfileMain && (
              <>
                <div className="w-[418px] h-[143px] flex flex-col items-center justify-center bg-[#4CC800] rounded-[7px] mb-[41px]">
                  <h1 className="text-white font-bold text-[24px]">
                    {profile?.first_name} {profile?.last_name}
                  </h1>
                  <p className="text-white font-normal text-[14px]">
                    {profile?.email_address}
                  </p>
                </div>
                <div className="w-[418px] h-[506px] flex flex-col items-center justify-center bg-[#F5F6FA] rounded-[7px] px-[23px] py-[37px]">
                  <div className="flex justify-between items-end w-[100%]">
                    <div className="mt-[16px]">
                      <h1 className="mb-[15px] font-bold">PennTag:</h1>
                      <p className="text-[#9EA0A4]">{profile?.username}</p>
                    </div>
                    <div className="flex gap-[10px]">
                      <h3 className="text-[#4CC800] text-[12px]">Copy</h3>
                      <FaCopy className="fill-[#D9DBDC]" />
                    </div>
                  </div>
                  <div className="w-[100%] h-[2px] bg-[#D9DBDC] mt-[17px]"></div>
                  <div className="flex justify-between items-end w-[100%]">
                    <div className="mt-[16px]">
                      <h1 className="mb-[15px] font-bold">
                        Account Number ({accountData?.bank_name}):
                      </h1>
                      <p className="text-[#9EA0A4]">{accountData?.nuban}</p>
                    </div>
                    <div className="flex gap-[10px]">
                      <h3 className="text-[#4CC800] text-[12px]">Copy</h3>
                      <FaCopy className="fill-[#D9DBDC]" />
                    </div>
                  </div>
                  <div className="w-[100%] h-[2px] bg-[#D9DBDC] mt-[17px]"></div>
                  <div className="flex justify-between items-end w-[100%]">
                    <div className="mt-[16px]">
                      <h1 className="mb-[15px] font-bold">Phone Number:</h1>
                      <p className="text-[#9EA0A4]">{profile?.mobile_number}</p>
                    </div>
                    <div className="flex gap-[10px]">
                      <h3 className="text-[#4CC800] text-[12px]">Copy</h3>
                      <FaCopy className="fill-[#D9DBDC]" />
                    </div>
                  </div>
                  <div className="w-[100%] h-[2px] bg-[#D9DBDC] mt-[17px]"></div>
                  <div className="flex justify-between items-end w-[100%]">
                    <div className="mt-[16px]">
                      <h1 className="mb-[15px] font-bold">Email Address:</h1>
                      <p className="text-[#9EA0A4]">
                        {profile?.email_address}
                      </p>
                    </div>
                    <div className="flex gap-[10px]">
                      <h3 className="text-[#4CC800] text-[12px]">Verified</h3>
                      <FaCheckCircle className="fill-[#4CC800]" />
                    </div>
                  </div>
                  <div className="w-[100%] h-[2px] bg-[#D9DBDC] mt-[17px]"></div>
                  <div className="flex justify-between items-end w-[100%]">
                    <div className="mt-[16px]">
                      <h1 className="mb-[15px] font-bold">Next of kin:</h1>
                      <p className="text-[#9EA0A4]">Set your next of kin</p>
                    </div>
                    <div
                      className="flex gap-[10px]"
                      onClick={() => show2faPage()}
                    >
                      <h3 className="text-[#4CC800] text-[12px]">Edit</h3>
                      <FaEdit className="fill-[#D9DBDC]" />
                    </div>
                  </div>
                  <div className="w-[100%] h-[2px] bg-[#D9DBDC] mt-[17px]"></div>
                </div>
              </>
            )}
            {show2Fa && (
              <>
                <FaArrowLeft
                  className="mb-[20px] relative left-[-10px]"
                  onClick={() => handleShowProfile()}
                />
                <div className="w-[418px] h-[100px] flex flex-col items-center justify-center bg-[#4CC800] rounded-[7px] mb-[41px]">
                  <h1 className="text-white font-bold text-[24px]">
                    Next of Kin
                  </h1>
                </div>
                  <FormGroup>
                    <Label for="firstName">First Name</Label>
                    <Input
                      id="firstName"
                      name="firstName"
                      className="w-[421px] h-[63px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] outline-none border-none"
                      placeholder=""
                      type="text"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="lastName">Last Name</Label>
                    <Input
                      id="lastName"
                      name="lastName"
                      className="w-[421px] h-[63px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] outline-none border-none"
                      placeholder=""
                      type="text"
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="email">Email Address</Label>
                    <Input
                      id="email"
                      name="email"
                      className="w-[421px] h-[63px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] outline-none border-none"
                      placeholder=""
                      type="email"
                      onChange={(e) => setEmailAddress(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="phone">Phone Number</Label>
                    <Input
                      id="phone"
                      name="phone"
                      className="w-[421px] h-[63px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] outline-none border-none"
                      placeholder=""
                      type="text"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="rel">Relationship with Individual</Label>
                    <Input
                      id="rel"
                      name="rel"
                      className="w-[421px] h-[63px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] outline-none border-none"
                      placeholder=""
                      type="text"
                      onChange={(e) => setRel(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="gender">Gender</Label>
                    <Input
                      id="gender"
                      name="gender"
                      className="w-[421px] h-[63px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] outline-none border-none"
                      placeholder=""
                      type="select"
                      onChange={(e) => setPickGender(e.target.value)}
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="pin">Pin</Label>
                    <Input
                      id="pin"
                      name="pin"
                      className="w-[421px] h-[63px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] outline-none border-none"
                      placeholder=""
                      type="text"
                      onChange={(e) => setPin(e.target.value)}
                    />
                  </FormGroup>
                  <button type="submit" className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[3rem]" onClick={() => {addNextOfKin();toggle()}}>
                    Proceed
                  </button>
              </>
            )}
            <Modal isOpen={modal} toggle={toggle} className='w-[400px] h-[500px] top-[20%] rounded-[14px]'>
              <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                {nextofKinData?.status === "success" ? 
                  <img src="/assets/checkmark.png" alt="" />  : <img src="/assets/Cancel.png" alt="" />
              }
               <p className="mt-[20px] text-center">{nextofKinData?.response?.data?.message}</p>
               <button onClick={() => {toggle();handleCloseProfile()}} className='w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px] text-white'>Ok</button>
              </ModalBody>
            </Modal>
          </Tab>
          <Tab eventKey="security" title="Security">
            {showSecurityPage && <>
                <div className="w-[418px] h-[143px] flex flex-col items-center justify-center bg-[#4CC800] rounded-[7px] mb-[41px]">
              <h1 className="text-white font-bold text-[24px]">{profile?.first_name} {profile?.last_name}</h1>
              <p className="text-white font-normal text-[14px]">
                {profile?.email_address}
              </p>
            </div>
            <div className="w-[418px] h-[506px] flex flex-col items-center justify-center bg-[#F5F6FA] rounded-[7px] px-[23px] py-[37px]">
              <div className="flex justify-between items-end w-[100%]">
                <div className="mt-[16px]">
                  <h1 className="mb-[15px] font-bold">
                    Bank Verification Number (BVN)
                  </h1>
                  <p className="text-[#9EA0A4]">**********</p>
                </div>
                {profile?.onboarding_state === "BVN" ?  <div className="flex gap-[10px]">
                  <h3 className="text-[#4CC800] text-[12px]">Verified</h3>
                  <FaCheckCircle className="fill-[#4CC800]" />
                </div> :  <div className="flex gap-[10px]">
                  <h3 className="text-[#4CC800] text-[12px]">Not Verified</h3>
                  <FaCheckCircle className="fill-red" />
                </div>}
               
              </div>
              <div className="w-[100%] h-[2px] bg-[#D9DBDC] mt-[17px]"></div>
              <div className="flex justify-between items-end w-[100%]">
                <div className="mt-[16px]" >
                  <h1 className="mb-[15px] font-bold">Transaction Pin</h1>
                  <p className="text-[#9EA0A4]">****</p>
                </div>
                <div className="flex gap-[10px]" onClick={() => {forgotPin();showPinChangePage()}}>
                  <h3 className="text-[#4CC800] text-[12px]">Change</h3>
                  <FaEdit className="fill-[#D9DBDC]" />
                </div>
              </div>
              <div className="w-[100%] h-[2px] bg-[#D9DBDC] mt-[17px]"></div>
              <div className="flex justify-between items-end w-[100%]">
                <div className="mt-[16px]">
                  <h1 className="mb-[15px] font-bold">Password</h1>
                  <p className="text-[#9EA0A4]">****</p>
                </div>
                <div className="flex gap-[10px]" onClick={() => {forgotPassword();showPassChangePage()}} >
                  <h3 className="text-[#4CC800] text-[12px]">Change</h3>
                  <FaEdit className="fill-[#D9DBDC]" />
                </div>
              </div>
              <div className="w-[100%] h-[2px] bg-[#D9DBDC] mt-[17px]"></div>
              <div className="flex justify-between items-end w-[100%]">
                <div className="mt-[16px]">
                  <h1 className="mb-[15px] font-bold">
                    2 Factor Authentication (2FA)
                  </h1>
                  <p className="text-[#9EA0A4]">Add extra layer of security</p>
                </div>
                <div className="flex gap-[10px]">
                  <h3 className="text-[#4CC800] text-[12px]">Set 2FA</h3>
                  <FaEdit className="fill-[#D9DBDC]" />
                </div>
              </div>
              <div className="w-[100%] h-[2px] bg-[#D9DBDC] mt-[17px]"></div>
            </div>
            </>}
            {pinChangePage && 
            <>
                <FaArrowLeft
                  className="mb-[20px] relative left-[-10px]"
                  onClick={() => handleShowSecurity()}
                />
                 <div className="w-[418px] h-[143px] flex flex-col items-center justify-center bg-[#4CC800] rounded-[7px] mb-[41px]">
              <h1 className="text-white font-bold text-[24px]">Change Transaction Pin</h1>
              <p className="text-white font-normal text-[14px]">
              Kindly enter the OTP sent to your mail
              </p>
            </div>
            <FormGroup>
                    <Label for="pinOtp">OTP</Label>
                    <Input
                      id="pinOtp"
                      name="pinOtp"
                      className="w-[421px] h-[63px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] outline-none border-none"
                      placeholder=""
                      type="text"
                      onChange={(e) => setPinChangeOTP(e.target.value)}
                    />
            </FormGroup>
            <FormGroup className="mt-[60px]">
                    <Label for="newPin">New Transaction Pin</Label>
                    <Input
                      id="newPin"
                      name="newPin"
                      className="w-[421px] h-[63px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] outline-none border-none"
                      placeholder=""
                      type="text"
                      onChange={(e) => setNewPin(e.target.value)}
                    />
            </FormGroup>
            <FormGroup>
                    <Label for="confirmPin">Confirm New Transaction Pin</Label>
                    <Input
                      id="confirmPin"
                      name="confirmPin"
                      className="w-[421px] h-[63px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] outline-none border-none"
                      placeholder=""
                      type="text"
                      onChange={(e) => setConfirmNewPin(e.target.value)}
                    />
            </FormGroup>
            <button className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[5rem]" onClick={() => {forgotPinComplete();toggle()}}>
              Proceed
            </button>
            <Modal isOpen={modal} toggle={toggle} className='w-[400px] h-[500px] top-[20%] rounded-[14px]'>
            <ModalBody className="flex flex-col items-center justify-center py-[20px]">
              {pinChangeData?.status === "success" ? 
                <img src="/assets/checkmark.png" alt="" />  : <img src="/assets/Cancel.png" alt="" />
            }
             <p className="mt-[20px] text-center">{pinChangeData?.message}</p>
             <button onClick={toggle} className='w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px]'>Continue</button>
            </ModalBody>
          </Modal>
            </>
            }
            {passChangePage && 
                <>
                <FaArrowLeft
                  className="mb-[20px] relative left-[-10px]"
                  onClick={() => handleShowSecurity()}
                />
                 <div className="w-[418px] h-[143px] flex flex-col items-center justify-center bg-[#4CC800] rounded-[7px] mb-[41px]">
              <h1 className="text-white font-bold text-[24px]">New Password</h1>
              <p className="text-white font-normal text-[14px]">
              Kindly enter the OTP sent to your mail
              </p>
            </div>
            <FormGroup>
                    <Label for="pinOtp">OTP</Label>
                    <Input
                      id="pinOtp"
                      name="pinOtp"
                      className="w-[421px] h-[63px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] outline-none border-none"
                      placeholder=""
                      type="text"
                      onChange={(e) => setChangePasswordOtp(e.target.value)}
                    />
            </FormGroup>
            <FormGroup className="mt-[60px]">
                    <Label for="newPin">Enter New Password</Label>
                    <Input
                      id="newPin"
                      name="newPin"
                      className="w-[421px] h-[63px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] outline-none border-none"
                      placeholder=""
                      type="password"
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
            </FormGroup>
            <FormGroup>
                    <Label for="confirmPin">Confirm New Password</Label>
                    <Input
                      id="confirmPin"
                      name="confirmPin"
                      className="w-[421px] h-[63px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] outline-none border-none"
                      placeholder=""
                      type="password"
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
            </FormGroup>
            <button className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[5rem]" onClick={() => {forgotPasswordComplete();toggle()}}>
              Proceed
            </button>
            <Modal isOpen={modal} toggle={toggle} className='w-[400px] h-[500px] top-[20%] rounded-[14px]'>
              <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                {passwordChangeData?.status === "success" ? 
                  <img src="/assets/checkmark.png" alt="" />  : <img src="/assets/Cancel.png" alt="" />
              }
               <p className="mt-[20px] text-center">{passwordChangeData?.message}</p>
               <button onClick={toggle} className='w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px]'>Continue</button>
              </ModalBody>
            </Modal>
            </>
            }
          </Tab>
        </Tabs>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default Profile;
