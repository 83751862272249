import React from 'react'
import {FaCopy} from 'react-icons/fa'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';


function PennTag() {
    const user = useSelector((state) => state?.user?.currentUser?.data)


    const copied = () => toast.success("PennTag copied!",{ autoClose: 100 });
  return (
    <div className='w-[377px] h-[126px] bg-[#1a1a1a] rounded-[14px] flex flex-col justify-center pl-[30px] relative mb-[8px]'>
      <div className='flex flex-col'>
        <p className='text-white text-[12px] mb-[10px]'>Penntag</p>
        <div className='flex gap-3 items-center '>
            <p className='text-white text-[19px] font-bold'>{user?.username}</p>
            <CopyToClipboard text={user?.username}>
                <FaCopy className='fill-[#A3A3A3] text-[19px]' onClick={copied}/>
            </CopyToClipboard>
            <ToastContainer />
        </div>
      </div>
      <div className='absolute top-0 right-0'>
            <img src="/assets/greendown.png" className='' alt="" />
      </div>
    </div>
  )
}

export default PennTag
