import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "reactstrap";
import Topbar from "../../components/Topbar/Topbar";
import { LogOut } from "../../redux/userRedux";
import Profile from "./Profile";
import AddNewBusiness from "../../components/Merchant/MerchantDashboard/AddNewBusiness";
import NewBusiness from "../../components/Merchant/MerchantDashboard/NewBusiness";
import CompanyInfo from "../../components/Merchant/MerchantDashboard/CompanyInfo";

function MerchSettings({ userType, setUserType,merchantVerif,merchant }) {
  const [showProfile, setShowProfile] = useState(false);
  const handleShowProfile = () => setShowProfile(true);
  const handleCloseProfile = () => setShowProfile(false);
  const [balanceState, setBalanceState] = useState(true);
  const handleCloseBusinessCanvas = () => {
    setSHowNewBusCanvas(false);
  };
  const [showNewBusCanvas, setSHowNewBusCanvas] = useState(false);
  const toggleBus = () => {
    setSHowNewBusCanvas(!showNewBusCanvas);
  };

  const [showCompanyInfo, setShowCompanyInfo] = useState(false);
  const handleShowCompanyInfo = () => {
    setShowCompanyInfo(!showCompanyInfo)
}

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(LogOut());
    window.localStorage.clear();
    navigate("/");
  };

  return (
    <div style={{ flex: 4 }}>
      <Topbar setUserType={setUserType} userType={userType} />
      <div className="bg-[#F0F0F0] w-[100%] min-h-[100vh] px-[40px] pt-[30px]">
        <h1 className="text-[24px] font-bold pb-[15px] mb-[26px]">Settings</h1>
        <div className="w-[90%] bg-white h-[128px] rounded-[14px] flex flex-col justify-center pl-[39px] mb-[18px]">
          <div className="flex items-center  gap-[5px] mb-[18px]">
            <span className="w-[16px] h-[16px] rounded-[50%] bg-[#4CC800]"></span>
            <p className="text-[14px] font-bold">Did you Know?</p>
          </div>
          <p className="text-[14px] font-normal">
            Penn Users get free transfers when they send money from their penn
            wallet to another penn user?
          </p>
        </div>
        <div className="w-[90%] bg-white h-[320px] rounded-[14px] flex flex-col justify-center pl-[39px]">
          <Link to="/Terms&Conditions">
            <p className="text-[14px] font-normal mb-[16px] cursor-pointer">
              Show Account Balance
            </p>
          </Link>
          <Link to="/user-management">
            <p className="text-[14px] font-normal mb-[16px] cursor-pointer">
              User Management
            </p>
          </Link>
          <span className="cursor-pointner" onClick={() => handleShowCompanyInfo()}>
            <p className="text-[14px] font-normal mb-[16px] cursor-pointer">
             Company Information
            </p>
          </span>
          <span className="cursor-pointner mb-[16px]" onClick={() => toggleBus()}>
            <span className="text-[14px] font-normal mb-[16px] cursor-pointer">
             Add New Business
            </span>
          </span>
          <Link to="/Terms&Conditions">
            <p className="text-[14px] font-normal mb-[16px] cursor-pointer">
              Terms & Conditions
            </p>
          </Link>
          <p
            className="text-[14px] font-normal mb-[16px] cursor-pointer"
            onClick={logoutHandler}
          >
            Log Out
          </p>
        </div>
        <NewBusiness merchantVerif={merchantVerif} handleCloseBusinessCanvas={handleCloseBusinessCanvas} showNewBusCanvas={showNewBusCanvas} />
        <CompanyInfo showCompanyInfo={showCompanyInfo} handleShowCompanyInfo={handleShowCompanyInfo} merchant={merchant}/>
      </div>
      <Profile
        showProfile={showProfile}
        handleShowProfile={handleShowProfile}
        handleCloseProfile={handleCloseProfile}
      />
    </div>
  );
}

export default MerchSettings;
