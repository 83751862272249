import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';
import { FaCopy } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import { Spinner } from 'reactstrap';

function MerchantVfd({merchantBank, merchant, loading}) {
const copied = () => toast.success("Account details copied!",{ autoClose: 100 });
return (
  <div className='w-[377px] h-[126px] bg-[#1a1a1a] rounded-[14px] flex flex-col justify-center pl-[30px] mb-[7px] relative'>
    <div className='flex flex-col'>
      <p className='text-white text-[12px] mb-[10px]'>Bank: {loading ? <Spinner size='sm' color='light'/> : merchant?.wallet?.bank_name}</p>
      <p className='text-white text-[12px] mb-[10px] z-10'>Account Name: {loading ? <Spinner size='sm' color='light'/> :merchant?.wallet?.account_number}</p>
      <div className='flex gap-3 items-center '>
          <p className='text-white text-[19px] font-bold'>{loading ? <Spinner size='sm' color='light'/> : merchant?.wallet?.account_number}</p>
          <CopyToClipboard text={merchant?.wallet?.account_number}>
              <FaCopy className='fill-[#A3A3A3] text-[19px]' onClick={copied}/>
          </CopyToClipboard>
          <ToastContainer />
      </div>
    </div>
    <div className='absolute bottom-0 right-0'>
          <img src="/assets/greenup.png" className='' alt="" />
    </div>
  </div>
)
}

export default MerchantVfd
