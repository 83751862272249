/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Close } from "@mui/icons-material";
import { Input, Modal, ModalBody, Spinner } from "reactstrap";
import { userRequest } from "../../../requestMethod";
import {FaChevronDown,FaMoneyBill,FaLock} from 'react-icons/fa'
import {BsFillPiggyBankFill} from 'react-icons/bs'
import { Tab, Tabs } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import './QuickTransactions.css'
import LockedFundsCanvas from "./LockedFundsCanvas";
import TargetSavingsCanvas from "./TargetSavingsCanvas";
import TransferCanvas from "./TransferCanvas";

function QuickTransactions({wallet}) {
  const [openQuickModal, setOpenQuickModal] = useState(false);
  const[ showBills, setShowBills] = useState(false)
  const handleCloseBills = () => setShowBills(false);
  const handleOpenBills = () => setShowBills(true);
  const[ showAirtime, setShowAirtime] = useState(false)
  const[airtimeProvider, setAirtimeProvider] = useState(null);
  const[cableProvider, setCableProvider] = useState(null);
  const[discoProvider, setDiscoProvider] = useState(null);
  const[betProvider,setBetProvider] = useState(null);
  const[selectedCableP, setSelectedCableP] = useState(null);
  const[selectedDiscoP, setSelectedDiscoP] = useState(null);
  const[cablePackage, setCablePackage] = useState(null);
  const[discoPackage, setDiscoPackage] = useState(null);
  const[betPackage,setBetPackage] = useState(null)
  const[cableBundle,setCableBundle] = useState()
  const navigate = useNavigate();
  const [selectedDataP,setSelectedDataP] = useState(null)
  const[selectedBetP,setSelectedBetP] =useState(null)
  const [selectedDataBundle,setSelectedDataBundle] = useState()
  const[betItemCode,setBetItemCode] = useState("");
  const[airtimeNumber, setAirtimeNumber] = useState("");
  const[airtimeAmount, setAirtimeAmount] = useState(Number);
  const[betAmount, setBetAmount] = useState(Number);
  const[betId, setBetId] = useState("")
  const[dataProvider, setDataProvider] = useState(null);
  const[dataNumber, setDataNumber] = useState("");
  const [smartCard, setSmartCard]= useState("");
  const[ discoMeter, setDiscoMeter] = useState("")
  const [discoAmount, setDiscoAMount] = useState("")
  const [discoItemCode,setDiscoItemCode] = useState(null)
  const [dataBundle,setDataBundle] = useState(null)
  const [selectedAirtime,setSelectedAirtime] = useState(null);
  const handleCloseAirtime = () => setShowAirtime(false);
  const handleOpenAirtime = () => setShowAirtime(true);
  const [showLockedFunds, setShowLockedFunds] = useState(false);
  const handleCloseLockedFunds = () => setShowLockedFunds(false);
  const handleShowLockedFunds = () => setShowLockedFunds(true);
  const [showTarget, setShowTarget] = useState(false);
  const handleCloseTarget = () => setShowTarget(false);
   const handleShowTarget = () => setShowTarget(true);
   const [showTransfer, setShowTransfer] = useState(false);
   const handleCloseTransfer = () => setShowTransfer(false);
   const handleShowTransfer = () => setShowTransfer(true);
   const[buydataData, setBuydataData] = useState(null)
   const[airtimeData, setAirtimeData] = useState(null)
   const[cableData, setCableData] = useState(null)
   const[electricData, setElectricData] = useState(null)
   const[bettingData, setBettingData] = useState(null)
   const [pin,setPin] = useState("")
   const[airtimeLoading,setAirtimeLoading] = useState(false)
   const[dataLoading,setDataLoading] = useState(false)
   const[cableLoading,setCableLoading] = useState(false)
   const[electricLoading,setElectricLoading] = useState(false)
   const[bettingLoading,setBettingLoading] = useState(false)
  const [key, setKey] = useState("home");
  const [airtimeModal, setAiritmeModal] = useState(false);
  const toggleAirtimeModal = () => setAiritmeModal(!airtimeModal);
  const closeAirtimeModal = () => {
    handleCloseAirtime();
    toggleAirtimeModal();
    navigate(0);
  };
  const [dataModal, setDataModal] = useState(false);
  const toggleDataModal = () => setDataModal(!dataModal);
  const closeDataModal = () => {
    handleCloseAirtime();
    toggleDataModal();
    navigate(0);
  };
  const [ cableModal, setCableModal] = useState(false);
  const toggleCableModal = () => setCableModal(!cableModal);
  const closeCableModal = () => {
    handleCloseBills();
    toggleCableModal();
    navigate(0);
  };
  const [ electricModal, setElectricModal] = useState(false);
  const toggleElectricModal = () => setElectricModal(!electricModal);
  const closeElectricModal = () => {
    handleCloseBills();
    toggleElectricModal();
    navigate(0);
  };
  const [ bettingModal, setBettingModal] = useState(false);
  const toggleBettingModal = () => setBettingModal(!bettingModal);
  const closeBettingModal = () => {
    handleCloseBills();
    toggleBettingModal();
    navigate(0);
  };
  const token = useSelector((state) => state?.user?.currentUser?.token?.access_token);
  const penTag = useSelector((state) => state?.user?.currentUser?.data?.username)

  

  const getAirtimeProvider = async () => {
    const response = await userRequest.get("api/v2/customer/bills/categories/A001/products",{
      headers: {"Authorization" : `Bearer ${token}`}
    })
    setAirtimeProvider(response.data.data)
}

const getDataProvider = async () => {
  const response = await userRequest.get("api/v2/customer/bills/categories/B001/products",{
    headers: {"Authorization" : `Bearer ${token}`}
  })
  setDataProvider(response.data.data)
}

const getCableProvider = async () => {
  const response = await userRequest.get("api/v2/customer/bills/categories/E001/products",{
    headers: {"Authorization" : `Bearer ${token}`}
  })
  setCableProvider(response.data.data)
}

const getDiscoProvider = async () => {
  const response = await userRequest.get("api/v2/customer/bills/categories/C001/products",{
    headers: {"Authorization" : `Bearer ${token}`}
  })
  setDiscoProvider(response.data.data)
}

const getBetProvider = async () => {
  const response = await userRequest.get("api/v2/customer/bills/categories/F001/products",{
    headers: {"Authorization" : `Bearer ${token}`}
  })
  setBetProvider(response.data.data)
}

useEffect(() => {
  getAirtimeProvider();
},[]);

useEffect(() => {
  getDataProvider();
},[]);

useEffect(() => {
  getCableProvider();
},[]);

useEffect(() => {
  getDiscoProvider();
},[]);

useEffect(() => {
  getBetProvider();
},[]);


// const getDataProvider = async () => {
//   const response = await userRequest.get("api/v2/customer/bills/categories/B001/providers",{
//     headers: {"Authorization" : `Bearer ${token}`}
//   })
//   setDataProvider(response.data.data)
// }

// const getItemCode = async () => {
//   const response = await userRequest.get(`/api/v2/customer/bills/category/product/${selectedAirtime}/items`,{
//     headers: {"Authorization" : `Bearer ${token}`}
//   })
//   setAirtimeItemCode(response?.data?.data[0]?.item_code)
// }

const getProductItems = async () => {
  const response = await userRequest.get(`/api/v2/customer/bills/category/product/${selectedDataP}/items`,{
        headers: {"Authorization" : `Bearer ${token}`}
      })
      setDataBundle(response.data.data);
}

useEffect(() => {
  getProductItems();
},[selectedDataP]);

const getCableItems = async () => {
  const response = await userRequest.get(`/api/v2/customer/bills/category/product/${selectedCableP}/items`,{
        headers: {"Authorization" : `Bearer ${token}`}
      })
      setCablePackage(response.data.data);
}

useEffect(() => {
  getCableItems();
},[selectedCableP]);

const getDiscoItems = async () => {
  const response = await userRequest.get(`/api/v2/customer/bills/category/product/${selectedDiscoP}/items`,{
        headers: {"Authorization" : `Bearer ${token}`}
      })
      setDiscoPackage(response.data.data);
}

useEffect(() => {
  getDiscoItems();
},[selectedDiscoP]);

const getBetItems = async () => {
  const response = await userRequest.get(`/api/v2/customer/bills/category/product/${selectedBetP}/items`,{
        headers: {"Authorization" : `Bearer ${token}`}
      })
      setBetPackage(response.data.data);
}

useEffect(() => {
  getBetItems();
},[selectedBetP]);




const buyAirtime = async () => {
  try {
    setAirtimeLoading(true)
    const response = await userRequest.post(
      "api/v2/customer/bills/pay",
      {
        amount: Number(airtimeAmount),
        currency: "NGN",
        transaction_pin: pin,
        source_type: 'individual',
        fund_source:penTag,
        biller_code: selectedAirtime,
        narration: "airtime",
        item_code: "AT099",
        customer_id: airtimeNumber ,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          source: "web",
          "x-api-key": "94684537",
        },
      }
    );
    setAirtimeData(response.data)
    setAirtimeLoading(false)
  } catch (error) {
    setAirtimeData(error)
    setAirtimeLoading(false)
  }
  
};

const buyData = async () => {
  try {
    setDataLoading(true)
    const selectedData = dataBundle.find(x => x.id === Number(selectedDataBundle));
  const response = await userRequest.post(
    "api/v2/customer/bills/pay",
    {
      amount: Number(selectedData.amount),
      currency: "NGN",
      transaction_pin: pin,
      source_type: 'individual',
      fund_source: penTag,
      biller_code: selectedDataP,
      narration: "Buying Data",
      item_code: selectedData.item_code,
      customer_id: dataNumber ,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        source: "web",
        "x-api-key": "94684537",
      },
    }
  );
  setBuydataData(response.data)
  setDataLoading(false)
  } catch (error) {
    setBuydataData(error)
  setDataLoading(false)
  }
  
};



const buyCable = async () => {
  setCableLoading(true)
  try {
    const selectedCableBundle = cablePackage.find(x => x.id === Number(cableBundle));

  const response = await userRequest.post(
    "api/v2/customer/bills/pay",
    {
      amount: Number(selectedCableBundle.amount),
      currency: "NGN",
      transaction_pin: pin,
      source_type: 'individual',
      fund_source:penTag,
      biller_code: selectedCableP,
      narration: "Buying Cable",
      item_code: selectedCableBundle.item_code,
      customer_id: smartCard ,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        source: "web",
        "x-api-key": "94684537",
      },
    }
  );
  setCableData(response.data)
  setCableLoading(false)
  } catch (error) {
    setCableData(error)
    setCableLoading(false)
  }
  
};

const buyDisco = async () => {
  try {
    setElectricLoading(true)
     // const selectedCableBundle = cablePackage.find(x => x.id === Number(cableBundle));
  // console.log("selected",selectedCableBundle,cableBundle)
  const response = await userRequest.post(
    "api/v2/customer/bills/pay",
    {
      amount: Number(discoAmount),
      currency: "NGN",
      transaction_pin: pin,
      source_type: "individal",
      fund_source: penTag,
      biller_code: selectedDiscoP,
      narration: "Buying Disco",
      item_code: discoItemCode,
      customer_id: discoMeter ,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        source: "web",
        "x-api-key": "94684537",
      },
    }
  );
    setElectricData(response.data)
    setElectricLoading(false)
  } catch (error) {
    setElectricData(error)
    setElectricLoading(false)
  }
 
};

const BetTopUp = async () => {
  try {
    setBettingLoading(true)
    // const selectedCableBundle = cablePackage.find(x => x.id === Number(cableBundle));
  // console.log("selected",selectedCableBundle,cableBundle)
  const response = await userRequest.post(
    "api/v2/customer/bills/pay",
    {
      amount: Number(betAmount),
      currency: "NGN",
      transaction_pin: pin,
      source_type: "individual",
      fund_source: penTag,
      biller_code: selectedBetP,
      narration: "Buying Disco",
      item_code: betItemCode,
      customer_id: betId ,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        source: "web",
        "x-api-key": "94684537",
      },
    }
  );
  setBettingData(response.data)
  setBettingLoading(false)
  } catch (error) {
    setBettingData(error)
    setBettingLoading(false)
  }
  
};


  const modalHandler = () => {
    setOpenQuickModal(!openQuickModal)
  }
  
  return (
    <div className="w-[674px] h-[181px] bg-white rounded-[14px] flex flex-col justify-center  pl-[48px] relative">
      <h1 className="text-[20px] mb-[19px]">Quick Transactions</h1>
      <div className="rounded-[32px] border-none outline-none bg-[#F5F6FA] w-[604px] h-[52px] flex justify-between items-center px-[24px]" onClick={modalHandler}>
          <h1>What do you want to do?</h1>
          <FaChevronDown onClick={modalHandler}/>
      </div>
      {openQuickModal ?  <div className="w-[596px] h-[204px] bg-[#F5F6FA] z-20 absolute top-[145px] rounded-[10px] flex flex-col justify-center pl-[15px]">
          <div className="flex gap-[30px] mb-[8px] items-center cursor-pointer" onClick={() => {handleShowTransfer();modalHandler()}}>
            <img src="/assets/transfer.png"  className="w-[14px] h-[16px]" alt="" />
            <p>Transfer</p>
          </div>
          <div className="flex gap-[30px] mb-[8px] items-center cursor-pointer" onClick={() => {handleOpenAirtime();modalHandler()}}>
            <img src="/assets/bills.svg" alt="" />
            <p className="ml-[5px]">Buy Airtime/Data</p>
          </div>
          <div className="flex gap-[30px] mb-[8px] items-center cursor-pointer" onClick={() => {handleShowLockedFunds();modalHandler()}}>
            <FaLock/>
            <p>Lock Funds</p>
          </div>
          <div className="flex gap-[30px] mb-[8px] items-center cursor-pointer" onClick={() => {handleOpenBills();modalHandler()}}>
            <FaMoneyBill/>
            <p>Pay Bills</p>
          </div>
          <div className="flex gap-[30px] mb-[8px] items-center cursor-pointer" onClick={() => {handleShowTarget();modalHandler()}}>
            <BsFillPiggyBankFill/>
            <p>Target Savings</p>
          </div>
      </div>: "" }
       
       <LockedFundsCanvas showLockedFunds={showLockedFunds} handleCloseLockedFunds={handleCloseLockedFunds} handleShowLockedFunds={handleShowLockedFunds}/>

       <TargetSavingsCanvas showTarget={showTarget} handleCloseTarget={handleCloseTarget} handleShowTarget={handleShowTarget}/>

       <TransferCanvas showTransfer={showTransfer} handleCloseTransfer={handleCloseTransfer} handleShowTransfer={handleShowTransfer} wallet={wallet}/>
      <Offcanvas
          show={showBills}
          onHide={handleCloseBills}
          placement="end"
          className="py-[20px] pl-[30px] flex flex-col w-[505px] quick"
        >
          <Offcanvas.Header closeButton className="w-[100%]">
            <Offcanvas.Title className="mt-[60px]">Pay Bills</Offcanvas.Title>
            <Close onClick={handleCloseBills} className="mr-[-200px]" />
          </Offcanvas.Header>
          <Offcanvas.Body>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-[40px]"
          >
            <Tab eventKey="home" title="Cable">
            <Input
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                type="select"
                onChange={(e) => setSelectedCableP(e.target.value)}
              >
                <option value="">Cable Provider</option>
                {cableProvider?.map((provider) => (
                  <option value={provider.biller_code}>{provider.biller_name}</option>
                ))}
              </Input>
              <Input
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                type="select"
                onChange={(e) => setCableBundle(e.target.value)}
              >
                <option value="">Package/Bouque</option>
                {cablePackage?.map((provider) => (
                  <option value={provider.id}>{provider.item_name} N{provider.amount}</option>
                ))}
              </Input>
              <input
                type="text"
                placeholder="Smart Card Number"
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                onChange={(e) => setSmartCard(e.target.value)}
              />
              <input
                type="text"
                placeholder="Phone Number"
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
              />
              <input
                type="text"
                placeholder="pin"
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
              />
              <p>Wallet Balance: <NumericFormat
            value={wallet.available_balance}
            thousandSeparator=','
            prefix='₦'
            disabled
            className='text-black bg-transparent'
          /></p>
              <button className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[5rem]" onClick={() => {buyCable();toggleCableModal()}}>
              {cableLoading ? (
                    <Spinner color="light">Loading...</Spinner>
                  ) : (
                    "Purchase"
                  )}
              </button>
              <Modal
              isOpen={cableModal}
              toggle={toggleCableModal}
              className="w-[400px] h-[500px] top-[20%] rounded-[14px]"
            >
              <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                {cableData?.status === "success" ? (
                  <img src="/assets/checkmark.png" alt="" />
                ) : (
                  <img src="/assets/Cancel.png" alt="" />
                )}
                <p className="mt-[20px] text-center">
                  {cableData?.response?.data?.message}
                </p>
                <button
                  onClick={() => {
                    toggleCableModal();
                    closeCableModal();
                  }}
                  className="w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px] text-white"
                >
                  Ok
                </button>
              </ModalBody>
            </Modal>
            </Tab>
            <Tab eventKey="profile" title="Electricity" className="">
            <Input
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                type="select"
                onChange={(e) => setSelectedDiscoP(e.target.value)}
              >
                <option value="">Electricity Provider</option>
                {discoProvider?.map((provider) => (
                  <option value={provider.biller_code}>{provider.biller_name}</option>
                ))}
              </Input>
              <Input
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                type="select"
                onChange={(e) => setDiscoItemCode(e.target.value)}
              >
                <option value="">Provider Type</option>
                {discoPackage?.map((provider) => (
                  <option value={provider.item_code}>{provider.item_name} N{provider.amount}</option>
                ))}
              </Input>
              <input
                type="text"
                placeholder="Meter Number"
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                onChange={(e) => setDiscoMeter(e.target.value)}
              />
              <input
                type="text"
                placeholder="Amount"
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                onChange={(e) => setDiscoAMount(e.target.value)}
              />
              <input
                type="text"
                placeholder="Telephone"
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
              />
              <input
                type="text"
                placeholder="Pin"
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
              />
              <p>Wallet Balance: <NumericFormat
            value={wallet.available_balance}
            thousandSeparator=','
            prefix='₦'
            disabled
            className='text-black bg-transparent'
          /></p>
              <button className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[5rem]" onClick={() => {buyDisco();toggleElectricModal()}}>
              {electricLoading ? (
                    <Spinner color="light">Loading...</Spinner>
                  ) : (
                    "Purchase"
                  )}
              </button>
              <Modal
              isOpen={electricModal}
              toggle={toggleElectricModal}
              className="w-[400px] h-[500px] top-[20%] rounded-[14px]"
            >
              <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                {electricData?.status === "success" ? (
                  <img src="/assets/checkmark.png" alt="" />
                ) : (
                  <img src="/assets/Cancel.png" alt="" />
                )}
                <p className="mt-[20px] text-center">
                  {electricData?.response?.data?.message}
                </p>
                <button
                  onClick={() => {
                    toggleElectricModal();
                    closeElectricModal();
                  }}
                  className="w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px] text-white"
                >
                  Ok
                </button>
              </ModalBody>
            </Modal>
            </Tab>
            <Tab eventKey="contact" title="Betting">
            <Input
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                type="select"
                onChange={(e) => setSelectedBetP(e.target.value)}
              >
                <option value="">Load Your Betting Wallet</option>
                {betProvider?.map((provider) => (
                  <option value={provider.biller_code}>{provider.biller_code}</option>
                ))}
              </Input>
              <Input
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                type="select"
                onChange={(e) => setBetItemCode(e.target.value)}
              >
                <option value="">Account Type</option>
                {betPackage?.map((provider) => (
                  <option value={provider.item_code}>{provider.item_name}</option>
                ))}
              </Input>
              <input
                type="text"
                placeholder="Customer ID"
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                onChange={(e) => setBetId(e.target.value)}
              />
              <input
                type="text"
                placeholder="Amount"
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                onChange={(e) => setBetAmount(e.target.value)}
              />
              <input
                type="text"
                placeholder="Pin"
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                onChange={(e) => setPin(e.target.value)}
              />
              <p>Wallet Balance: <NumericFormat
            value={wallet.available_balance}
            thousandSeparator=','
            prefix='₦'
            disabled
            className='text-black bg-transparent'
          /></p>
              <button className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[5rem]" onClick={() => {BetTopUp();toggleBettingModal()}}>
              {bettingLoading ? (
                    <Spinner color="light">Loading...</Spinner>
                  ) : (
                    "Proceed"
                  )}
              </button>
              <Modal
              isOpen={bettingModal}
              toggle={toggleBettingModal}
              className="w-[400px] h-[500px] top-[20%] rounded-[14px]"
            >
              <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                {bettingData?.status === "success" ? (
                  <img src="/assets/checkmark.png" alt="" />
                ) : (
                  <img src="/assets/Cancel.png" alt="" />
                )}
                <p className="mt-[20px] text-center">
                  {bettingData?.response?.data?.message}
                </p>
                <button
                  onClick={() => {
                    toggleBettingModal();
                    closeBettingModal();
                  }}
                  className="w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px] text-white"
                >
                  Ok
                </button>
              </ModalBody>
            </Modal>
            </Tab>
          </Tabs>
          </Offcanvas.Body>
        </Offcanvas>
        
        <Offcanvas
          show={showAirtime}
          onHide={handleCloseAirtime}
          placement="end"
          className="py-[20px] pl-[30px] flex flex-col w-[505px] quick"
        >
          <Offcanvas.Header closeButton className="w-[100%]">
            <Offcanvas.Title className="mt-[60px]">Buy Airitme/ Data</Offcanvas.Title>
            <Close onClick={handleCloseAirtime} className="mr-[-200px]" />
          </Offcanvas.Header>
          <Offcanvas.Body>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-[40px]"
          >
            <Tab eventKey="home" title="Buy Airtime">
              <Input
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                type="select"
                onChange={(e) => setSelectedAirtime(e.target.value)}
              >
                <option value="">Select Provider</option>
                {airtimeProvider?.map((provider) => (
                  <option value={provider.biller_code}>{provider.bill_provider}</option>
                ))}
              </Input>
              <input
                type="text"
                placeholder="Phone Number"
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                onChange={(e) => setAirtimeNumber(e.target.value)}
              />
              <input
                type="text"
                placeholder="Amount"
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                onChange={(e) => setAirtimeAmount(e.target.value)}
              />
              <input
                type="text"
                placeholder="Pin"
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                onChange={(e) => setPin(e.target.value)}
              />
              <p>Wallet Balance: <NumericFormat
            value={wallet.available_balance}
            thousandSeparator=','
            prefix='₦'
            disabled
            className='text-black bg-transparent'
          /></p>
              <button className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[5rem]" onClick={() => {buyAirtime();toggleAirtimeModal()}}>
              {airtimeLoading ? (
                    <Spinner color="light">Loading...</Spinner>
                  ) : (
                    "Purchase"
                  )}
              </button>
              <Modal
              isOpen={airtimeModal}
              toggle={toggleAirtimeModal}
              className="w-[400px] h-[500px] top-[20%] rounded-[14px]"
            >
              <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                {airtimeData?.status === "success" ? (
                  <img src="/assets/checkmark.png" alt="" />
                ) : (
                  <img src="/assets/Cancel.png" alt="" />
                )}
                <p className="mt-[20px] text-center">
                  {airtimeData?.response?.data?.message}
                </p>
                <button
                  onClick={() => {
                    toggleAirtimeModal();
                    closeAirtimeModal();
                  }}
                  className="w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px] text-white"
                >
                  Ok
                </button>
              </ModalBody>
            </Modal>
            </Tab>
            <Tab eventKey="profile" title="Data" className="">
            <Input
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                type="select"
                onChange={(e) => setSelectedDataP(e.target.value)}
              >
                 <option value="">Select Provider</option>
                {dataProvider?.map((provider) => (
                  <option value={provider.biller_code}>{provider.bill_provider}</option>
                ))}
              </Input>
              <Input
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                type="select"
                onChange={(e) => setSelectedDataBundle(e.target.value)}
                
              >
                 <option value="">Select Bundle</option>
                {dataBundle?.map((bundle) => (
                  <option value={bundle.id}>{bundle.item_name} <p className="ml-[50px]">₦{bundle.amount}</p></option>
                ))}
              </Input>
              <input
                type="text"
                placeholder="Telephone Number"
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                onChange={(e) => setDataNumber(e.target.value)}
              />
              <input
                type="password"
                placeholder="Pin"
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                onChange={(e) => setPin(e.target.value)}
              />
              <p>Wallet Balance: <NumericFormat
            value={wallet.available_balance}
            thousandSeparator=','
            prefix='₦'
            disabled
            className='text-black bg-transparent'
          /></p>
              <button className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[5rem]" onClick={() => {buyData();toggleDataModal()}}>
              {dataLoading ? (
                    <Spinner color="light">Loading...</Spinner>
                  ) : (
                    "Purchase"
                  )}
              </button>
              <Modal
              isOpen={dataModal}
              toggle={toggleDataModal}
              className="w-[400px] h-[500px] top-[20%] rounded-[14px]"
            >
              <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                {buydataData?.status === "success" ? (
                  <img src="/assets/checkmark.png" alt="" />
                ) : (
                  <img src="/assets/Cancel.png" alt="" />
                )}
                <p className="mt-[20px] text-center">
                  {buydataData?.response?.data?.message}
                </p>
                <button
                  onClick={() => {
                    toggleDataModal();
                    closeDataModal();
                  }}
                  className="w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px] text-white"
                >
                  Ok
                </button>
              </ModalBody>
            </Modal>
            </Tab>
          </Tabs>
          </Offcanvas.Body>
        </Offcanvas>
    </div>
  );
}

export default QuickTransactions;
