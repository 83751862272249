import React, { useEffect, useState } from "react";
import WidgetSm from "./WidgetSm";
import WidgetLg from "./WidgetLg";
import Transfer from "./DashboardComp/Transfer";
import { useSelector } from "react-redux";
import { signUp, userRequest } from "../../requestMethod";
import { useDispatch } from "react-redux";
import { LogOut } from "../../redux/userRedux";
import { useNavigate } from "react-router-dom";
import { FormGroup, Input, Modal, ModalBody, Spinner, Label } from "reactstrap";

function DashHome() {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pinloading, setPinLoading] = useState(false);
  const [pin, setPin] = useState("");
  const [pinData, setPinData] = useState();
  var date = new Date();
  var time = date.getHours();
  const [pinModal, setPinModal] = useState(false);
  const toggleSetPinModal = () => {
    setPinModal(!pinModal);
  };

  const [pinModal2, setPinModal2] = useState(false);
  const toggleSetPinModal2 = () => {
    setPinModal2(!pinModal2);
  };

  const user = useSelector((state) => state?.user?.currentUser?.data);
  const token = useSelector(
    (state) => state?.user?.currentUser?.token?.access_token
  );

  const getProfile = async () => {
    setLoading(true);
    try {
      const response = await userRequest.get("api/v2/customer/me", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProfile(response.data.data);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(LogOut());
        window.localStorage.clear();
        navigate("/");
      }
      setLoading(false);
    }
  };

  const SetUserPin = async () => {
    setPinLoading(true);
    try {
      const response = await userRequest.post(
        "api/v2/customer/set-transaction-pin",
        {
          pin: pin,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            source: "web",
            "x-api-key": "94684537",
          },
        }
      );
      setPinData(response.data.message);
      setPinLoading(false);
      toggleSetPinModal2()
      toggleSetPinModal()
    } catch (error) {
      setPinData(error.response.data.message);
      setPinLoading(false);
      toggleSetPinModal2()
      toggleSetPinModal()
    }
  };

  console.log("pinData", pinData);
  useEffect(() => {
    if(profile?.customer?.has_set_pin === false){
      toggleSetPinModal();
    }
  },[profile?.customer?.has_set_pin])

  useEffect(() => {
    getProfile();
  }, []);

  const AutoLogout = () => {
    getProfile();
    if (token === "") {
      dispatch(LogOut());
      window.localStorage.clear();
      navigate("/");
    }
  };

  useEffect(() => {
    AutoLogout();
  }, [token]);

  const wallet = profile?.personal_wallet || {};
  const customer = profile?.customer;
  const vfdAccNumber = profile?.personal_wallet?.account_number || {};
  const target_savings = profile?.total_target_balance || "0.00";
  const locked_funds = profile?.total_vault_balance || "0.00";

  return (
    <div className="bg-[#F0F0F0] w-[100%] h-[auto] px-[40px] pt-[10px]">
      <h1 className="text-[24px] font-bold pb-[15px]">
        Good{" "}
        {time > 5 && time < 12
          ? "Morning"
          : time > 12 && time < 18
          ? "afternoon"
          : "evening"}
        , {user?.first_name} {user?.last_name}
      </h1>
      <Transfer wallet={wallet} />
      <div className="flex gap-[20px] justify-center">
        <WidgetLg
          wallet={wallet}
          target_savings={target_savings}
          locked_funds={locked_funds}
          vfdAccNumber={vfdAccNumber}
          loading={loading}
        />
        <WidgetSm
          vfdAccNumber={vfdAccNumber}
          wallet={wallet}
          customer={customer}
          loading={loading}
        />
      </div>
      <Modal
        isOpen={pinModal}
        toggle={toggleSetPinModal}
        className="w-[500px] h-[500px] top-[20%] rounded-[14px] mt-[20px]"
      >
        <ModalBody className="flex flex-col items-center justify-center py-[20px] px-[30px] h-[250px]">
        <Label for="pin" className="text-black font-bold text-[24px] mb-[30px]">
              Set Transaction Pin
            </Label>
            <Input
              id="pin"
              name="pin"
              placeholder="Enter Pin"
              type="text"
              className="md:w-[400px] w-[320px] rounded-[20px] pl-[20px] bg-white"
              onChange={(e) => setPin(e.target.value)}
            />
          <div className="flex justify-center">
            <button
              className="text-white bg-[#4CC800] px-[60px] py-[10px] rounded-[136px] mt-[20px] w-[200px]"
              onClick={() => SetUserPin()}
            >
              {pinloading ? <Spinner color="light" size="sm" /> : "Set Pin"}
            </button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={pinModal2}
        toggle={toggleSetPinModal2}
        className="w-[400px] h-[300px] top-[20%] rounded-[14px] mt-[20px]"
      >
        <ModalBody className="flex flex-col items-center justify-center py-[20px] h-[250px]">
            <p className="text-black mb-[20px]">{pinData}</p>
            <button onClick={() => toggleSetPinModal2()} className="text-white bg-[#4CC800] px-[60px] py-[10px] rounded-[136px] mt-[20px] w-[200px]">Ok</button>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default DashHome;
