import React from 'react'

function VisaCard() {
  return (
    <div className='w-[377px] h-[233px] bg-[#1a1a1a] rounded-[14px] flex flex-col justify-center pl-[30px] mb-[7px] relative'>
      <img src="/assets/visa.png" alt="" className='w-[84px] mb-[36px]' />
      <div className='bg-white w-[177px] h-[49px]  rounded-[30px] self-center flex items-center justify-center mb-[36px]'>
      <p className=''>Coming Soon!</p>
      </div>
      <div className='flex gap-4'>
        <img src="/assets/dot.png" alt="" />
        <img src="/assets/dot.png" alt="" />
        <img src="/assets/dot.png" alt="" />
        <img src="/assets/dot.png" alt="" />
      </div>
      <img src="/assets/oval2.png" alt="" className='absolute right-0 opacity-20' />
      
    </div>
  )
}

export default VisaCard
