import React, { useEffect, useState } from 'react'
import Topbar from '../../components/Topbar/Topbar'
import Offcanvas from "react-bootstrap/Offcanvas";
import { Close } from "@mui/icons-material";
import { useSelector } from 'react-redux';
import { userRequest } from '../../requestMethod';

function Beneficiary({userType,setUserType}) {
  const[beneficiary,setBeneficiary] = useState(null)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // /api/v2/customer/send-money/bank-account-beneficiaries
  const token = useSelector(
    (state) => state?.user?.currentUser?.token?.access_token
  );

  const getBeneficiaries = async () => {
    const response = await userRequest.get("api/v2/customer/send-money/bank-account-beneficiaries",{
      headers: {"Authorization" : `Bearer ${token}`}
    })
    setBeneficiary(response.data.data)
}

useEffect(() => {
  getBeneficiaries();
},[]);


  return (
    <div style={{ flex: 4}}>
    <Topbar setUserType={setUserType} userType={userType}/>
    <div className='bg-[#F0F0F0] w-[100%] min-h-[100vh] px-[40px] pt-[30px]'>
      <h1 className='text-[24px] font-bold pb-[15px] mb-[26px]'>Beneficiaries</h1>
      <div className='flex justify-end relative right-0'>
      <div className="relative w-[100%] right-0 mb-[28px] flex justify-end">
        <div className="relative inset-y-0 left-[30px] flex items-center pl-3 pointer-events-none">
            <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
        </div>
        <input type="search" id="default-search" className="block w-[563px] h-[60px] rounded-[12px] pl-[40px] text-sm text-gray-900 border border-gray-300  bg-gray-50" placeholder="Search Beneficiaries" />
    </div>
      </div>

      <div className='w-[100%] h-[66px] bg-white rounded-tl-[14px] rounded-tr-[14px] flex items-center justify-end'>
          <select name="filter" id="portfolio" className='w-[176px] h-[43px] rounded-[10px] mr-[27px] pl-[10px]'>
              <option value="">Filter</option>
          </select>
      <button className="bg-white border-solid border-1 border-[#4CC800] text-center text-[#4CC800] w-[219px] h-[45px] rounded-[30px] text-[14px] mr-[30px]"  onClick={handleShow}>+ Add Beneficiary</button>
      <Offcanvas
          show={show}
          onHide={handleClose}
          placement="end"
          className="py-[20px] pl-[30px] flex flex-col w-[505px]"
        >
          <Offcanvas.Header closeButton className="w-[100%]">
            <Offcanvas.Title className="mt-[60px]">Add Beneficiary</Offcanvas.Title>
            <Close onClick={handleClose} className="mr-[-200px]" />
          </Offcanvas.Header>
          <Offcanvas.Body>
            <input
              type="text"
              placeholder="Name"
              className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px]"
            />
            <input
              type="text"
              placeholder="Phone Number"
              className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px]"
            />
            <input
              type="email"
              placeholder="Email Address"
              className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px]"
            />
            <input
              type="text"
              placeholder="Address"
              className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px]"
            />
            <button className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[5rem]">Add</button>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <div className='w-[100%]'>
<table className='w-[100%] px-[10px]'>
<thead>
  <tr className='flex items-center justify-center gap-[50px] h-[52px]'>
    <th className='text-[12px] w-[50px]'></th>
    <th className='text-[12px] w-[150px]'>BENEFICIARY NAME</th>
    <th className='text-[12px] w-[150px]'>BANK</th>
    <th className='text-[12px] w-[150px]'>ACCOUNT NUMBER</th>
    <th className='text-[12px] w-[250px]'>ADDRESS</th>
    <th className='text-[12px] w-[10px]'></th>
  </tr>
</thead>
<tbody>
  {beneficiary?.map((single) => (
    <tr className='flex items-center justify-center gap-[50px] h-[60px] bg-white px-[15px] border-b-[1px]' key={single.id}>
    <td className='w-[50px] h-[50px] text-[#4CC800] text-[14px] font-[500] flex items-center justify-center bg-[#4CC800]/[0.1] rounded-[50%]'>AR</td>
    <td className='text-[12px] w-[150px]'>{single.beneficiary_nick_name}</td>
    <td className='text-[12px] w-[150px]'>{single.bank_name}</td>
    <td className='text-[12px] w-[150px]'>{single.account_detail}</td>
    <td className='text-[12px] w-[250px]'>9 Oriwu Street, Lekki Phase 1, Lagos</td>
    <td className='text-[11px] w-[10px]'><Close/></td>
  </tr>
  ))}
</tbody>
</table>
      </div>
    </div>
  </div>
  )
}

export default Beneficiary
