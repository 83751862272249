import {createSlice} from '@reduxjs/toolkit'

const userSlice = createSlice({
    name: "user",
    initialState: {
        currentUser: {},
        isFetching: false,
        error:false,
        success: false,
        otpSuccess: false,
        createOtpFetching: false,
        otpError: false,
        createOtpSuccess: false,
        createOtpError: false
        // token:""
    },
    reducers: {
        loginStart:(state)=>{
            state.isFetching = true;
            state.success = false;
        },
        loginSuccess:(state,action)=>{
            state.isFetching = false;
            state.currentUser = action.payload;
            state.success = true;
            state.error = false;
        },
        LoginFailure:(state,action)=>{
            state.isFetching = false;
            state.error = true;
            state.success = false;
        },
        LogOut:(state) => {
            state.currentUser = {};
            state.error = false;
            state.success = false;
            state.otpSuccess = false;
            state.otpError = false;
        },
        otpStart:(state)=>{
            state.otpFetching = true;
            state.otpSuccess = false;
        },
        otpSuccess:(state,action)=>{
            state.otpFetching = false;
            state.currentUser = action.payload;
            state.otpSuccess = true;
            state.otpError = false;
            // state.token = action.payload.user.currentUser.token.access_token;
        },
        otpFailure:(state)=>{
            state.otpFetching = false;
            state.otpError = true;
            state.otpSuccess = false;
        },
        createOtpStart:(state)=>{
            state.createOtpFetching = true;
            state.createOtpSuccess = false;
        },
        createOtpSuccess:(state,action)=>{
            state.createOtpFetching = false;
            state.currentUser = action.payload;
            state.createOtpSuccess = true;
            state.createOtpError = false;
        },
        createOtpFailure:(state)=>{
            state.createOtpFetching = false;
            state.createOtpError = true;
            state.createOtpSuccess = false;
        },
        
    }
})

export const {loginStart, loginSuccess, LoginFailure, otpStart,otpSuccess,otpFailure, LogOut,createOtpStart,createOtpSuccess,createOtpFailure} = userSlice.actions
export default userSlice.reducer