import React, {useState} from "react";
import "./Topbar.css";
import { useSelector } from "react-redux";
import CompleteKYC from "./CompleteKYC";

function Topbar({setUserType, userType}) {
  const [showKYC, setShowKYC] = useState(false)
  const handleShowKyc = () => {
      setShowKYC(!showKYC)
  }
  const status = useSelector(
    (state) => state?.user?.currentUser?.data?.status
  );

  const showVerifyCanvas = () => {
    handleShowKyc();
  }


  return (
    <div className="bg-[#F0F0F0] flex align-center justify-between w-[100%] h-[100px] relative">
      <img
        src="/assets/topdesign.png"
        alt=""
        className="relative left-[-20px]"
      />
      <div className="mr-[50px] mt-[90px] flex items-center justify-center pb-[20px]">
        <div
          onClick={() => setUserType("individual")}
          className={userType === 'individual' ? "text-white text-[16px] px-[23px] py-[15px] bg-[#4CC800] shadow-[0px 14px 54px rgba(0, 0, 0, 0.06)] rounded-[30px]": "text-[#A7A7A7] text-[16px] ml-[20px] cursor-pointer"}
        >
          Individual
        </div>
        <div 
        onClick={() => status === "Verified" ? setUserType("merchant") : showVerifyCanvas()}
        className={userType === 'merchant' ? "text-white text-[16px] px-[23px] py-[15px] bg-[#4CC800] shadow-[0px 14px 54px rgba(0, 0, 0, 0.06)] rounded-[30px] ml-[10px]": "text-[#A7A7A7] text-[16px] ml-[20px] cursor-pointer"}>
          Merchant
        </div>
      </div>
      <CompleteKYC showKYC={showKYC} handleShowKyc={handleShowKyc}/>
    </div>
  );
}

export default Topbar;
