import React,{useState} from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Input } from 'reactstrap';
import Topbar from '../../components/Topbar/Topbar'
import { LogOut } from '../../redux/userRedux';
import Profile from './Profile';

function Settings({userType,setUserType}) {
    const [showProfile, setShowProfile] = useState(false);
    const handleShowProfile = () => setShowProfile(true);
    const handleCloseProfile = () => setShowProfile(false);
    const[balanceState, setBalanceState] = useState(true);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const logoutHandler = () => {
        dispatch(LogOut())
        window.localStorage.clear();
        navigate('/')
    }
    
  return (
    <div style={{ flex: 4}}>
      <Topbar setUserType={setUserType} userType={userType}/>
      <div className='bg-[#F0F0F0] w-[100%] min-h-[100vh] px-[40px] pt-[30px]'>
      <h1 className='text-[24px] font-bold pb-[15px] mb-[26px]'>Settings</h1>
      <div className='w-[90%] bg-white h-[128px] rounded-[14px] flex flex-col justify-center pl-[39px] mb-[18px]'>
        <div className='flex items-center  gap-[5px] mb-[18px]'>
            <span className='w-[16px] h-[16px] rounded-[50%] bg-[#4CC800]'></span>
            <p className='text-[14px] font-bold'>Did you Know?</p>
        </div>
        <p className='text-[14px] font-normal'>Penn Users get free transfers when they send money from their penn wallet to another penn user?</p>
      </div>
      <div className='w-[90%] bg-white h-[220px] rounded-[14px] flex flex-col justify-center pl-[39px]'>
            <p className='text-[14px] font-normal mb-[16px] cursor-pointer' onClick={handleShowProfile}>My Profile & Security</p>
            <p className='text-[14px] font-normal mb-[16px] cursor-pointer'>Card Details</p>
            <Link to='/Terms&Conditions'>
            <p className='text-[14px] font-normal mb-[16px] cursor-pointer'>Terms & Conditions</p>
            </Link>
            <p className='text-[14px] font-normal mb-[16px] cursor-pointer' onClick={logoutHandler}>Log Out</p>
            </div>
      </div>
      <Profile showProfile={showProfile} handleShowProfile={handleShowProfile} handleCloseProfile={handleCloseProfile}/>
    </div>
  )
}

export default Settings
