import React, { useState, useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Form, FormGroup, Input, Label ,Spinner} from "reactstrap";
import { LogOut } from '../../redux/userRedux'
import { useDispatch } from 'react-redux'
import {  useNavigate } from "react-router-dom";
import { userRequest } from "../../requestMethod";

function ForgotPassword() {
  const dispatch = useDispatch();
    const [identifier, setIdentifier] = useState("")
    const[otp,setOtp] = useState("")
    const[loading,setLoading] = useState(false);
    const[forgotPasswordRes, setForgotPasswordRes] = useState(null)
    const navigate = useNavigate();
  const backHandler = (e) =>{
    e.preventDefault();
    dispatch(LogOut())
    window.localStorage.clear();
    navigate('/')
  }

  const forgotPassword = async () => {
    try {
      const response = await userRequest.post("api/v2/customer/forgot-password",
      {
        identifier: identifier
      });
      setLoading(false)
      setForgotPasswordRes(response.data)
    } catch (error) {
      setForgotPasswordRes(error)
      setLoading(false)
     
    }
   
    
    }

    useEffect(() => {
      if (forgotPasswordRes?.status === 'success') {
        navigate("/forgot-password-complete", { replace: true, state: { email: identifier } });
      }
    }, [forgotPasswordRes?.status, navigate, identifier]);

  return (
    <div className='flex justify-center items-center bg-white relative h-[100vh] w-[100%]'>
        <img src="/assets/login-design.png" alt="" className='absolute top-0 left-0 md:w-[auto] w-[300px]' />
        <div className='absolute top-[150px] left-[50px] flex gap-[15px] items-center justify-center cursor-pointer' onClick={backHandler}>
          <FaArrowLeft />
          <p className='text-[16px]'>Go Back</p>
        </div>
        <div className="mt-[0px]text-white flex flex-col items-center justify-center">
            <img src="/assets/blacktext.png" alt="" className='md:w-[200px] w-[150px]'/>
            <p className='text-black text-[16px] text-center mt-[20px]'>Kindly check your mail for OTP</p>
            <div className='md:w-[450px] w-[350px] bg-slate-100 py-[40px] rounded-[30px] flex flex-col items-center justify-center mt-[20px]'>
            <FormGroup className='mt-[10px]'>
                    <Label for="email" className='text-black'>
                    Email or Username
                    </Label>
                    <Input
                    id="email"
                    name="email"
                    type="text"
                    className='md:w-[400px] w-[320px] rounded-[20px] pl-[20px] bg-white'
                    onChange={e => setIdentifier(e.target.value)}
                    />
                                   
                </FormGroup>
                <div className="flex justify-center">
                <button
              className="text-white bg-[#4CC800] px-[60px] py-[10px] rounded-[136px] mt-[20px] w-[200px]"
              onClick={() => forgotPassword()}
            >
              Send OTP
            </button>
                </div>
            </div>
        </div>
        <img src="/assets/login-design2.png" alt="" className='absolute bottom-0 right-0 w-[300px] md:w-[auto]' />
    </div>
  );
}

export default ForgotPassword;
