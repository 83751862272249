import React,{useEffect} from 'react'
import DashHome from '../../components/DashHome/DashHome'
import Topbar from '../../components/Topbar/Topbar'
import './dashboard.css'
import { useNavigate } from 'react-router-dom'
import { profileStart, profileFailure, profileSuccess } from '../../redux/profileRedux'
import { userRequest } from '../../requestMethod'
import { useSelector } from 'react-redux'

function Dashboard({setUserType, userType}) {
  const navigate = useNavigate();
  const token = useSelector(
    (state) => state?.user?.currentUser?.token?.access_token
  );

 

   const getProfile = async (dispatch, data) => {
    dispatch(profileStart());
    try {
      const res = await userRequest.post("api/v2/customer/me", data);
      dispatch(profileSuccess(res.data));
    } catch (error) {
      dispatch(profileFailure(error?.data))
      window.localStorage.setItem("error", error)
      throw error
    }
  };
  return (
    <div className='dashboard relative'>
        <Topbar setUserType = {setUserType} userType={userType}/>
        <DashHome/>
    </div>
  )
}

export default Dashboard