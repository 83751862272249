import axios from 'axios';

const BASE_URL = "https://api.mypennytree.com/"

export const publicRequest = axios.create({
    baseURL: BASE_URL,
    headers: { source: "web", "x-api-key": "94684537", version: 6}
})

export const userRequest = axios.create({
    baseURL: BASE_URL,
    headers: {source: "web", "x-api-key": "94684537", version: 6}
})

export const roleRequest = axios.create({
    baseURL: BASE_URL,
    headers: {source: "web", "x-api-key": "94684537", version: 6}
})

export const signUp = axios.create({
    baseURL: BASE_URL,
    headers: {source: "web", "x-api-key": "94684537", version: 8, device: "MzkzREJENDgtNzg3Ri00MTkzLUE3NTAtODM0QzZDOEEwMDhCfHxJcGhvbmV8fElPU3x8MTUuNy4zfHxmQnI5T0wwWHZVdkRwTVpyZXlvME44OkFQQTkxYkh3dGFQcWpfYTJlS0o3Tzl6NV9EU3BaYWdEeXVVWHN4aHc1dE5tSnJPNE5PM1lha2YwcDZWWkg5TXZfeUpfY2xyYnhiSlhwYVN5N0Z3bjJ4VTM0bFBCLU1Ec1U1a1B6YjhwVXNLM2NIMkl3NW1CMGdKSmJBeHhpM20zSjd2ZkVJeFhQaWtB"}
})

export const uploadRequest = axios.create({
    baseURL: BASE_URL,
    headers: {source: "web", "x-api-key": "94684537"}
})

