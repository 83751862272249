/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import './Transfer.css'
import TransferCanvas from "./TransferCanvas";

function Transfer({wallet}) {
  const [showTransfer, setShowTransfer] = useState(false);
  const handleCloseTransfer = () => setShowTransfer(false);
  const handleShowTransfer = () => setShowTransfer(true);
  


  return (
    <div className="w-[100%] flex items-center justify-end mb-[10px]">
      <button
        onClick={handleShowTransfer}
        className="group flex items-center justify-center gap-[5px] border-solid border-1 border-black px-[25px] py-[8px] rounded-[30px] hover:bg-[#4CC800] hover:border-[#4CC800]"
      >
        <FaPlus className="group-hover:fill-white" />
        <p className="group-hover:text-white">Transfer</p>
      </button>
      <TransferCanvas showTransfer={showTransfer} handleCloseTransfer={handleCloseTransfer} handleShowTransfer={handleShowTransfer} wallet={wallet}/>
    </div>
  );
}

export default Transfer;
