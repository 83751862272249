import { Close } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { Offcanvas, Tab, Tabs } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { FormGroup, Input, Label, Modal, ModalBody, Spinner } from "reactstrap";
import { userRequest } from "../../../requestMethod";
import { FaRegEye, FaRegEyeSlash, FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import "./Transfer.css";

function TransferCanvas({ showTransfer, handleCloseTransfer, wallet }) {
  const [key, setKey] = useState("home");
  const [loading, setLoading] = useState(false);
  const [showBankselector,setShowBankSelector] = useState(true);
  const [pennTag, setPenntag] = useState("");
  const [merchTag, setMerchTag] = useState("");
  const [PennAmount, setPennAmount] = useState(0);
  const [bankAmount, setBankAmount] = useState(0);
  const [merchAmount, setMerchAmount] = useState(0);
  const [accountNumber, setAccountNumber] = useState("");
  const [penAccountNumber, setPenAccountNumber] = useState("");
  const [merchAccountNumber, setMerchAccountNumber] = useState("");
  const [name, setName] = useState("");
  const [pennName, setPennName] = useState("");
  const [merchName, setMerchName] = useState("");
  const [bankTransferData, setBankTransferData] = useState(null);
  const [merchTransferData, setMerchTransferData] = useState(null);
  const [pennDesc, setPennDesc] = useState("");
  const [merchDesc, setMerchDesc] = useState("");
  const [bankDesc, setbankDesc] = useState("");
  const [saveBenPenn, setSaveBenPenn] = useState(false);
  const [saveBenMerch, setSaveBenMerch] = useState(false);
  const [saveBenBank, setSaveBenBank] = useState(false);
  const [merchLoading, setMerchLoading] = useState(false)
  const [bankCode, setBankCode] = useState(null);
  console.log("check",bankCode)
  const [bankName, setBankName] = useState("");
  const [bankrealName, setBankrealName] = useState("");
  const [AccountName, setAccountName] = useState("");
  const [pin, setPin] = useState("");
  const [pennData, setPenndata] = useState(null);
  const [bankBeneficiary, setBankBeneficiary] = useState(null);
  const [PenBeneficiary, setPenBeneficiary] = useState(null);
  const [MerchBeneficiary, setMerchBeneficiary] = useState(null);
  const [passwordType, setPasswordType] = useState("password");
  const [banks, setBanks] = useState(null);
  const navigate = useNavigate();
  const interest = Number(53.75);
  const [P2Bmodal, setP2BModal] = useState(false);
  const [P2Pmodal, setP2PModal] = useState(false);
  const [P2Mmodal, setP2MModal] = useState(false);
  const toggleP2B = () => setP2BModal(!P2Bmodal);
  const toggleP2P = () => setP2PModal(!P2Pmodal);
  const toggleP2M = () => setP2MModal(!P2Mmodal);
  const [showP2BTrans, setShowP2BTrans] = useState(true);
  const [showP2Bsummary, setShowP2Bsummary] = useState(false);
  const handleP2BsummaryShow = () => {
    setShowP2BTrans(false);
    setShowP2Bsummary(true);
  };

  const handleP2BsummaryClose = () => {
    setShowP2BTrans(true);
    setShowP2Bsummary(false);
  };

  const [showP2PTrans, setShowP2PTrans] = useState(true);
  const [showP2Psummary, setShowP2Psummary] = useState(false);
  const handleP2PsummaryShow = () => {
    setShowP2PTrans(false);
    setShowP2Psummary(true);
  };

  const handleP2PsummaryClose = () => {
    setShowP2PTrans(true);
    setShowP2Psummary(false);
  };

  const [showP2MTrans, setShowP2MTrans] = useState(true);
  const [showP2Msummary, setShowP2Msummary] = useState(false);
  const handleP2MsummaryShow = () => {
    setShowP2MTrans(false);
    setShowP2Msummary(true);
  };

  const handleP2MsummaryClose = () => {
    setShowP2MTrans(true);
    setShowP2Msummary(false);
  };

  const token = useSelector(
    (state) => state?.user?.currentUser?.token?.access_token
  );

  const user = useSelector((state) => state?.user?.currentUser?.data?.username);

  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const getBeneficiaries = async () => {
    const response = await userRequest.get(
      "api/v2/customer/send-money/bank-account-beneficiaries",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setBankBeneficiary(response.data.data);
  };

  const getPenBeneficiaries = async () => {
    const response = await userRequest.get(
      "api/v2/customer/send-money/pen-account-beneficiaries?beneficiary_type=individual",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setPenBeneficiary(response.data.data);
  };

  console.log("beneficiary",bankBeneficiary);

  const getMerchBeneficiaries = async () => {
    const response = await userRequest.get(
      "api/v2/customer/send-money/pen-account-beneficiaries?beneficiary_type=merchant",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setMerchBeneficiary(response.data.data);
  };
  useEffect(() => {
    // getBeneficiaries();
    // getMerchBeneficiaries();
  }, []);

  useEffect(() => {
    getPenBeneficiaries();
  }, []);

  useEffect(() => {
    getBeneficiaries();
  }, []);

  useEffect(() => {
    getMerchBeneficiaries();
  }, []);

  const getBanks = async () => {
    const response = await userRequest.get(
      "/api/v2/customer/send-money/banks",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setBanks(response.data.data);
  };

  useEffect(() => {
    getBanks();
  }, []);

  const doPenn2Penn = async () => {
    setLoading(true);
    try {
      const response = await userRequest.post(
        "api/v2/customer/send-money/to-pennytree",
        {
          amount: Number(PennAmount),
          currency: "NGN",
          sender_type: "individual",
          sender_tag: user,
          beneficiary_type: "individual",
          beneficiary_tag: pennTag,
          transaction_pin: pin,
          save_as_beneficiary: saveBenPenn,
          narration: pennDesc,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            source: "web",
            "x-api-key": "94684537",
          },
        }
      );
      setPenndata(response.data);
      setLoading(false);
    } catch (error) {
      setPenndata(error.response.data);
      setLoading(false);
    }
  };

  console.log("pennData", pennData)

  const doPenn2Bank = async () => {
    setLoading(true);
    try {
      const response = await userRequest.post(
        "api/v2/customer/send-money/to-bank",
        {
          amount: Number(bankAmount),
          currency: "NGN",
          bank_code: bankCode?.value,
          account_number: accountNumber,
          sender_type: "individual",
          sender_tag: user,
          transaction_pin: pin,
          save_as_beneficiary: saveBenBank,
          narration: bankDesc,
          provider_id: 0,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            source: "web",
            "x-api-key": "94684537",
          },
        }
      );
      setBankTransferData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setBankTransferData(error.response.data);
    }
  };

  const doPenn2Merch = async () => {
    setLoading(true);
    try {
      const response = await userRequest.post(
        "api/v2/customer/send-money/to-pennytree",
        {
          amount: Number(merchAmount),
          currency: "NGN",
          beneficiary_tag: merchTag,
          transaction_pin: pin,
          save_as_beneficiary: saveBenMerch,
          sender_type: "individual",
          sender_tag: user,
          beneficiary_type: "merchant",
          narration: merchDesc,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            source: "web",
            "x-api-key": "94684537",
          },
        }
      );
      setMerchTransferData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setMerchTransferData(error.response.data);
    }
  };

  const PennResolve = async () => {
    setPennName("")
    try {
      const response = await userRequest.post(
        "api/v2/customer/send-money/pen-account-resolve",
        {
          identifier: pennTag,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            source: "web",
            "x-api-key": "94684537",
          },
        }
      );
      setPennName(response.data.data.account_name);
    } catch (error) {
      
    }
    
  };

  useEffect(() => {
    PennResolve()
  }, [pennTag]);

  const BankResolve = async () => {
    const response = await userRequest.post(
      "api/v2/customer/send-money/bank-account-resolve",
      {
        bank_code: bankCode?.value || bankCode,
        account_number: accountNumber,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          source: "web",
          "x-api-key": "94684537",
        },
      }
    );
    setBankName(response.data.data.account_name);
  };

  console.log("bankcode", bankCode)

  useEffect(() => {
    BankResolve();
  }, [bankCode]);

  const MerchResolve = async () => {
    setMerchName("")
    setMerchLoading(true)
    try {
      const response = await userRequest.post(
        "api/v2/customer/send-money/pen-account-resolve",
        {
          identifier: merchTag,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            source: "web",
            "x-api-key": "94684537",
          },
        }
      );
      setMerchName(response.data.data.business_name);
      setMerchLoading(false)
    } catch (error) {
      setMerchName("")
      setMerchLoading(false)
    }
   
  };

  useEffect(() => {
    MerchResolve()
  }, [merchTag]);

  const closeP2BModal = () => {
    handleCloseTransfer();
    toggleP2B();
    handleP2BsummaryClose();
    navigate(0);
  };

  const closeP2PModal = () => {
    handleCloseTransfer();
    toggleP2P();
    handleP2PsummaryClose();
    navigate(0);
  };

  const closeP2MModal = () => {
    handleCloseTransfer();
    toggleP2M();
    handleP2MsummaryClose();
    navigate(0);
  };

  const options = banks?.map((item) => {
    return {
      label: item?.bank_name,
      value: item?.bank_code,
    };
  });

  console.log(bankBeneficiary)
  return (
    <Offcanvas
      show={showTransfer}
      onHide={handleCloseTransfer}
      placement="end"
      className="py-[20px] pl-[30px] flex flex-col w-[505px] transfer"
    >
      <Offcanvas.Header closeButton className="w-[100%]">
        <Offcanvas.Title className="mt-[60px]">Transfer </Offcanvas.Title>
        <Close onClick={handleCloseTransfer} className="mr-[-200px]" />
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-[40px]"
        >
          <Tab eventKey="home" title="Penn-2-Bank">
            {showP2BTrans && (
              <>
                <Label for='beneficiary'>
                  Select Beneficiary
                </Label>
                <Input
                id="beneficiary"
                name="beneficiary"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-transparent"
                  type="select"
                  onChange={(e) => {
                    const beneficiaryDetails = bankBeneficiary.find(
                      (x) => x.id === Number(e.target.value)
                    );
                    setAccountNumber(beneficiaryDetails?.beneficiary_account);
                    setBankCode(beneficiaryDetails?.bank_code);
                    setAccountName(beneficiaryDetails?.beneficiary_nick_name);
                    setBankrealName(beneficiaryDetails?.bank_name)
                    setShowBankSelector(false)
                  }}
                >
                  <option value="">Select Beneficary</option>
                  {bankBeneficiary?.map((single) => (
                    <option value={single?.id} className="text-black" onChange={() => setShowBankSelector(false)}>
                      {single?.beneficiary_nick_name}{" "}({ single?.bank_name})
                    </option>
                  ))}
                </Input>
                <input
                  type="text"
                  placeholder="Account Number"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                  onChange={(e) => {setAccountNumber(e.target.value); setShowBankSelector(true)}}
                  value={accountNumber}
                />
                {/* <Input
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                  type="select"
                  onChange={(e) => setBankCode(e.target.value)}
                  value={bankCode}
                >
                  <option value="">Select Bank</option>
                  {banks?.sort((a, b) => a.name?.localeCompare(b.name))?.map((bank) => (
                    <option value={bank.bank_code}>{bank.bank_name}</option>
                  ))}
                </Input> */}
                {showBankselector &&
                <Select
                  options={options}
                  placeholder="Select Bank"
                  onChange={setBankCode}
                  defaultValue={bankCode}
                  value={bankCode?.label}
                />
}
                <input
                  type="text"
                  placeholder="Name"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                  value={accountNumber?.length === 10 ? bankName : ""}
                  onChange={accountNumber?.length === 10 && BankResolve()}
                  readOnly
                />
                <input
                  type="text"
                  placeholder="Amount"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                  onChange={(e) => setBankAmount(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Description"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                  onChange={(e) => setbankDesc(e.target.value)}
                />
                <p>
                  Wallet Balance:{" "}
                  <NumericFormat
                    value={wallet.available_balance}
                    thousandSeparator=","
                    prefix="₦"
                    disabled
                    className="text-black bg-transparent"
                  />
                </p>
                <button
                  className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[5rem]"
                  onClick={() => handleP2BsummaryShow()}
                >
                  Proceed
                </button>
              </>
            )}
            {showP2Bsummary && (
              <>
                <FaArrowLeft onClick={() => handleP2BsummaryClose()} />
                <h1 className="text-[24px] font-bold mb-[30px]">Summary</h1>
                <div className="w-[419px] h-[369px] bg-[#F5F6FA] rounded-[14px] flex flex-col items-start justify-start py-[40px] px-[15px]">
                  <div className="flex gap-[10px] items-center">
                    <div className="w-[10px] h-[10px] rounded-[50%] bg-[#4CC800]"></div>
                    <p>Details</p>
                  </div>
                  <div className="w-[100%] bg-[#535456] h-[1px] mt-[20px] mb-[15px] self-center"></div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">Amount</p>
                    <div className="flex justify-end items-end w-[200px]">
                      <NumericFormat
                        value={bankAmount}
                        thousandSeparator=","
                        prefix="₦"
                        disabled
                        className="text-[#535456] text-[14px] text-end bg-transparent"
                      />
                    </div>
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">
                      Transaction Charge
                    </p>
                    <NumericFormat
                      value={53.4}
                      thousandSeparator=","
                      prefix="₦"
                      disabled
                      className="text-[#535456] text-[14px]  text-end bg-transparent"
                    />
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">Bank Name</p>
                    <h4 className="text-[#535456] text-[14px]">
                      {bankCode?.label || bankrealName}
                    </h4>
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">Bank Account</p>
                    <h4 className="text-[#535456] text-[14px]">
                      {accountNumber}
                    </h4>
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">
                      Beneficiary Name
                    </p>
                    <h4 className="text-[#535456] text-[14px]">{bankName}</h4>
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">Payment Method</p>
                    <h4 className="text-[#535456] text-[14px]">Wallet</h4>
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">Description</p>
                    <h4 className="text-[#535456] text-[14px]">{bankDesc}</h4>
                  </div>
                </div>
                <div className="flex items-center justify-start my-[20px]">
                  <input
                    type="checkbox"
                    id="benefit"
                    className="mr-[10px]"
                    onClick={() => setSaveBenPenn(!saveBenPenn)}
                  />
                  <label for="Benefit">Save as beneficiary</label>
                </div>
                <FormGroup className="relative">
                  <input
                    type={passwordType}
                    placeholder="Pin"
                    className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                    onChange={(e) => setPin(e.target.value)}
                  />
                  <button
                    onClick={togglePassword}
                    className="absolute right-[50px] top-[20px]"
                  >
                    {passwordType === "password" ? (
                      <FaRegEye className="text-[25px" />
                    ) : (
                      <FaRegEyeSlash className="text-[25px" />
                    )}
                  </button>
                </FormGroup>
                <button
                  className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[1rem]"
                  onClick={() => {
                    doPenn2Bank();
                    toggleP2B();
                  }}
                >
                  {loading ? (
                    <Spinner color="light">Loading...</Spinner>
                  ) : (
                    "Proceed"
                  )}
                </button>
              </>
            )}
            <Modal
              isOpen={P2Bmodal}
              toggle={toggleP2B}
              className="w-[400px] h-[500px] top-[20%] rounded-[14px]"
            >
              <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                {bankTransferData?.data?.status === "failed" ? (
                  <img src="/assets/Cancel.png" alt="" />
                ) : (
                  <img src="/assets/checkmark.png" alt="" />
                )}
                <p className="mt-[20px] text-center text-black text-[14px]">
                  {bankTransferData?.message}
                </p>
                <button
                  onClick={() => {
                    toggleP2B();
                    closeP2BModal();
                  }}
                  className="w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px] text-white"
                >
                  Ok
                </button>
              </ModalBody>
            </Modal>
          </Tab>
          <Tab eventKey="profile" title="Penn-2-Penn" className="">
            {showP2PTrans && (
              <>
              <Label for='beneficiary'>
                  Select Beneficiary
                </Label>
                <Input
                id="beneficiary"
                name="beneficiary"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-transparent"
                  type="select"
                  onChange={(e) => {
                    const beneficiaryDetails = PenBeneficiary.find(
                      (x) => x.id === Number(e.target.value)
                    );
                    setPenntag(beneficiaryDetails?.beneficiary_tag);
                    setPennName(beneficiaryDetails?.beneficiary_nick_name);
                  }}
                >
                  <option value="">Select Beneficary</option>
                  {PenBeneficiary?.map((single) => (
                    <option value={single.id} className="text-black">
                      {single.beneficiary_nick_name}
                    </option>
                  ))}
                </Input>
                <input
                  type="text"
                  placeholder="PennTag"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                  onChange={(e) => setPenntag(e.target.value)}
                  value={pennTag}
                />
                <input
                  type="text"
                  placeholder="Name"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                  value={pennName}
                />
                <input
                  type="text"
                  placeholder="Amount"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                  onChange={(e) => setPennAmount(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Description"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                  onChange={(e) => setPennDesc(e.target.value)}
                />
                <p>
                  Wallet Balance:{" "}
                  <NumericFormat
                    value={wallet.available_balance}
                    thousandSeparator=","
                    prefix="₦"
                    disabled
                    className="text-black bg-transparent"
                  />
                </p>
                <button
                  className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[5rem]"
                  onClick={() => handleP2PsummaryShow()}
                >
                  Proceed
                </button>
              </>
            )}
            {showP2Psummary && (
              <>
                <FaArrowLeft onClick={() => handleP2PsummaryClose()} />
                <h1 className="text-[24px] font-bold mb-[30px]">Summary</h1>
                <div className="w-[419px] h-[369px] bg-[#F5F6FA] rounded-[14px] flex flex-col items-start justify-start py-[40px] px-[15px]">
                  <div className="flex gap-[10px] items-center">
                    <div className="w-[10px] h-[10px] rounded-[50%] bg-[#4CC800]"></div>
                    <p>Details</p>
                  </div>
                  <div className="w-[100%] bg-[#535456] h-[1px] mt-[20px] mb-[15px] self-center"></div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">Amount</p>
                    <div className="flex justify-end items-end w-[200px]">
                      <NumericFormat
                        value={PennAmount}
                        thousandSeparator=","
                        prefix="₦"
                        disabled
                        className="text-[#535456] text-[14px] text-end bg-transparent"
                      />
                    </div>
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">
                      Transaction Charge
                    </p>
                    <NumericFormat
                      value={0.0}
                      thousandSeparator=","
                      prefix="₦"
                      disabled
                      className="text-[#535456] text-[14px]  text-end bg-transparent"
                    />
                  </div>

                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">PennTag</p>
                    <h4 className="text-[#535456] text-[14px]">{pennTag}</h4>
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">
                      Beneficiary Name
                    </p>
                    <h4 className="text-[#535456] text-[14px]">{pennName}</h4>
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">Payment Method</p>
                    <h4 className="text-[#535456] text-[14px]">Wallet</h4>
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">Description</p>
                    <h4 className="text-[#535456] text-[14px]">{pennDesc}</h4>
                  </div>
                </div>
                <div className="flex items-center justify-start my-[20px]">
                  <input
                    type="checkbox"
                    id="benefit"
                    className="mr-[10px]"
                    onClick={() => setSaveBenPenn(!saveBenPenn)}
                  />
                  <label for="Benefit">Save as beneficiary</label>
                </div>
                <FormGroup className="relative">
                  <input
                    type={passwordType}
                    placeholder="Pin"
                    className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                    onChange={(e) => setPin(e.target.value)}
                  />
                  <button
                    onClick={togglePassword}
                    className="absolute right-[50px] top-[20px]"
                  >
                    {passwordType === "password" ? (
                      <FaRegEye className="text-[25px" />
                    ) : (
                      <FaRegEyeSlash className="text-[25px" />
                    )}
                  </button>
                </FormGroup>
                <button
                  className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[1rem]"
                  onClick={() => {
                    doPenn2Penn();
                    toggleP2P();
                  }}
                >
                  {loading ? (
                    <Spinner color="light">Loading...</Spinner>
                  ) : (
                    "Proceed"
                  )}
                </button>
              </>
            )}
            <Modal
              isOpen={P2Pmodal}
              toggle={toggleP2P}
              className="w-[400px] h-[500px] top-[20%] rounded-[14px]"
            >
              <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                {pennData?.status === "failed" ? (
                  <img src="/assets/Cancel.png" alt="" />
                ) : (
                  <img src="/assets/checkmark.png" alt="" />
                )}
                <p className="mt-[20px] text-center">
                   {pennData?.message}
                </p>
                <button
                  onClick={() => {
                    toggleP2P();
                    closeP2PModal();
                  }}
                  className="w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px] text-white"
                >
                  Ok
                </button>
              </ModalBody>
            </Modal>
          </Tab>
          <Tab eventKey="contact" title="Penn-2-Merch">
            {showP2MTrans && (
              <>
              <Label for='ben'>Select Beneficiary</Label>
                <Input
                id='ben'
                name='ben'
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-transparent"
                  type="select"
                  onChange={(e) => {
                    const beneficiaryDetails = MerchBeneficiary.find(
                      (x) => x.id === Number(e.target.value)
                    );
                    setMerchTag(beneficiaryDetails?.beneficiary_tag);
                    setMerchName(beneficiaryDetails?.beneficiary_nick_name);
                  }}
                >
                  <option value="">Select Beneficary</option>
                  {MerchBeneficiary?.map((single) => (
                    <option value={single.id} className="text-black">
                      {single.beneficiary_nick_name}
                    </option>
                  ))}
                </Input>
                <input
                  type="text"
                  placeholder="MerchTag"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[10px] text-[14px] text-black"
                  onChange={(e) => setMerchTag(e.target.value)}
                  value={merchTag}
                />
                {/* <div className="flex items-center justify-end mb-[5px]">
                <p>{merchTag?.length === 9 ? merchName : ""}</p>
                </div> */}
                <input
                  type="text"
                  placeholder="Name"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                  value={merchName}
                  readOnly
                />
                <input
                  type="text"
                  placeholder="Amount"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                  onChange={(e) => setMerchAmount(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Description"
                  className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px] text-black"
                  onChange={(e) => setMerchDesc(e.target.value)}
                />
                <div className="flex items-center justify-center mb-[20px]">
                  <input
                    type="checkbox"
                    id="benefit"
                    className="mr-[10px]"
                    onClick={() => setSaveBenMerch(!saveBenMerch)}
                  />
                  <label htmlFor="Benefit">Save as beneficiary</label>
                </div>

                <p>
                  Wallet Balance:{" "}
                  <NumericFormat
                    value={wallet.available_balance}
                    thousandSeparator=","
                    prefix="₦"
                    disabled
                    className="text-black bg-transparent"
                  />
                </p>
                <button
                  className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[5rem]"
                  onClick={() => handleP2MsummaryShow()}
                >
                  Proceed
                </button>
              </>
            )}
            {showP2Msummary && (
              <>
                <FaArrowLeft onClick={() => handleP2MsummaryClose()} />
                <h1 className="text-[24px] font-bold mb-[30px]">Summary</h1>
                <div className="w-[419px] h-[369px] bg-[#F5F6FA] rounded-[14px] flex flex-col items-start justify-start py-[40px] px-[15px]">
                  <div className="flex gap-[10px] items-center">
                    <div className="w-[10px] h-[10px] rounded-[50%] bg-[#4CC800]"></div>
                    <p>Details</p>
                  </div>
                  <div className="w-[100%] bg-[#535456] h-[1px] mt-[20px] mb-[15px] self-center"></div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">Amount</p>
                    <div className="flex justify-end items-end w-[200px]">
                      <NumericFormat
                        value={merchAmount}
                        thousandSeparator=","
                        prefix="₦"
                        disabled
                        className="text-[#535456] text-[14px] text-end bg-transparent"
                      />
                    </div>
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">
                      Transaction Charge
                    </p>
                    <NumericFormat
                      value={0.0}
                      thousandSeparator=","
                      prefix="₦"
                      disabled
                      className="text-[#535456] text-[14px]  text-end bg-transparent"
                    />
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">Merchant Tag</p>
                    <h4 className="text-[#535456] text-[14px]">{merchTag}</h4>
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">
                      Beneficiary Name
                    </p>
                    <h4 className="text-[#535456] text-[14px]">{merchName}</h4>
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">Payment Method</p>
                    <h4 className="text-[#535456] text-[14px]">Wallet</h4>
                  </div>
                  <div className="w-[100%] flex justify-between items-center mb-[10px]">
                    <p className="text-[14px] text-[#070707]">Description</p>
                    <h4 className="text-[#535456] text-[14px]">{merchDesc}</h4>
                  </div>
                </div>
                <div className="flex items-center justify-start my-[20px]">
                  <input
                    type="checkbox"
                    id="benefit"
                    className="mr-[10px]"
                    onClick={() => setSaveBenMerch(!saveBenMerch)}
                  />
                  <label for="Benefit">Save as beneficiary</label>
                </div>
                <FormGroup className="relative">
                  <input
                    type={passwordType}
                    placeholder="Pin"
                    className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                    onChange={(e) => setPin(e.target.value)}
                  />
                  <button
                    onClick={togglePassword}
                    className="absolute right-[50px] top-[20px]"
                  >
                    {passwordType === "password" ? (
                      <FaRegEye className="text-[25px" />
                    ) : (
                      <FaRegEyeSlash className="text-[25px" />
                    )}
                  </button>
                </FormGroup>
                <button
                  className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[1rem]"
                  onClick={() => {
                    doPenn2Merch();
                    toggleP2M();
                  }}
                >
                  {loading ? (
                    <Spinner color="light">Loading...</Spinner>
                  ) : (
                    "Proceed"
                  )}
                </button>
              </>
            )}
            <Modal
              isOpen={P2Mmodal}
              toggle={toggleP2M}
              className="w-[400px] h-[500px] top-[20%] rounded-[14px]"
            >
              <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                {merchTransferData?.status === "success" ? (
                  <img src="/assets/checkmark.png" alt="" />
                ) : (
                  <img src="/assets/Cancel.png" alt="" />
                )}
                <p className="mt-[20px] text-center">
                  {merchTransferData?.message}
                </p>
                <button
                  onClick={() => {
                    toggleP2M();
                    closeP2MModal();
                  }}
                  className="w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px] text-white"
                >
                  Ok
                </button>
              </ModalBody>
            </Modal>
          </Tab>
        </Tabs>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default TransferCanvas;
