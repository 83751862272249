import {createSlice} from '@reduxjs/toolkit'

const profileSlice = createSlice({
    name: "profile",
    initialState: {
        userProfile: {},
        isFetching: false,
        error:false,
        success: false,
    },
    reducers: {
        profileStart:(state)=>{
            state.isFetching = true;
            state.success = false;
        },
        profileSuccess:(state,action)=>{
            state.isFetching = false;
            state.userProfile = action.payload;
            state.success = true;
            state.error = false;
        },
        profileFailure:(state,action)=>{
            state.isFetching = false;
            state.error = true;
            state.success = false;
        },
        profileClear:(state) => {
            state.currentUser = {};
            state.error = false;
            state.success = false;
            state.otpSuccess = false;
            state.otpError = false;
        }
        
    }
})

export const {profileStart,profileSuccess,profileFailure,profileClear} = profileSlice.actions
export default profileSlice.reducer