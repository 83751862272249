import React, { useEffect, useState } from "react";
import { FaChevronRight, FaFileDownload } from "react-icons/fa";
import Topbar from "../../components/Topbar/Topbar";
import { useSelector } from "react-redux";
import { userRequest } from "../../requestMethod";
import ReceiptCanvas from "./ReceiptCanvas";

function Receipts({ userType, setUserType, merchantId, chooseBus }) {
  const [showReceipt, setShowReceipt] = useState(false);
  const handleCloseReceipt = () => setShowReceipt(false);
  const handleShowReceipt = () => setShowReceipt(true);
  const [receipteHistory, setreceiptHistory] = useState(null);
  const token = useSelector(
    (state) => state?.user?.currentUser?.token?.access_token
  );

  const getReceipthistory = async () => {
    const response = await userRequest.get(
      "api/v2/customer/merchant/receipt/history",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setreceiptHistory(response.data.data.items);
  };
  useEffect(() => {
    getReceipthistory();
  }, []);

  return (
    <div style={{ flex: 4 }}>
      <Topbar setUserType={setUserType} userType={userType} />
      <div className="bg-[#F0F0F0] w-[100%] min-h-[100vh] px-[40px] pt-[30px]">
        <h1 className="text-[24px] font-bold pb-[15px] mb-[26px]">Receipts</h1>
        <div className="flex justify-end relative right-0">
          {/* <div className="relative w-[100%] right-0 mb-[28px] flex justify-end">
            <div className="relative inset-y-0 left-[30px] flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block w-[563px] h-[60px] rounded-[12px] pl-[40px] text-sm text-gray-900 border border-gray-300  bg-gray-50"
              placeholder="Search Receipts"
            />
          </div> */}
        </div>
        <ReceiptCanvas
          showReceipt={showReceipt}
          handleCloseReceipt={handleCloseReceipt}
          merchantId={merchantId}
          chooseBus={chooseBus}
        />
        <div className="w-[100%] h-[66px] bg-white rounded-tl-[14px] rounded-tr-[14px] flex items-center justify-end mb-[20px]">
          <button
            className="bg-white border-solid border-1 border-[#4CC800] text-center text-[#4CC800] w-[219px] h-[45px] rounded-[30px] text-[14px] mr-[30px]"
            onClick={handleShowReceipt}
          >
            + Generate
          </button>
        </div>
        <div className="w-[100%]">
          <table className="w-[100%] px-[10px]">
            {receipteHistory?.length === 0 ? (
              <div className="flex items-center justify-center bg-[#fff] w-[100%] h-[300px]">
                <p className="font-bold text-[24px]">
                  No receipt created
                </p>
              </div>
            ) : (
              <>
                <thead>
                  <tr className="flex items-center justify-center gap-[50px] h-[52px]">
                    <th className="text-[12px] w-[150px]">CUSTOMER NAME</th>
                    <th className="text-[12px] w-[80px]">TYPE</th>
                    <th className="text-[12px] w-[150px]">RECEIPT DATE</th>
                    <th className="text-[12px] w-[120px]">RECEIPT AMOUNT</th>
                    <th className="text-[12px] w-[100px]">TAX</th>
                    <th className="text-[12px] w-[100px]">TOTAL AMOUNT</th>
                    <th className="text-[12px] w-[15px]"></th>
                  </tr>
                </thead>
                <tbody>
                  {receipteHistory?.map((receipt) => (
                    <tr className="flex items-center justify-center gap-[50px] h-[60px] bg-white px-[15px] border-b-[1px]">
                      <td className="text-[12px] w-[150px]">
                        {receipt.recipient_name || receipt.recipient_email}
                      </td>
                      <td className="text-[12px] text-[#FFC025] px-[20px] py-[8px] bg-[#FFC025]/[0.1] w-[80px] rounded-[33px] text-center">
                        Receipt
                      </td>
                      <td className="text-[12px] w-[150px]">
                        {new Date(receipt.date_created).toLocaleDateString()}{" "}
                        {new Date(receipt.date_created).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </td>
                      <td className="text-[12px] w-[120px]">
                        {receipt.amount}
                      </td>
                      <td className="text-[12px] w-[100px]">{receipt.tax}%</td>
                      <td className="text-[12px] w-[100px]">
                        ₦{receipt.amount}
                      </td>
                      <td className="text-[18px] w-[15px]">
                        <a
                          href={receipt.document_link}
                          target="_blank"
                          download={receipt.document_link}
                          rel="noreferrer"
                        >
                          <FaFileDownload />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            )}
          </table>
        </div>
      </div>
    </div>
  );
}

export default Receipts;
