import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";

import { Close } from "@mui/icons-material";
import { Modal, ModalBody, Spinner } from "reactstrap";
import FileUpload from "./FileUpload";
import { useSelector } from "react-redux";
import { uploadRequest, userRequest } from "../../../requestMethod";
import { useNavigate } from "react-router-dom";

const AddNewBusiness = ({ showverif, handleShowVerif,merchantId }) => {
  const [loading, setLoading] = useState(false);
  var formData = new FormData();
  const navigate = useNavigate();
  const [cert, setCert] = useState("");
  const [statusR, setStatus] = useState("");
  const [UtilityBill, setUtilityBill] = useState("");
  const [validId, setValidId] = useState("");
  const [others, setOthers] = useState("");
  const [verifyData, setVerifyData] = useState();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const secondToggle = () => {
    setModal(!modal);
    // navigate(0);
  };
  const token = useSelector(
    (state) => state?.user?.currentUser?.token?.access_token
  );


  const verifyMerchant = async () => {
    let formData = new FormData();
    formData.append("UtilityBill", UtilityBill);
    formData.append("CertificateOfIncoporation", cert);
    formData.append("Mermat", statusR);
    formData.append("Identification", validId);
    formData.append("BusinessId", merchantId);
    try {
      setLoading(true);
      const response = await uploadRequest({
        method: "post",
        url: "api/v2/customer/verification/document/update",
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        },
      });
      
      // const response = await userRequest.post(
      //   "api/v2/customer/verification/document/update",{
      //     data :formData
      //   },
      // {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //       source: "web",
      //       "x-api-key": "94684537",
      //     },
      //   }

      // );
      setVerifyData(response.data.message);
      setLoading(false);
      toggle();
    } catch (error) {
      setVerifyData(error.response.data.message);
      setLoading(false);
      toggle();
    }
  };

 
  return (
    <>
      <Offcanvas
        show={showverif}
        onHide={handleShowVerif}
        placement="end"
        className="py-[20px] pl-[30px] flex flex-col w-[505px]"
      >
        <Offcanvas.Header closeButton className="w-[100%]">
          <Offcanvas.Title className="mt-[60px]">
            Verify your Business
          </Offcanvas.Title>
          <Close onClick={handleShowVerif} className="mr-[-200px]" />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <FileUpload
            title="Certificate of Incorporation"
            handleChange={(e) => setCert(e.target.files[0])}
            filename={cert}
          />
          <FileUpload
            title="Status Report"
            handleChange={(e) => setStatus(e.target.files[0])}
            filename={statusR}
          />
          <FileUpload
            title="Utility Bill"
            handleChange={(e) => setUtilityBill(e.target.files[0])}
            filename={UtilityBill}
          />
          <FileUpload
            title="Valid Means of ID"
            handleChange={(e) => setValidId(e.target.files[0])}
            filename={validId}
          />
          <FileUpload
            title="Others"
            handleChange={(e) => setOthers(e.target.files[0])}
            filename={others}
          />

          <button
            className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[1rem]"
            onClick={() => {verifyMerchant();handleShowVerif()}}
          >
            {loading ? <Spinner color="light" /> : "Submit"}
          </button>
          <Modal
            isOpen={modal}
            toggle={toggle}
            className="w-[400px] h-[500px] top-[20%] rounded-[14px]"
          >
            <ModalBody className="flex flex-col items-center justify-center py-[20px]">
              {verifyData && (
                <p className="text-[black] test-[10px] text-center w-[350px] mb-[10px]">
                  {verifyData}
                </p>
              )}
              <button
                onClick={() => {
                  toggle();
                  secondToggle();
                }}
                className="w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px] text-white"
              >
                Ok
              </button>
            </ModalBody>
          </Modal>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default AddNewBusiness;
