import React from "react";
import Topbar from "../../Topbar/Topbar";
import MerchantHome from "./MerchantHome";

function Mdashboard({
  setUserType,
  userType,
  setBusIndex,
  merchantName,
  merchantVerif,
  merchants,
  merchant,
  linkedBus,
  linkBusData,
  setBusLinked,
  setBusMerch,
  chooseBus,
  merchantId,
  roleName,
  loading
}) 

{

  console.log("chooseBus", chooseBus)
  return (
    <div className="dashboard relative">
      <Topbar setUserType={setUserType} userType={userType} />
      <MerchantHome
        setBusIndex={setBusIndex}
        merchantName={merchantName}
        merchantVerif={merchantVerif}
        merchants={merchants}
        merchant={merchant}
        linkedBus={linkedBus}
        linkBusData={linkBusData}
        setBusLinked={setBusLinked}
        setBusMerch={setBusMerch}
        chooseBus={chooseBus}
        merchantId={merchantId}
        roleName={roleName}
        loading={loading}
        setUserType={setUserType}
      />
    </div>
  );
}

export default Mdashboard;
