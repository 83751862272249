import React, {useState, useEffect} from 'react'
import { Offcanvas } from "react-bootstrap";
import { Input, Label, Modal, ModalBody, Spinner } from "reactstrap";
import { Close } from "@mui/icons-material";
import { useSelector } from 'react-redux';
import { userRequest } from '../../../requestMethod';
import { useNavigate } from 'react-router-dom';

function NewBusiness({showNewBusCanvas,handleCloseBusinessCanvas, merchantVerif, setUserType}) {
    const [loading, setLoading] = useState(false);
    const [businessName, setBusinessName] = useState("");
    const [regNumber, setRegNumber] = useState("");
    const [taxNumber, setTaxNumber] = useState("");
    const [address, setAddress] = useState("");
    const [businessType, setBusinessType] = useState("")
    const [emailAddress, setEmailAddress] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [city, setCity] = useState("")
    const [lga, setLga] = useState("")
    const [soo, setSOO] = useState("")
    const [businessData, setBusinessData] = useState(null)
    const [buscategory, setBusCategory] = useState(null)
    const [apiComp, setApiComp] = useState(false)
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const secondToggle = () => {
      setModal(!modal)
      setApiComp(false);
      navigate(0);
      setUserType("merchant")
    }
  
    const navigate = useNavigate();
    const token = useSelector(
      (state) => state?.user?.currentUser?.token?.access_token
    );
  
    const user = useSelector(
      (state) => state.user.currentUser.data
    );
  
    
  const createNewBus = async () => {
    setLoading(true);
    try {
      const response = await userRequest.post(
        "api/v2/customer/business/create",
        {
          business_name: businessName + "Testing",
          registration_number: regNumber,
          tax_number: taxNumber,
          support_email_address: emailAddress,
          support_mobile_number: phoneNumber,
          business_type_id: businessType,
          business_category_id: 3,
          number_of_employee: "1",
          address: address,
          incorporation_date: null ,
          city: city,
          country: "Nigeria",
          lga: lga,
          state: soo,
          other_type_name: "",
          legal_name: "",
          directors: [
            {
              name: user?.first_name,
              nationality: "Nigerian",
              address: "Lagos",
              gender: "Male",
              phone_number: user?.mobile_number,
              date_of_birth: user?.date_of_birth,
              shareholdings: "",
              occupation: "CEO",
              id_type: merchantVerif?.type,
              id_number: merchantVerif?.value
            }
          ],
          smileid_address: "",
          search_certificate: "",
          smileid_email: "",
          proprietors: [
          ]
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            source: "web",
            "x-api-key": "94684537",
          },
        }
      );
      setBusinessData(response.data.message);
      setLoading(false);
      toggle()
      setApiComp(true)
    } catch (error) {
      setBusinessData(error.response.data.message);
      setLoading(false);
      toggle()
      setApiComp(true)
    }
  };

  const getCategory = async () => {
    const response = await userRequest.get(
      "api/v2/customer/business/types",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setBusCategory(response.data.data);
  };

  useEffect(() => {
    getCategory();
  }, []);

    const states = [
        "Abia",
        "Adamawa",
        "Akwa Ibom",
        "Anambra",
        "Bauchi",
        "Bayelsa",
        "Benue",
        "Borno",
        "Cross River",
        "Delta",
        "Ebonyi",
        "Edo",
        "Ekiti",
        "Enugu",
        "FCT - Abuja",
        "Gombe",
        "Imo",
        "Jigawa",
        "Kaduna",
        "Kano",
        "Katsina",
        "Kebbi",
        "Kogi",
        "Kwara",
        "Lagos",
        "Nasarawa",
        "Niger",
        "Ogun",
        "Ondo",
        "Osun",
        "Oyo",
        "Plateau",
        "Rivers",
        "Sokoto",
        "Taraba",
        "Yobe",
        "Zamfara"
      ]
  return (
    <>
    <Offcanvas
        show={showNewBusCanvas}
        onHide={handleCloseBusinessCanvas}
        placement="end"
        className="py-[20px] pl-[30px] flex flex-col w-[505px]"
      >
        <Offcanvas.Header closeButton className="w-[100%]">
          <Offcanvas.Title className="mt-[60px]">
            Create a Merchant Account
          </Offcanvas.Title>
          <Close onClick={handleCloseBusinessCanvas} className="mr-[-200px]" />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Label for="bus-name" className="text-black">
            Business Name
          </Label>
          <Input
            id="bus-name"
            name="bus-name"
            type="text"
            className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
            onChange={e => setBusinessName(e.target.value)}
          />
          <Label for="reg-num" className="text-black">
            Company Registration number
          </Label>
          <Input
            id="reg-num"
            name="reg-num"
            type="text"
            className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
            onChange={e => setRegNumber(e.target.value)}
          />
          <Label for="inc-type" className="text-black">
            Business Incorporation Type
          </Label>
          <Input
            type="select"
            id="inc-type"
            name="inc-type"
            className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
          >
            <option value="">Business Incorporation Type</option>
            <option value="1">Business Name</option>
            <option value="2">Private/Public Limited</option>
            <option value="3">Incorporated Trustees</option>
          </Input>
          <Label For="DueDate" className="text-black">
          Date of Incorporation
        </Label>
        <input
          type="date"
          id="DueDate"
          name="DueDate"
          placeholder="Due Date"
          className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[50px]"
        />
          <Label for="tax" className="text-black">
            Tax identification Number(optional)
          </Label>
          <Input
            id="tax"
            name="tax"
            type="text"
            className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
            onChange={e => setTaxNumber(e.target.value)}
          />
          <Label for="email" className="text-black">
            Email Address
          </Label>
          <Input
            id="email"
            name="email"
            type="text"
            className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
            onChange={e => setEmailAddress(e.target.value)}
          />
          <Label for="phone" className="text-black">
            Phone Number
          </Label>
          <Input
            id="phone"
            name="phone"
            type="text"
            className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
            onChange={e => setPhoneNumber(e.target.value)}
          />
          <Label for="bus-category" className="text-black">
            Business Category
          </Label>
          <Input
            type="select"
            id="category"
            name="category"
            className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
            onChange={(e) => setBusinessType(e.target.value)}
          >
            <option value=""></option>
            {buscategory?.map((buscat) => (
              <option value={buscat.id}>{buscat.name}</option>
            ))}
          </Input>
          <Label for="bus-emp" className="text-black">
            Number of Employees
          </Label>
          <Input
            type="select"
            id="bus-emp"
            name="bus-emp"
            className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
          >
            <option value="" disabled></option>
            <option value="1">Myself</option>
            <option value="2">1-10</option>
            <option value="3">11-20</option>
            <option value="4">21-50</option>
            <option value="5">51-above</option>
          </Input>
          <Label for="address" className="text-black">
            Address
          </Label>
          <Input
            id="address"
            name="address"
            type="text"
            className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
            onChange={e => setAddress(e.target.value)}
          />
          <Label for="city" className="text-black">
            City
          </Label>
          <Input
            id="city"
            name="city"
            type="text"
            className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
            onChange={e => setCity(e.target.value)}
          />
          <Label for="lga" className="text-black">
            Local Government Area (LGA)
          </Label>
          <Input
            id="lga"
            name="lga"
            type="text"
            className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
            onChange={e => setLga(e.target.value)}
          />
          <Label for="state" className="text-black">
            State
          </Label>
          <Input
            type="select"
            id="state"
            name="state"
            className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
            onChange={e => setSOO(e.target.value)}
          >
            <option value=""></option>
            {states.map((state) => (
              <option value={state}>{state}</option>
            ))}
          </Input>
          <button className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[5rem]" onClick={() => createNewBus()}>
            {loading ? (
              <Spinner color="light"> Loading...</Spinner>
            ) : (
              "Create Business Account"
            )}
          </button>
          <Modal isOpen={modal} toggle={toggle} className='w-[400px] h-[500px] top-[20%] rounded-[14px]'>
              <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                  {apiComp && <p className="text-[black] test-[10px] text-center w-[350px] mb-[10px]">{businessData}</p>}
                  <button onClick={() => {toggle();secondToggle()}} className='w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px] text-white'>Ok</button>
              </ModalBody>
            </Modal>   
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default NewBusiness
