import { Close } from '@mui/icons-material';
import React from 'react'
import { useState } from 'react';
import Offcanvas from "react-bootstrap/Offcanvas";
import './Topbar.css'
import VerifyCanvas from '../DashHome/DashboardComp/VerifyCanvas';

function CompleteKYC({handleShowKyc,showKYC}) {
    const [showVerifyCanvaskyc, setShowVerifyCanvaskyc] = useState(false);
    const handleVerifyCanvaskyc = () => {
        setShowVerifyCanvaskyc(!showVerifyCanvaskyc)
    }
  return (
    <Offcanvas
      show={showKYC}
      onHide={handleShowKyc}
      placement="end"
      className="py-[20px] pl-[30px] flex flex-col w-[505px]"
    >
      <Offcanvas.Header closeButton className="w-[100%]">
        <Offcanvas.Title className="mt-[60px]">
          Complete KYC!!!
        </Offcanvas.Title>
        <Close onClick={handleShowKyc} className="mr-[-200px]" />
      </Offcanvas.Header>
      <Offcanvas.Body className='flex items-center justify-center text-center'>
        <div className='w-[323px] h-[412px] bg-[#fff] border-solid border-[3px] rounded-[14px] flex flex-col items-center justify-center shadow-[0px 0px 5px 2px rgba(76,200,0,0.75)]'>
            <img src="assets/Cancel.png" alt="" className='mb-[35px]'/>
            <h1 className='text-[24px] font-bold mb-[15px]'>Complete KYC</h1>
            <p className='text-[16px] font-normal w-[197px] mb-[63px]'>Complete KYC to create a merchant account</p>
            <button className='w-[173px] h-[53px] bg-[#4cc800] text-[#fff] font-bold' onClick={() => {handleVerifyCanvaskyc()}}>Complete KYC</button>
        </div>
        <VerifyCanvas  showVerifyCanvas={showVerifyCanvaskyc} handleVerifyCanvas={handleVerifyCanvaskyc}/>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default CompleteKYC
