import React from 'react'
import {FaCopy} from 'react-icons/fa'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'reactstrap';

function Vfd({wallet , loading}) {
    const copied = () => toast.success("Account details copied!",{ autoClose: 100 });
  return (
    <div className='w-[377px] h-[126px] bg-[#1a1a1a] rounded-[14px] flex flex-col justify-center pl-[30px] mb-[7px] relative'>
      <div className='flex flex-col'>
        <p className='text-white text-[12px] mb-[10px]'>Bank: {loading ? <Spinner color='light' size='sm'/> : wallet?.bank_name}</p>
        {/* <p className='text-white text-[12px] mb-[10px] z-10'>Account Name: {customer?.last_name + " " + customer?.first_name}</p> */}
        <div className='flex gap-3 items-center '>
            <p className='text-white text-[19px] font-bold'>{loading ? <Spinner color='light' size = 'sm'/> : wallet?.account_number}</p>
            <CopyToClipboard text={wallet?.account_number}>
                <FaCopy className='fill-[#A3A3A3] text-[19px]' onClick={copied}/>
            </CopyToClipboard>
            <ToastContainer />
        </div>
      </div>
      <div className='absolute bottom-0 right-0'>
            <img src="/assets/greenup.png" className='' alt="" />
      </div>
    </div>
  )
}

export default Vfd
