import { useState } from "react";
import AddNewBusiness from "./AddNewBusiness";

function Verfication({ chooseBus, merchantId }) {
  const [showverif, setShowVerif] = useState(false);
 const handleShowVerif = () => {
    setShowVerif(!showverif);
  };
return (
    <div className="cursor-pointer">
    {chooseBus ? 
      <div
        className="w-[373px] h-[66px] bg-[#1B1B1B] rounded-[14px] flex items-center justify-between px-[20px]"
        onClick={() => handleShowVerif()}
      >
        <div className="flex items-center justify-center gap-[12px]">
          <div className="w-[13px] h-[13px] bg-[#4CC800] rounded-[50%] text-white"></div>
          <p className="text-[#fff]">Complete Business Verification</p>
        </div>
        <div>
          <img src="/assets/arrow.png" alt="" />
        </div>
      </div>
 : ""}
      <AddNewBusiness showverif={showverif} handleShowVerif={handleShowVerif} merchantId={merchantId}/>
    </div>
  );
}

export default Verfication;
