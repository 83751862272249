import React from 'react'
import VisaCard from '../DashHome/DashboardComp/VisaCard'
import './merchant.css'
import UserInvoice from './UserInvoice'
import Verfication from './MerchantDashboard/Verfication'
import { useSelector } from 'react-redux'


function WidgetSm({chooseBus, merchantId, merchant}) {

  return (
    <div className='widgetSm'>
      <VisaCard/>
      {merchant?.status !== "Verified" && 
        <Verfication chooseBus={chooseBus} merchantId={merchantId}/>
  }
      <UserInvoice/>
    </div>
  )
}

export default WidgetSm
