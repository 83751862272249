import React from 'react'
import Balance from './DashboardComp/Balance'
import LockFunds from './DashboardComp/LockFunds'
import QuickTransactions from './DashboardComp/QuickTransactions'
import RecentTrans from './DashboardComp/RecentTrans'
import TargetSavings from './DashboardComp/TargetSavings'
import './dashHome.css'

function WidgetLg({wallet, target_savings, locked_funds,vfd, loading}) {
  return (
    <div className='widgetLg'>
      <div className='flex gap-[13px] mb-[18px]'>
        <Balance wallet={wallet} vfd={vfd} loading={loading}/>
        <LockFunds locked_funds={locked_funds} wallet={wallet}/>
        <TargetSavings target_savings={target_savings}/>
      </div>
      <QuickTransactions wallet={wallet}/>
      <RecentTrans/>
    </div>
  )
}

export default WidgetLg
