import React,{useState,useEffect} from 'react'
import { Form, FormGroup, Input, Label, Modal, ModalBody, Spinner } from 'reactstrap';
import { useSelector,useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa'
import { signUp } from '../../requestMethod';
import { LogOut } from '../../redux/userRedux';


function SetPin() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState("")
  const [pinData,setPinData] = useState()
  const navigate = useNavigate();
  const [errorState, setErrorState] = useState(false)
    const [otpErrMessage, setOtpErrMessage] = useState(null)
    const [modal,setModal] = useState(false);
    const toggle = () => {
      setModal(!modal);
    }

    const secondToggle = () => {
      setModal(!modal);
      dispatch(LogOut())
      window.localStorage.clear();
      navigate('/')
    }

    const token = useSelector((state) => state?.user?.currentUser?.token?.access_token);


  const SetUserPin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await signUp.post(
        "api/v2/customer/sign-up/set-transaction-pin",
        {
          pin: pin,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            source: "web",
            "x-api-key": "94684537",
            version: 5,
          },
        }
      );
      setPinData(response.data);
      setLoading(false);
      toggle()
    } catch (error) {
      setPinData(error.response.data.message);
      setLoading(false);
      toggle()
    }
  };




  useEffect(() => {
    if (pinData?.status === "success") {
      navigate("/", { replace: true });
    }
  }, [navigate, pinData?.status]);

  return (
    <div className="flex justify-center items-center bg-white relative h-[100vh] w-[100%]">
      <img
        src="/assets/login-design.png"
        alt=""
        className="absolute top-0 left-0 md:w-[auto] w-[300px]"
      />
      <Link to="/create-account">
        <div className="absolute top-[150px] left-[50px] flex gap-[15px] items-center justify-center cursor-pointer">
          <FaArrowLeft />
          <p className="text-[16px]">Go Back</p>
        </div>
      </Link>
      <div className="mt-[0px]text-white flex flex-col items-center justify-center">
        <img
          src="/assets/blacktext.png"
          alt=""
          className="md:w-[200px] w-[150px]"
        />
        <p className="text-black text-[16px] text-center mt-[20px]">
          Kindly set your Transaction Pin
        </p>
        <div className="md:w-[450px] w-[350px] bg-slate-100 py-[40px] rounded-[30px] flex flex-col items-center justify-center mt-[20px]">
          <FormGroup className="mt-[10px]">
            <Label for="pin" className="text-black">
              Set Pin
            </Label>
            <Input
              id="pin"
              name="pin"
              placeholder="Enter Pin"
              type="text"
              className="md:w-[400px] w-[320px] rounded-[20px] pl-[20px] bg-white"
              onChange={(e) => setPin(e.target.value)}
            />
          </FormGroup>
          <div className="flex justify-center">
            <button
              className="text-white bg-[#4CC800] px-[60px] py-[10px] rounded-[136px] mt-[20px] w-[200px]"
              onClick={SetUserPin}
            >
              {loading ? <Spinner color="light" size="sm" /> : "Confirm"}
            </button>
          </div>
        </div>
        <Modal isOpen={modal} toggle={toggle} className='w-[400px] h-[500px] top-[20%] rounded-[14px]'>
              <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                  {errorState && <p className="text-[red] test-[10px] text-center w-[350px] mb-[10px]">{pinData?.message}</p>}
                  <button onClick={() => {toggle();secondToggle()}} className='w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px] text-white'>Proceed to Login</button>
              </ModalBody>
            </Modal>
      </div>
      <img
        src="/assets/login-design2.png"
        alt=""
        className="absolute bottom-0 right-0 w-[300px] md:w-[auto]"
      />
    </div>
  );
}

export default SetPin
