import { Close } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useSelector } from "react-redux";
import { Label } from "reactstrap";
import { userRequest } from "../../requestMethod";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import pdfFile from "./test.pdf";
import { RWebShare } from "react-web-share";
import {FaShareAlt} from 'react-icons/fa'
import { NumericFormat } from "react-number-format";

function ReceiptCanvas({ showReceipt, handleCloseReceipt,merchantId,chooseBus}) {
  const [Name, setName] = useState("");
  const [Quantity, setQuantity] = useState(Number);
  const [download, setDownload] = useState(false);
  const [Rate, setRate] = useState(Number);
  const [receiptState, setReceiptState] = useState(false)
  const [receiptError, setReceiptError] = useState(null)
  const [dueDate, setDueDate] = useState(null);
  const [item, setItem] = useState("");
  const [receiptData, setReceiptData] = useState(null);
  const [downloadlink, setDownloadLink] = useState("");
  const date = new Date();
  var yr = date.getFullYear();
  var mo = date.getMonth() + 1;
  var day = date.getDate();
  const dateToUse = yr + "-" + mo + "-" + day
  const [phone, setPhone] = useState("");
  const [cFullName, setCFullName] = useState("");
  const [cAddress, setCAddress] = useState("");
  const [amountR, setAmountR] = useState(Number);
  const [tax, setTax] = useState(0);
  const [receiptPreview, setReceiptPreview] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [profile, setProfile] = useState(null)
  const subTotal = Rate * Quantity;
  const Total = (tax / 100) * subTotal + subTotal;
  const token = useSelector(
    (state) => state?.user?.currentUser?.token?.access_token
  );

  console.log("rate", Rate)

  const getProfile = async () => {
    const response = await userRequest.get("api/v2/customer/me",{
      headers: {"Authorization" : `Bearer ${token}`}
    });
    setProfile(response.data.data)
}
useEffect(() => {
  getProfile();
},[]);


const resetStates = () => {
  setQuantity("")
  setRate("")
  setItem("")
  setPhone("")
  setAmountR("")
  setCFullName("")
  setAmountR("")
}


  function currencyFormat(num) {
    return "₦" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, ",");
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const apiUrl = chooseBus ? "api/v2/customer/merchant/receipt/create" : "api/v2/customer/merchant/receipt/create-for-business"

  const generateReceipt = async () => {
    try {
      setReceiptState(false)
      const response = await userRequest.post(
       apiUrl,
        {
          business_id: merchantId,
          due_date: dueDate,
          date_generated: dateToUse,
          recipients: [
            {
              email_address: Name,
              mobile_number: phone,
              full_name: cFullName,
              address: cAddress,
              invoice_recipient_id: 0
            }
          ],
          items: [
            {
              description: "Payment Receipt",
              unit: "Naira",
              rate: Rate,
              quantity: Quantity
            }
          ],
          notes: "Payment Receipt",
          add_signature: false,
          add_logo: false,
          reference: `Payment for ${item}`,
          tax: tax,
          amount_received: amountR,
          name: `Payment for ${item}`,
          is_receipt: true
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            source: "web",
            "x-api-key": "94684537",
          },
        }
      );
      setReceiptData(response);

      setDownloadLink(response.data.data.invoice.document_link);
      setReceiptPreview(true);
    } catch (error) {
      setReceiptData(error.data);
      setDownloadLink(null);
      setReceiptPreview(true);
      setReceiptError(error.response.data)
      setReceiptState(true)
    }
  };

  console.log("dueDate", dueDate)

 

  return (
    <Offcanvas
      show={showReceipt}
      onHide={() => {handleCloseReceipt(); setReceiptData(null);resetStates()}}
      placement="end"
      className="py-[20px] pl-[30px] flex flex-col w-[505px]"
    >
      <Offcanvas.Header closeButton className="w-[100%]">
        <Offcanvas.Title className="mt-[60px]">
          Generate Receipt
        </Offcanvas.Title>
        <Close onClick={() => {handleCloseReceipt(); setReceiptData(null);resetStates()}} className="mr-[-200px]" />
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Label for="customer_name" className="text-black">
          Customer Name
        </Label>
        <input
          type="text"
          id="customer_name"
          name="customer_name"
          placeholder=""
          className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px]"
          onChange={(e) => setCFullName(e.target.value)}
        />
        <Label for="customer_email" className="text-black">
          Email
        </Label>
        <input
          type="text"
          id="customer_email"
          name="customer_email"
          placeholder=""
          className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px]"
          onChange={(e) => setName(e.target.value)}
        />
        <Label for="phone" className="text-black">
          Phone Number
        </Label>
        <input
          type="text"
          id="phone"
          name="phone"
          placeholder=""
          className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px]"
          onChange={(e) => setPhone(e.target.value)}
        />
        <Label for="address" className="text-black">
          Address
        </Label>
        <input
          type="text"
          id="address"
          name="address"
          placeholder=""
          className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px]"
          onChange={(e) => setCAddress(e.target.value)}
        />
        <Label for="item" className="text-black">
          Customer Item
        </Label>
        <input
          type="text"
          id="item"
          name="item"
          placeholder=""
          className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px]"
          onChange={(e) => setItem(e.target.value)}
        />
        <Label for="rate" className="text-black">
          Price Per Unit
        </Label>
        {/* <NumericFormat  thousandSeparator=","  prefix='' className='w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px]' onChange={(e) => setRate(e.target.value)}/> */}
        <input
          type="text"
          id="rate"
          name="rate"
          placeholder=""
          className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px]"
          onChange={(e) => setRate(e.target.value)}
        />
        <Label for="quantity" className="text-black">
          Quantity
        </Label>
        <input
          type="text"
          name="quantity"
          id="quantity"
          placeholder=""
          className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px]"
          onChange={(e) => setQuantity(e.target.value)}
        />
        <Label for="tax" className="text-black">
          Tax(%)
        </Label>
        <input
          type="text"
          name="tax"
          id="tax"
          placeholder=""
          className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px]"
          onChange={(e) => setTax(e.target.value)}
        />
        <Label for="amountR" className="text-black">
          Total
        </Label>
        <input
          type="text"
          name="amountR"
          id="amountR"
          placeholder=""
          className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px]"
          value={Total}
        />
        <Label for="amountR" className="text-black">
          Amount Received
        </Label>
        <input
          type="text"
          name="amountR"
          id="amountR"
          placeholder=""
          className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px]"
          onChange={(e) => setAmountR(Number(e.target.value))}
        />

        <Label For="DueDate" className="text-black">
          Due Date
        </Label>
        <input
          type="date"
          id="DueDate"
          name="DueDate"
          placeholder="Due Date"
          className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] px-[20px] mb-[50px]"
          onChange={(e) => setDueDate(e.target.value)}
        />
        <p className="text-[red]">{receiptState && receiptError.message}</p>
            {receiptData?.data?.status === "success" ? (
          <a
            className="bg-[#fff] text-center text-[#4CC800] border-solid border-1 border-[#4CC800] px-[168px] py-[20px]  rounded-[30px] text-[20px] mt-[2rem]"
            rel="noreferrer"
            target="_blank"
            href={downloadlink}
            download={receiptData?.invoice?.document_link}
          >
            Download
          </a>
        ) : (
          <button
            className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[20px] mb-[2rem]"
            onClick={() => {
              generateReceipt();
              setDownload(!download);
            }}
          >
            Generate
          </button>
        )}
        {/* <button
          className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[20px] mb-[2rem]"
          onClick={() => {
            generateReceipt();
            setDownload(!download);
          }}
        >
          Generate
        </button>
        {receiptData?.data?.status === "success" ? (
          <div className="flex items-center justify-center gap-[20px]">
              <a
            className="bg-[#fff] text-center text-[#4CC800] border-solid border-1 border-[#4CC800] px-[54px] py-[10px]  rounded-[30px] text-[20px] mt-[2rem]"
            rel="noreferrer"
            target="_blank"
            href={downloadlink}
            download={receiptData?.data?.data?.invoice?.document_link}
          >
            Download
          </a>
          <RWebShare
          data={{
            text: "Share Receipt to Customers",
            url: {downloadlink},
            title: "Share Receipt",
            sites:["facebook","whatsapp","twitter","telegram","mail","copy"]
          }}
          
        >
          <button className="flex items-center justify-center gap-[10px] bg-[#4CC800] text-center text-[#fff] border-solid border-1 border-[#4CC800] px-[54px] py-[10px]  rounded-[30px] text-[20px] mt-[2rem]"><FaShareAlt className="fill-white"/> <p className="text-[#fff]">Share</p></button>
        </RWebShare>
          </div>
          
        ) : (
          ""
        )} */}
        
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default ReceiptCanvas;
