import React from 'react'
import PennTag from './DashboardComp/PennTag'
import UserPortfolio from './DashboardComp/UserPortfolio'
import Vfd from './DashboardComp/Vfd'
import VisaCard from './DashboardComp/VisaCard'
import './dashHome.css'
import CompleteVerif from './DashboardComp/CompleteVerif'
import { useSelector } from 'react-redux'


function WidgetSm({vfdAccNumber, wallet, customer, loading}) {
  const status = useSelector(
    (state) => state?.user?.currentUser?.data?.status
  );
  return (
    <div className='widgetSm'>
      <Vfd vfdAccNumber={vfdAccNumber} wallet={wallet} customer={customer} loading={loading}/>
      <PennTag loading={loading}/>
      {status !== "Verified" && 
        <CompleteVerif/>
      }
      <VisaCard/>

      <UserPortfolio/>
    </div>
  )
}

export default WidgetSm
