import { Close } from "@mui/icons-material";
import React, { useState, useEffect, useRef } from "react";
import { Offcanvas } from "react-bootstrap";
import { userRequest } from "../../../requestMethod";
import { useSelector } from "react-redux";
import { Input, Label, Modal, ModalBody, Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft, FaLastfmSquare } from "react-icons/fa";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import SeerbitCheckout from "seerbit-reactjs";
import { PaystackButton } from 'react-paystack';



function TargetSavingsCanvas({ showTarget, handleCloseTarget }) {
  const [loading, setLoading] = useState(false);
  const [targetNames, setTargetName] = useState(null);
  const [purposeName, setPurposeName] = useState(null);
  const [amount, setAmount] = useState(Number);
  const [reccurAmount, setReccurAmount] = useState(Number);
  const [frequency, setFrequency] = useState(null);
  const [categoryValue, setCategoryValue] = useState(null);
  const [cardData, setCardData] = useState(null);
  const [cardRef, setCardRef] = useState("");
  const [cardProvider, setCardProvider] = useState("");
  const [categoryId, setCategoryId] = useState(Number);
  const [tAndC, setTandC] = useState(false);
  const [cardResponse, setCardResponse] = useState(null)
  var newDate = new Date();
  const seerbitButton = useRef();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(newDate);
  const [weekDay, setWeekDay] = useState(0);
  const [monthDay, setMonthDay] = useState(0);
  const [withdrawalDay, setWithdrawalDay] = useState("");
  const [targetDays, setTargetDays] = useState(Number);
  const [estimate, setEstimate] = useState(Number);
  const [interestDays, setInterestDays] = useState(Number);
  const [targetData, setTargetData] = useState(null);
  const [firstPage, setFirstPage] = useState(true);
  const [showCards, setShowCards] = useState(false);
  const [addCardPage, setAddCardPage] = useState(false);
  const [seerbitRef, setSeerbitRef] = useState("");
  const [paystackRef, setPaystackRef] = useState(null);
  const [seerbitTransId, setSeerbitTransId] = useState(null);
  const [targetModal, setTargetModal] = useState(false);
  const [payStackId,setPaystackId] = useState('')
  const [paystackResponse, setPayStackResponse] = useState(null)
  const toggleTargetModal = () => setTargetModal(!targetModal);
  const closeTargetModal = () => {
    handleCloseTarget();
    toggleTargetModal();
    navigate(0);
  };
  const [seerbitModal, setSeerbitModal] = useState(false);
  const toggleSeerbitModal = () => setSeerbitModal(!seerbitModal);
  const closeSeebitModal = () => {
    toggleSeerbitModal();
    setSeerbitRef("")
  };

  const [paystackModal, setPaystackModal] = useState(false);
  const togglePaystackModal = () => setPaystackModal(!paystackModal);
  const closePayStackModal = () => {
    togglePaystackModal();
    setPaystackRef("")
  };
  const [successCardModal, setSuccessCardModal] = useState(false);
  const toggleSuccessCardModal = () => setSuccessCardModal(!successCardModal);
  const closeSuccessCardModal = () => {
    toggleSuccessCardModal();
    navigate(0)
  };
  const [hundreCardModal, setHundredCardModal] = useState(false);
  const toggleHundredCardModal = () => setHundredCardModal(!hundreCardModal);
  const closeHundredCardModal = () => {
    toggleHundredCardModal();
  };
  const PennyTreeInterest = Number(15.4 / 36500);
  const targetOccurence = () => {
    const targetDys = Number(Number(amount) / Number(reccurAmount));
    if (categoryValue === "2") {
      setTargetDays(targetDys * 7);
    } else if (categoryValue === "1") {
      setTargetDays(targetDys);
    } else {
      setTargetDays(targetDys);
    }
    if (categoryValue === "2") {
      setInterestDays(targetDys * 7);
    } else if (categoryValue === "1") {
      setInterestDays(targetDys);
    } else {
      setInterestDays(targetDys * 30);
    }
  };

  function currencyFormat(num) {
    return "₦" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "₦1,");
  }

  const compoundInterest = (p, t, r, n) => {
    const amount = p * Math.pow(1 + r / n, n * t);
    const interest = amount - p;
    setEstimate(Math.floor(amount));
  };

  const withdrawalDate = async () => {
    var date = new Date();
    date.setDate(date.getDate() + Number(targetDays));
    setWithdrawalDay(date);
  };

  useEffect(() => {
    withdrawalDate();
  }, []);

  useEffect(() => {
    targetOccurence();
  }, [categoryValue]);

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    setWithdrawalDay(result);
  }

  function addMonths(date, months) {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + months);
    setWithdrawalDay(newDate);
  }

  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getMonth() + 1), pad(d.getDate()), d.getFullYear()].join("/");
  }

  const token = useSelector(
    (state) => state?.user?.currentUser?.token?.access_token
  );

  const firstName = useSelector(
    (state) => state?.user?.currentUser?.data?.first_name
  );
  const lastName = useSelector(
    (state) => state?.user?.currentUser?.data?.last_name
  );
  const email = useSelector(
    (state) => state?.user?.currentUser?.data?.email_address
  );



  const getTargetSavingsName = async () => {
    const response = await userRequest.get(
      "api/v2/customer/miscellanous/all-categories",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setTargetName(response.data.data);
  };

  const getSavingFrequency = async () => {
    const response = await userRequest.get(
      "/api/v2/customer/miscellanous/all-frequencies",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setFrequency(response.data.data);
  };

  const getAllCards = async () => {
    const response = await userRequest.get("api/v2/customer/card/all", {
      headers: { Authorization: `Bearer ${token}` },
    });
    setCardData(response.data.data);
  };

  const getCardProvider = async () => {
    const response = await userRequest.get(
      "api/v2/customer/miscellanous/all-card-provider",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setCardProvider(response.data.data[0].id);
  };

  const getSeerbitRef = async () => {
    const response = await userRequest.post(
      "api/v2/customer/card/initiate",
      {
        provider_id: 8,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          source: "web",
          "x-api-key": "94684537",
        },
      }
    );
    // seerbitOptions.tranref = response?.data?.data?.transaction_reference
    setSeerbitRef(response?.data?.data?.transaction_reference);
    // handleSeerbit(response?.data?.data?.transaction_reference);
  };

  const addCardSeerbit = async () => {
    const response = await userRequest.post(
      "api/v2/customer/card/add",
      {
        transaction_id: seerbitTransId,
        provider: 8
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          source: "web",
          "x-api-key": "94684537",
        },
      }
    );
    setCardResponse(response.data)
    };

  

//  PAYSTACK

  const getPaystackRef = async () => {
    const response = await userRequest.post(
      "api/v2/customer/card/initiate",
      {
        provider_id: 14,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          source: "web",
          "x-api-key": "94684537",
        },
      }
    );
    setPaystackRef(response?.data?.data?.transaction_reference);
  };

 

  const payStackConfig = {
    reference: paystackRef,
    email: email,
    amount: 10000, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: 'pk_live_2d5e743be8c654d4ba722862c11765bde35e8c0f',
  };

      // you can call this function anything
      const handlePaystackSuccessAction = (reference) => {
        // Implementation for whatever you want to do with reference and after success call.
        if(reference.message === 'Approved') {
          setPaystackId(reference.trxref)
          togglePaystackModal();
          addCardPayStack();
          toggleSuccessCardModal();
        }
      };
  
      // you can call this function anything
      const handlePaystackCloseAction = () => {
        togglePaystackModal();
        // implementation for  whatever you want to do when the Paystack dialog closed.
      }

    const componentProps = {
      ...payStackConfig,
      text: 'Yes',
      onSuccess: (reference) => handlePaystackSuccessAction(reference),
      onClose: handlePaystackCloseAction,
  };
  
  const addCardPayStack = async () => {
    const response = await userRequest.post(
      "api/v2/customer/card/add",
      {
        transaction_id: payStackId,
        provider: 14
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          source: "web",
          "x-api-key": "94684537",
        },
      }
    );
    setPayStackResponse(response)
    };

    

  useEffect(() => {
    getTargetSavingsName();
    getSavingFrequency();
    getAllCards();
    getCardProvider();
  }, []);

  const TargetSavings = async () => {
    try {
      setLoading(true);
      const response = await userRequest.post(
        "api/v2/customer/investments/create",
        {
          purpose: purposeName,
          target_amount: amount,
          debit_amount: reccurAmount,
          start_date: startDate,
          end_date: withdrawalDay,
          category: categoryId,
          frequency: categoryValue,
          day_of_the_week: weekDay,
          day_of_the_month: monthDay,
          provider: cardProvider,
          card_reference: cardRef,
          signed_t_and_c: tAndC,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            source: "web",
            "x-api-key": "94684537",
          },
        }
      );
      setTargetData(response.data);
      setLoading(false);
    } catch (error) {
      setTargetData(error);
      setLoading(false);
    }
  };

  const showAddCardPage = () => {
    setFirstPage(false);
    setAddCardPage(true);
    setShowCards(false);
  };

  const showFirstPage = () => {
    setFirstPage(true);
    setAddCardPage(false);
    setShowCards(false);
  };

  const showCardPage = () => {
    setShowCards(true);
    setFirstPage(false);
    setAddCardPage(false);
  };

  const seerbitOptions = {
    public_key: "SBPUBK_XPRXSWP3VN4LSPDMQA9PAEZS82SUCBAQ",
    amount: 100,
    planId: "",
    customization: {
      theme: {
        border_color: "#000000",
        background_color: "#004C64",
        button_color: "#0084A0",
      },
      payment_method: ["card"],
      display_fee: true, // true
      display_type: "embed", //inline
      logo: "https://downloads.intercomcdn.com/i/o/370991/b1d2d385b5c9f9820864f1f6/4dd765d69f9fd9dedb4738d9888ed24a.png",
    },
  };

  // const handleSeerbit = (transaction_reference) => {
  //   transPresent(transaction_reference)
  // };

  // const transPresent = (transaction_reference) => {
  //   transaction_reference = seerbitRef
  //   console.log("transref", seerbitRef, transaction_reference )
  //   if  (seerbitRef) {
  //     console.log("transref", seerbitRef )
  //     console.log("true")
  //     document.getElementsByClassName("serbit1")[0].click();
  //     console.log("element", document.getElementsByClassName("seerbit-pay"))
      
  //   } else {
  //     setSeerbitRef(transaction_reference)
  //   }
  // }

  // console.log("seerbitButton")

  const closeSeerbit = (close) => {
    console.log(close);
  };
  const callbackSeerbit = (response) => {
    setSeerbitTransId(response.payments.paymentReference)
    addCardSeerbit();
};

  const checkProgressSeerbit = (progress) => {
    console.log(progress);
  };

  return (
    <Offcanvas
      show={showTarget}
      onHide={handleCloseTarget}
      placement="end"
      className="py-[20px] pl-[30px] flex flex-col w-[505px]"
    >
      <Offcanvas.Header closeButton className="w-[100%]">
        <Offcanvas.Title className="mt-[60px]">Target Savings </Offcanvas.Title>
        <Close
          onClick={() => {
            handleCloseTarget();
            showFirstPage();
          }}
          className="mr-[-200px]"
        />
      </Offcanvas.Header>
      <Offcanvas.Body>
        {firstPage && (
          <>
            <div className="flex flex-col items-center  justify-center">
              <button
                className="bg-[#4CC800] text-center text-white w-[301px] h-[46px] text-[20px] mt-[1rem]"
                onClick={() => showCardPage()}
              >
                Add new Card
              </button>
              <p className="my-[10px] ">OR</p>
            </div>
            <h1 className="text-center uppercase mb-[10px]">
              Start a new target savings
            </h1>
            <Input
              type="select"
              placeholder="Name your savings plan (Savings name)"
              className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              onChange={(e) => {
                const targetCategory = targetNames.find(
                  (x) => x.id === Number(e.target.value)
                );
                setCategoryId(targetCategory.id);
                setPurposeName(e.target.value);
              }}
            >
              <option value="">Name Your Vault</option>
              {targetNames?.map((names) => (
                <option value={names.id}>{names.name}</option>
              ))}
            </Input>
            <input
              type="text"
              placeholder="What is your target amount?"
              className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              onChange={(e) => setAmount(e.target.value)}
            />
            <input
              type="text"
              placeholder="How much do you want to save? (Recurring amount)"
              className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              onChange={(e) => setReccurAmount(e.target.value)}
            />

            <Input
              type="select"
              placeholder="How often do you want to save? (Frequency)"
              className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              onChange={(e) => {
                setCategoryValue(e.target.value);
                targetOccurence();
                // (categoryValue === "1" || categoryValue === 2) && addDays(startDate,Number(targetDays))
                // categoryValue === "3" && addMonths(startDate, Number(targetDays))
              }}
            >
              <option value="">How often do you want to save?</option>
              {frequency?.map((names) => (
                <option value={names.id}>{names.name}</option>
              ))}
            </Input>
            <Input
              type="date"
              placeholder="When do you want to start ? (Start Date)"
              className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              onChange={(e) => {
                setStartDate(e.target.value);
                targetOccurence();
                compoundInterest(
                  amount,
                  interestDays,
                  PennyTreeInterest,
                  targetDays
                );
                (categoryValue === "1" || categoryValue === "2") &&
                  addDays(e.target.value, Number(targetDays));
                categoryValue === "3" &&
                  addMonths(e.target.value, Number(targetDays));
              }}
            />
            {categoryValue === "2" && (
              <Input
                type="select"
                placeholder="Which day of the week do you want to save?"
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                onChange={(e) => setWeekDay(e.target.value)}
              >
                <option value="">
                  Which day of the week do you want to save?
                </option>
                <option value="1">Sunday</option>
                <option value="2">Monday</option>
                <option value="3">Tuesday</option>
                <option value="4">Wednesday</option>
                <option value="5">Thursday</option>
                <option value="7">Friday</option>
                <option value="7">Saturday</option>
              </Input>
            )}
            {categoryValue === "3" && (
              <Input
                type="select"
                placeholder="Which day of the week do you want to save?"
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                onChange={(e) => setMonthDay(e.target.value)}
              >
                <option value="">
                  Which day of the Month do you want to save?
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">18</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
              </Input>
            )}
            <Label for="withdraw" className="text-black">
              Withdrawal Date
            </Label>
            <Input
              id="withdraw"
              name="withdraw"
              type="text"
              placeholder="Withdrawal Date"
              className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              readOnly
              value={convertDate(withdrawalDay)}
            />
            <Label for="estimate" className="text-black">
              Estimated Amount
            </Label>
            <Input
              id="estimate"
              name="estimate"
              type="text"
              placeholder="Estimated Amount"
              className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              readOnly
              value={currencyFormat(estimate)}
            />
            <Input
              type="select"
              placeholder="Select Payment Method"
              className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              onChange={(e) => setCardRef(e.target.value)}
            >
              <option value="">Select Payment Method</option>
              {cardData?.map((names) => (
                <option value={names.card_reference}>
                  {names.card_number}
                  {"   " + names.card_type}
                </option>
              ))}
            </Input>
            <p className="mt-[129px] text-[12px] text-left">
              The Estimated Amount at Maturity will be{" "}
              <span className="text-[#4CC800]">{currencyFormat(estimate)}</span>{" "}
              and your withdrawal date will be on{" "}
              <span className="text-[#4CC800]">
                {convertDate(withdrawalDay)}
              </span>
              . By proceeding, I agree that I have read and understood the terms
              and conditions set forth in. <br />
              <span className="font-bold">Read more</span>.
            </p>
            <button
              className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[5rem]"
              onClick={() => {
                TargetSavings();
                toggleTargetModal();
              }}
            >
              {loading ? <Spinner color="light"> Loading...</Spinner> : "Start"}
            </button>
            <Modal
              isOpen={targetModal}
              toggle={toggleTargetModal}
              className="w-[400px] h-[500px] top-[20%] rounded-[14px]"
            >
              <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                {targetData?.status === "success" ? (
                  <img src="/assets/checkmark.png" alt="" />
                ) : (
                  <img src="/assets/Cancel.png" alt="" />
                )}
                <p className="mt-[20px] text-center">
                  {targetData?.response?.data?.message}
                </p>
                <button
                  onClick={() => {
                    toggleTargetModal();
                    closeTargetModal();
                  }}
                  className="w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px] text-white"
                >
                  Ok
                </button>
              </ModalBody>
            </Modal>
          </>
        )}
        {showCards && (
          <div>
            <FaChevronLeft
              onClick={() => showFirstPage()}
              className="mb-[20px]"
            />
            <p className="font-bold text-[24px] ml-[20px] mb-[20px]">Cards</p>
            <div className="flex flex-col items-center justify-center">
              {cardData?.map((cards) => (
                <div className="bg-[#333] w-[90%] h-[200px] rounded-[20px] relative flex  items-center justify-center mb-[20px]">
                  <img
                    src="/assets/atmchip.png"
                    alt=""
                    className="absolute w-[60px] h-[60px] top-[10px] left-[10px]"
                  />
                  {cards.card_type === "VERVE" ? (
                    <img
                      src="/assets/visa.png"
                      alt=""
                      className="absolute w-[80px] h-[20px] right-[20px] top-[40px]"
                    />
                  ) : (
                    <img
                      src="/assets/visa.png"
                      alt=""
                      className="absolute w-[80px] h-[60px] right-[10px] top-[10px]"
                    />
                  )}
                  <h3 className="text-white font-bold">{cards.card_number}</h3>
                  <p className="absolute left-[20px] bottom-[20px] text-white">
                    Gateway {cards.gateway_provider}
                  </p>
                </div>
              ))}
            </div>
            <div className="flex items-center justify-center">
              <button
                className="bg-[#4CC800] text-center text-white w-[301px] h-[46px] text-[20px] mt-[1rem]"
                onClick={() => toggleHundredCardModal()}
              >
                Add new Card
              </button>
            </div>
            <Modal
              isOpen={hundreCardModal}
              toggle={toggleHundredCardModal}
              className="w-[400px] h-[500px] top-[20%] rounded-[14px]"
            >
              <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                <p className="text-center py-[10px]">
                  ₦100 will be deducted from your account to add and activate
                  your card. The deducted amount (₦100) will be added to your
                  wallet for your use. Do you wish to continue?
                </p>
                <div className="flex gap-[20px] pt-[30px]">
                  <button
                    onClick={() => closeHundredCardModal()}
                    className="bg-[#FF0000] w-[70px] h-[30px] text-white font-semibold"
                  >
                    No
                  </button>
                  <button
                    onClick={() => showAddCardPage()}
                    className="bg-[#4CC800] w-[70px] h-[30px] text-white font-semibold"
                  >
                    Yes
                  </button>
                </div>
              </ModalBody>
            </Modal>
          </div>
        )}
        {addCardPage && (
          <>
            <FaChevronLeft
              onClick={() => showCardPage()}
              className="mb-[20px]"
            />
            <p className="font-bold text-[24px]">Add card</p>
            <p className="font-normal text-[18px]">
              Please select a payment gateway
            </p>
            <div className="flex flex-col items-start justify-start">
              <button
                className="flex items-start justify-start gap-[50px] px-[15px] py-[10px] bg-[#4CC800] w-[90%] mt-[20px]"
                onClick={() => {
                  getSeerbitRef();
                  toggleSeerbitModal();
                }}
              >
                <img
                  src="/assets/seerbit.png"
                  alt=""
                  className="w-[30px] h-[20px]"
                />
                <p className="font-bold text-white text-[18px]">Seerbit</p>
                
              </button>
              <Modal
              isOpen={successCardModal}
              toggle={toggleSuccessCardModal}
              className="w-[400px] h-[500px] top-[20%] rounded-[14px]"
            >
              <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                <p className="text-center py-[10px]">
                 Card Added Successfully!
                </p>
                <div className="flex gap-[20px] pt-[30px]">
                  <button
                    className="bg-[#4CC800] w-[70px] h-[30px] text-white font-semibold"
                onClick={() => {closeSuccessCardModal(); closeTargetModal();}}
                  >
                    
                    Ok
                  </button>
                </div>
              </ModalBody>
            </Modal>
              <button
                className="flex items-start justify-start gap-[50px] px-[15px] py-[10px] bg-[#4CC800] w-[90%] mt-[20px]"
                onClick={() => {getPaystackRef(); togglePaystackModal();}}
              >
                <img
                  src="/assets/Paystack).png"
                  alt=""
                  className="w-[30px] h-[20px]"
                />
                <p className="font-bold text-white text-[18px]">Paystack</p>
              </button>
               <Modal
              isOpen={paystackModal}
              toggle={togglePaystackModal}
              className="w-[400px] h-[500px] top-[20%] rounded-[14px]"
            >
              <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                <p className="text-center py-[10px]">
                 Proceed with Paystack
                </p>
                <div className="flex gap-[20px] pt-[30px]">
                  <button
                    onClick={() => closePayStackModal()}
                    className="bg-[#FF0000] w-[70px] h-[30px] text-white font-semibold"
                  >
                    No
                  </button>
                  <button
                    className="bg-[#4CC800] w-[70px] h-[30px] text-white font-semibold"
                  >
                  <PaystackButton {...componentProps} />
                  </button>
                </div>
              </ModalBody>
            </Modal>
            </div>
          </>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default TargetSavingsCanvas;
