import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, FormGroup, Label, Input, Spinner } from "reactstrap";
import { CreateAccountOtp, OtpApi } from "../../redux/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import { FaArrowLeft } from "react-icons/fa";
import { LogOut } from "../../redux/userRedux";
import { signUp, userRequest } from "../../requestMethod";

function CreateOtp() {
  const [loading, setLoading] = useState(false);
  const [otpData, setOtpData] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const email = location?.state?.email;
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const [errorState, setErrorState] = useState(false)
    const [otpErrMessage, setOtpErrMessage] = useState(null)
    const [modal,setModal] = useState(false);
    const toggle = () => {
      setModal(!modal);
    }

   

    const user = useSelector((state) => state.user)
    const {
      createOtpFetching,
      createOtpSuccess,
      createOtpError,
      
    } = user

  const otpSuccess = async (e) => {
    try {
      setLoading(true);
      const response = await signUp.post(
        "api/v2/customer/sign-up/complete",
        {
          email_address: email,
          otp: otp,
        },
        {
          headers: {
            source: "web",
            "x-api-key": "94684537",
          },
        }
      );
      setOtpData(response?.data);
      setLoading(false);
    } catch (error) {
      setOtpData(error?.response?.data?.message);
      setLoading(false);
    }
  };

  console.log(otpData)

 

  const submitHandler = async(e) => {
    e.preventDefault();
    try {
      CreateAccountOtp(dispatch,{email_address: email, otp})
    } catch (error) {
      setErrorState(true)
      setOtpErrMessage(error.response.data.message)
      toggle();
    }
   
  
}

console.log(createOtpSuccess)


useEffect(() => {
  if(otpData?.status === "success") {
    navigate("/", { replace: true });
  }
  }, [otpData?.status, navigate])

  
  return (
    <div className="flex justify-center items-center bg-white relative h-[100vh] w-[100%]">
      <img
        src="/assets/login-design.png"
        alt=""
        className="absolute top-0 left-0 md:w-[auto] w-[300px]"
      />
      <Link to="/create-account">
        <div className="absolute top-[150px] left-[50px] flex gap-[15px] items-center justify-center cursor-pointer">
          <FaArrowLeft />
          <p className="text-[16px]">Go Back</p>
        </div>
      </Link>
      <div className="mt-[0px]text-white flex flex-col items-center justify-center">
        <img
          src="/assets/blacktext.png"
          alt=""
          className="md:w-[200px] w-[150px]"
        />
        <p className="text-black text-[16px] text-center mt-[20px]">
          Kindly check your mail for OTP
        </p>
        <div className="md:w-[450px] w-[350px] bg-slate-100 py-[40px] rounded-[30px] flex flex-col items-center justify-center mt-[20px]">
          <FormGroup className="mt-[10px]">
            <Label for="email" className="text-black">
              Enter Otp
            </Label>
            <Input
              id="otp"
              name="otp"
              placeholder="Enter OTP"
              type="text"
              className="md:w-[400px] w-[320px] rounded-[20px] pl-[20px] bg-white"
              onChange={(e) => setOtp(e.target.value)}
            />
          </FormGroup>
          <div className="flex justify-center">
            <button
              className="text-white bg-[#4CC800] px-[60px] py-[10px] rounded-[136px] mt-[20px] w-[200px]"
              onClick={() => otpSuccess()}
            >
              {createOtpFetching ? <Spinner color="light" size="sm" /> : "Confirm"}
            </button>
          </div>
        </div>
      </div>
      <img
        src="/assets/login-design2.png"
        alt=""
        className="absolute bottom-0 right-0 w-[300px] md:w-[auto]"
      />
    </div>
  );
}

export default CreateOtp;
