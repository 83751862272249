import React, { useEffect, useState } from "react";
import { FormGroup, Input, Label, Modal, ModalBody, Spinner } from "reactstrap";
import { roleRequest, userRequest } from "../../../requestMethod";
import { useSelector } from "react-redux";
import { FaRegEye, FaRegEyeSlash, FaTrashAlt } from "react-icons/fa";
import {ImBlocked, ImSwitch} from 'react-icons/im'

function Owner({ merchant, merchantId, handleShowUser,storedRoles }) {
  const [addCusData, setAddCusData] = useState(null);
  const [remCusData, setRemCusData] = useState(null);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [apiComp, setApiComp] = useState(false);
  const [apiComp2, setApiComp2] = useState(false);
  const [modal, setModal] = useState(false);
  const [confirmModal,setConfirmModal] = useState(false)
  const toggleConfirmModal = () => {
    setConfirmModal(true)
  }
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [customerid, setCustomerid] = useState("");
  const [permissions, setPermissions] = useState(null);
  const [pin,setPin] = useState("")
  const [reactivateModal,setReactivateModal] = useState(false)
    const [reactivateModal2,setReactivateModal2] = useState(false)
    const [deactivateModal,setDeactivateModal] = useState(false)
    const [deactivateModal2,setDeactivateModal2] = useState(false)
    const [passwordType, setPasswordType] = useState("password");
  const toggle = () => {
    setModal(!modal);
  };
  const toggle2 = () => {
    setModal2(!modal2);
  };

  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const toggleReactivate = () => {
    setReactivateModal(!reactivateModal);
  };
  const toggleDeactivate = () => {
    setDeactivateModal(!deactivateModal);
  };

  const toggleReactivate2 = () => {
    setReactivateModal2(!reactivateModal2);
    setApiComp2(false);
  };

  const toggleDeactivate2 = () => {
    setDeactivateModal2(!deactivateModal2);
    setApiComp2(false);
  };

  const toggle3 = () => {
    setModal3(!modal3);
  };
  const secondToggle = () => {
    setModal(!modal);
    setApiComp2(false);
  };

  const secondToggle2 = () => {
    setModal2(!modal2);
    setApiComp2(false);
  };

  const secondToggle3 = () => {
    setModal3(!modal3);
    setApiComp2(false);
  };
  const token = useSelector(
    (state) => state?.user?.currentUser?.token?.access_token
  );

  const first_name = useSelector(
    (state) => state.user.currentUser.data.first_name
  );

  const filtered = storedRoles.filter(role => {
    return role.role_name === 'Owner';
  });
  const addOwnerToBus = async () => {
    try {
      setLoading(true);
      const response = await roleRequest.post(
        "api/v2/customer/customer-to-business/add",
        {
          email_address: email,
          first_name: first_name,
          role_id: 6,
          business_id: merchantId,
          referal_code: "",
          pin: pin
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setLoading(false);
      setAddCusData(response?.data?.message);
      setLoading(false);
      setApiComp(true);
      toggle();
    } catch (error) {
      setAddCusData(error?.response?.data?.message);
      setLoading(false);
      setApiComp(true);
      toggle();
    }
  };

  const removeOwnerfromBus = async () => {
    try {
      setLoading2(true);
      const response = await roleRequest.get(
        `/api/v2/customer/business/block/${customerid}/${merchantId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setRemCusData(response?.data?.message);
      setLoading2(false);
      setApiComp2(true);
      toggle3();
    } catch (error) {
      setRemCusData(error?.response?.data?.message);
      setLoading2(false);
      setApiComp2(true);
      toggle3();
    }
  };

  const reactivateUser = async () => {
    try {
      setLoading2(true);
      const response = await roleRequest.get(
        `/api/v2/customer/business/activate/${customerid}/${merchantId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setRemCusData(response?.data?.message);
      setLoading2(false);
      setApiComp2(true);
      toggle3();
    } catch (error) {
      setRemCusData(error?.response?.data?.message);
      setLoading2(false);
      setApiComp2(true);
      toggle3();
    }
  };

  const deactivateUser = async () => {
    try {
      setLoading2(true);
      const response = await roleRequest.get(
        `/api/v2/customer/business/deactivate/${customerid}/${merchantId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setRemCusData(response?.data?.message);
      setLoading2(false);
      setApiComp2(true);
      toggle3();
    } catch (error) {
      setRemCusData(error?.response?.data?.message);
      setLoading2(false);
      setApiComp2(true);
      toggle3();
    }
  };

  const getOwnerPermissions = async () => {
    const response = await roleRequest.get(
      "api/v2/customer/utility/roles/permission/all?RoleId=6",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setPermissions(response.data.data[0].permissions);
  };

  useEffect(() => {
    getOwnerPermissions();
  }, []);

  let newArray = permissions?.map((res) => {
    return { name: res.name, is_active: res.is_active };
  });
  

  return (
    <div className="w-[95%] min-h-[820px] bg-[#fff] rounded-[14px] px-[41px] py-[34px]">
      <div className="flex flex-col items-start justify-start ">
        <p className="font-semibold text-[16px]">Role Details</p>
      </div>
      <div className="flex items-start justify-center gap-[71px]">
        <div>
          <div className="mt-[28px]">
            <div className="w-[100%] h-[2px] bg-[#b2b6bb] mt-[32px] mb-[20px]"></div>
            <Label for="owner">Name of Role</Label>
            <div className="w-[465px] h-[52px] bg-[#F5F6FA] rounded-[29px] flex items-center mt-[10px]">
              <p className="ml-[20px] text-[#535456] text-[14px]">Owner</p>
            </div>
          </div>
          <div className="mt-[28px]">
            <Label for="role">Role description</Label>
            <div className="w-[465px] h-[85px] bg-[#F5F6FA] rounded-[14px] flex items-center mt-[10px]">
              <p className="ml-[20px] text-[#535456] text-[14px]">
                This role is best suited for the business owner or an executive
                team member that wil require full admin access.
              </p>
            </div>
          </div>
          <div className="w-[100%] h-[2px] bg-[#b2b6bb] mt-[32px] mb-[25px]"></div>
          <div>
            <p className="text-[16px] text-[#1B1B1B] font-semibold mb-[10px]">
              Team members with this role
            </p>
            <div className="flex items-center justify-start gap-[13px] w-[408px] h-[52px] rounded-[29px] border-solid border-[2px] border-[#B2B6BB] pl-[9px]">
              {filtered?.map((role) => (
                <div className="flex items-center justify-center gap-[10px]">
                  <span className="w-[34px] h-[34px] rounded-[50%] text-[#fff] font-semibold text-[12px] bg-[#E792EE] flex items-center justify-center">
                    {role.first_name?.charAt(0)}
                    {role.last_name?.charAt(0)}
                  </span>
                  <p className="text-[14px]">
                    {role.first_name + " " + role.last_name}
                  </p>
                </div>
              ))}
              </div>
            {/* <div className="flex items-center justify-start gap-[13px] w-[195px] h-[52px] rounded-[29px] border-solid border-[2px] border-[#B2B6BB] pl-[9px]">
              <span className="w-[34px] h-[34px] rounded-[50%] text-[#fff] font-semibold text-[12px] bg-[#FD7900] flex items-center justify-center">
                {merchant.business_name.substring(0, 2)}
              </span>
              <p className="w-[100px] overflow-hidden">
                {merchant.business_name}
              </p>
            </div> */}
          </div>
          <div className="w-[100%] h-[2px] bg-[#b2b6bb] mt-[32px] mb-[25px]"></div>
          <div>
            <p className="text-[16px] text-[#1B1B1B] font-semibold mb-[10px]">
              Invite a new team member to this role
            </p>
            <div className="relative">
              <Input
                className="w-[463px] h-[52px] rounded-[29px]"
                placeholder="email address"
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                className="text-[#fff] border-solid border-2 rounded-[30px] px-[14px] py-[10px] border-[#4cc800] bg-[#4cc800] w-[153px] h-[44px] absolute right-[6px] top-[4px]"
                onClick={() => toggleConfirmModal()}
              >
                 Send invite
              </button>
              <Modal
                isOpen={confirmModal}
                toggle={toggleConfirmModal}
                className="w-[400px] h-[300px] top-[20%] rounded-[14px] mt-[20px]"
              >
                <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                <Label>Kindly Input your Pin</Label>
                  <input
                    type={passwordType}
                    placeholder="Pin"
                    className="w-[350px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                    onChange={(e) => setPin(e.target.value)}
                  />
                  <button
                    onClick={togglePassword}
                    className="absolute right-[50px] top-[67px]"
                  >
                    {passwordType === "password" ? (
                      <FaRegEye className="text-[20px]" />
                    ) : (
                      <FaRegEyeSlash className="text-[20px]" />
                    )}
                  </button>
                
                  <button
                    onClick={() => {
                      addOwnerToBus();
                      toggleConfirmModal();
                    }}
                    className="w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px] text-white"
                  >
                    {loading ? <Spinner size='sm' color="light"/> : "Ok"}
                  </button>
                </ModalBody>
              </Modal>
              <Modal
                isOpen={modal}
                toggle={toggle}
                className="w-[400px] h-[500px] top-[20%] rounded-[14px]"
              >
                <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                  {apiComp && (
                    <p className="text-[black] test-[10px] text-center w-[350px] mb-[10px]">
                      {addCusData}
                    </p>
                  )}
                  <button
                    onClick={() => {
                      toggle();
                      secondToggle();
                      handleShowUser();
                    }}
                    className="w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px] text-white"
                  >
                    Ok
                  </button>
                </ModalBody>
              </Modal>
            </div>
          </div>
          <div className="mt-[21px] mb-[30px]">
            <p className="text-[16px] text-[#1B1B1B] font-semibold mb-[10px]">
              Remove a team member
            </p>
            {/* <div className="relative">
                    <Input
                      className="w-[463px] h-[52px] rounded-[29px]"
                      placeholder="email address"
                      onChange={(e) => setCustomerid(e.target.value)}
                    />
                    <button className="text-[#fff] border-solid border-2 rounded-[30px] px-[14px] py-[10px] border-[#F00] bg-[#F00] w-[153px] h-[44px] absolute right-[6px] top-[4px]" onClick={() => removeOwnerfromBus()}>
                    {loading2 ? <Spinner size="sm" color="light"/> : "Send invite"}
                    </button>
                    <Modal isOpen={modal2} toggle={toggle2} className='w-[400px] h-[500px] top-[20%] rounded-[14px]'>
              <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                  {apiComp2 && <p className="text-[black] test-[10px] text-center w-[350px] mb-[10px]">{remCusData}</p>}
                  <button onClick={() => {toggle2();secondToggle2();handleShowUser()}} className='w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px] text-white'>Ok</button>
              </ModalBody>
            </Modal>   
                  </div> */}
            {filtered.map((role) => (
              <div
                className="flex flex-col items-center justify-start gap-[2px] w-[463px] bg-[#fff] h-auto p-[10px]"
                key={role.customer_id}
              >
                <div className="flex items-center justify-between w-[463px] h-[30px]">
                  <div className="flex items-center justify-center gap-[15px]">
                    <span className="w-[34px] h-[34px] rounded-[50%] text-[#fff] font-semibold text-[12px] bg-[#FD7900] flex items-center justify-center">
                      {role.first_name?.charAt(0)}
                      {role.last_name?.charAt(0)}
                    </span>
                    <p className="font-semibold w-[150px]">
                      {role.first_name + " " + role.last_name}
                    </p>
                  </div>
                  <div>
                  {role.status === "BLOCKED" ? <p className="font-semibold text-white bg-red-500 rounded-[14px] w-[100px] text-center py-[2px]">blocked</p>: role.status === "ACTIVE" || role.status === null ? <p className="font-semibold text-white bg-green-500 rounded-[14px] w-[100px] text-center py-[2px]">Active</p>  : <p className="font-semibold text-white bg-yellow-500 rounded-[14px] w-[100px] text-center py-[2px]">Inactive</p>}
                  </div>
                  <div className="flex items-center justify-center gap-[10px]">
                    {role.status === "ACTIVE" ?
                      <span
                      value={role.customer_id}
                      key={role.customer_id}
                      onClick={() => {
                        setCustomerid(role.customer_id);
                        toggleDeactivate();
                      }}
                    >
                      <ImBlocked className="fill-red-500"/>
                    </span> : 
                    role.status === "BLOCKED" || role.status === "DEACTIVATED" ?
                    <span
                    value={role.customer_id}
                    key={role.customer_id}
                    onClick={() => {
                      setCustomerid(role.customer_id);
                      toggleReactivate();
                    }}
                  >
                    <ImSwitch className="fill-[#4cc800]"/>
                  </span> : <ImSwitch className="fill-white disabled"/>
                  }
                  <span
                    value={role.customer_id}
                    key={role.customer_id}
                    onClick={() => {
                      setCustomerid(role.customer_id);
                      toggle2();
                    }}
                  >
                    <FaTrashAlt />
                  </span>
                  </div>
                  <Modal
                    isOpen={reactivateModal}
                    toggle={toggleReactivate}
                    className="w-[400px] h-[500px] top-[20%] rounded-[14px]"
                  >
                    <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                      <p className="text-[black] test-[10px] text-center font-bold w-[350px] mb-[0px]">
                        Are you sure you want to activate this User?
                      </p>
                      <FormGroup className="relative">
                  <Label>Kindly Input your Pin</Label>
                  <input
                    type={passwordType}
                    placeholder="Pin"
                    className="w-[350px] h-[32px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                    onChange={(e) => setPin(e.target.value)}
                  />
                  <button
                    onClick={togglePassword}
                    className="absolute right-[50px] top-[20px]"
                  >
                    {passwordType === "password" ? (
                      <FaRegEye className="text-[25px" />
                    ) : (
                      <FaRegEyeSlash className="text-[25px" />
                    )}
                  </button>
                </FormGroup>
                      <button
                        onClick={() => {
                          reactivateUser();
                          toggleReactivate2();
                        }}
                        className="w-[180px] h-[53px] bg-[#4cc800] mt-[46px] rounded-[7px] text-white"
                      >
                        Activate
                      </button>
                    </ModalBody>
                  </Modal>
                  <Modal
                    isOpen={deactivateModal}
                    toggle={toggleDeactivate}
                    className="w-[400px] h-[500px] top-[20%] rounded-[14px]"
                  >
                    <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                      <p className="text-[black] test-[10px] text-center font-bold w-[350px] mb-[0px]">
                        Are you sure you want to deactivate this User?
                      </p>

                      <button
                        onClick={() => {
                          deactivateUser();
                          toggleDeactivate2();
                        }}
                        className="w-[180px] h-[53px] bg-red-500 mt-[46px] rounded-[7px] text-white"
                      >
                        Deactivate
                      </button>
                    </ModalBody>
                  </Modal>
                  <Modal
                    isOpen={modal2}
                    toggle={toggle2}
                    className="w-[400px] h-[500px] top-[20%] rounded-[14px]"
                  >
                    <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                      <p className="text-[black] test-[10px] text-center font-bold w-[350px] mb-[0px]">
                        Are you sure you want to remove this User?
                      </p>

                      <button
                        onClick={() => {
                          removeOwnerfromBus();
                        }}
                        className="w-[180px] h-[53px] bg-red-500 mt-[46px] rounded-[7px] text-white"
                      >
                        Remove
                      </button>
                    </ModalBody>
                  </Modal>
                  <Modal isOpen={modal3} toggle={toggle3} className='w-[400px] h-[500px] top-[20%] rounded-[14px]'>
              <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                  {apiComp2 && <p className="text-[black] test-[10px] text-center w-[350px]">{remCusData}</p>}
                  <button onClick={() => {toggle3();secondToggle3();handleShowUser()}} className='w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px] text-white'>Ok</button>
              </ModalBody>
            </Modal>  
                </div>
              </div>
            ))}
          </div> 
        </div>
        <div className="w-[462px] h-[645px] border-[#B2B6BB] border-solid border-[1px] rounded-[14px] mt-[30px] px-[17px] py-[20px]">
          <p className="font-semibold text-[16px]">Role Permissions</p>
          <div className="w-[100%] h-[2px] bg-[#b2b6bb] mt-[16px] mb-[16px]"></div>
          <p className="text-[12px] text-[#B9BDC1] font-medium">
            See the the list of availale permissions for this role
          </p>
          <div className="mt-[17px]">
            {newArray?.map((news) => news?.is_active && (
              <div className="flex items-center justify-start gap-[13px] mb-[12px]" key={news.id}>
                <div className="w-[11px] h-[11px] rounded-[2px] bg-[#4CC800]"></div>
                <p className="text-[##535456] text-[12px]">{news?.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Owner;
