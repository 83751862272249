import React,{useState} from 'react';
import "../dashHome.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Close } from "@mui/icons-material";
import { NumericFormat } from 'react-number-format';
import TargetSavingsCanvas from './TargetSavingsCanvas';

function TargetSavings({target_savings}) {
    const [showTarget, setShowTarget] = useState(false);
    const handleCloseTarget = () => setShowTarget(false);
     const handleShowTarget = () => setShowTarget(true);
     return (
       <div className="w-[216px] h-[176px] rounded-[12px] green-gradient flex flex-col justify-center pl-[19px]">
         <p className="text-[15px] text-black mb-[22px]">Target Savigs</p>
         <p className="text-[24px] text-black mb-[22px]">{<NumericFormat value={target_savings} disabled thousandSeparator="," prefix='₦' className='text-black bg-transparent'/>|| 0.00}  </p>
         <div>
           <button  onClick={handleShowTarget} className='w-[129px] h-[31px] rounded-[32px] bg-white border-none outline-none'>
             <p className="text-[#070707] text-[12px]">Save</p>
           </button>
   
          <TargetSavingsCanvas showTarget={showTarget} handleCloseTarget={handleCloseTarget}/>
         </div>
       </div>
     );
   }
export default TargetSavings
