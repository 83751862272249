import React, { useEffect, useState, useRef } from 'react'
import { FaChevronRight } from 'react-icons/fa'
import { jsPDF } from 'jspdf';
import Topbar from '../../components/Topbar/Topbar'
import Offcanvas from "react-bootstrap/Offcanvas";
import { Close } from "@mui/icons-material";
import { userRequest } from '../../requestMethod';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { Input, Modal, ModalBody } from 'reactstrap';


function MerchantTransactions({userType,setUserType, merchantId}) {
  const [trans, setTrans] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [statementData, setStatementData] = useState(null);
  const [actionType, setActionType] = useState(null);
  const [accountType, setAccountType] = useState(null);
  const [showTrans, setShowTrans] = useState(false);
  const handleTransClose = () => setShowTrans(false);
   const handleTransShow = () => {
    setShowTrans(true);
    setAnchorEl(null);
   }
   const pdfRef = useRef(null);
   const [showStatement, setShowStatement] = useState(false);
  const handleStatementClose = () => setShowStatement(false);
  const handleStatementOpen = () => setShowStatement(true);
   const [fromDate, setFromDate] = useState(null);
  const [transDetail, setTransDetail] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [transId, setTransId] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = () => {
    
    const content = pdfRef.current;
    

    const doc = new jsPDF("l","pt",'letter')
    doc.html(content, {
        callback: function (doc) {
            doc.save('receipts.pdf');
        },
    });
};

  
  const generateStatement = async () => {
    const response = await userRequest.post(
      "api/v2/customer/transaction/download-statements",
      {
        from_date: fromDate,
        to_date: endDate,
        action: actionType,
        source: accountType,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          source: "web",
          "x-api-key": "94684537",
        },
      }
    );
    setStatementData(response.data);
  };


  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const token = useSelector(
    (state) => state?.user?.currentUser?.token?.access_token
  );

  const getTransactions = async () => {
    const response = await userRequest.get(`api/v2/customer/transaction/${merchantId}/all?customer_type=merchant`,{
      headers: {"Authorization" : `Bearer ${token}`}
    });
    setTrans(response.data.data.items)
}

useEffect(() => {
  getTransactions();
},[]); 

const getTransactionsDetails = async () => {
  const response = await userRequest.get(
    `/api/v2/customer/transaction/${transId}/details`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  setTransDetail(response.data.data.transaction);
};



useEffect(() => {
  getTransactionsDetails();
}, [transId]);



  return (
    <div style={{ flex: 4}}>
    <Topbar setUserType={setUserType} userType={userType}/>
    <div className='bg-[#F0F0F0] w-[100%] min-h-[100vh] px-[40px] pt-[30px]'>
      <h1 className='text-[24px] font-bold pb-[15px] mb-[26px]'>Transactions</h1>
      <div className='w-[100%] h-[66px] bg-white rounded-tl-[14px] rounded-tr-[14px] flex items-center justify-end'>
      <button className="bg-[#4CC800] text-center text-white w-[219px] h-[45px] rounded-[30px] text-[14px] mr-[30px]" onClick={() => {
              handleStatementOpen();
            }}>Get Account Statement
            </button>
      </div>
      <Offcanvas
            show={showStatement}
            onHide={handleStatementClose}
            placement="end"
            className="py-[20px] pl-[30px] flex flex-col w-[505px]"
          >
            <Offcanvas.Header closeButton className="w-[100%]">
              <Offcanvas.Title className="mt-[60px]">
                Get Account Statement
              </Offcanvas.Title>
              <Close onClick={handleStatementClose} className="mr-[-200px]" />
            </Offcanvas.Header>
            <Offcanvas.Body>
              <label htmlFor="fromDate">Start Date</label>
              <input
                type="date"
                placeholder="Name"
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[21px]"
                name="fromDate"
                onChange={(e) => setFromDate(e.target.value)}
                required
              />
              <label htmlFor="EndDate">End Date</label>
              <input
                type="date"
                placeholder="Phone Number"
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[21px]"
                onChange={(e) => setEndDate(e.target.value)}
                name="endDate"
                required
              />
              <Input
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] border-none outline-none] mb-[21px]"
                type="select"
                onChange={(e) => setAccountType(e.target.value)}
                placeholder='Account Type'
                required
              >
                <option value=" ">Account Type</option>
                <option value="merchant">Merchant</option>
              </Input>
              <Input
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] border-none outline-none]"
                type="select"
                onChange={(e) => setActionType(e.target.value)}
                required
              >
                <option value=" ">Transaction Type</option>
                <option value="CREDIT">All</option>
                <option value="CREDIT">Credit</option>
                <option value="DEBIT">Debit</option>
                <option value="POS">POS</option>
              </Input>
              <button
                className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[1rem]"
                onClick={() => {
                  generateStatement();
                  toggle();
                }}
              >
                Generate
              </button>
              <Modal
                isOpen={modal}
                toggle={toggle}
                className="w-[400px] h-[500px] top-[20%] rounded-[14px]"
              >
                <ModalBody className="flex flex-col items-center justify-center py-[20px]">
                  {statementData?.status === "success" ? (
                    <img src="/assets/checkmark.png" alt="" />
                  ) : (
                    <img src="/assets/Cancel.png" alt="" />
                  )}
                  <p className="mt-[20px] text-center">
                    {statementData?.message}
                  </p>
                  <button
                    onClick={() => {
                      toggle();
                      handleStatementClose();
                    }}
                    className="w-[180px] h-[53px] bg-[#4CC800] mt-[46px] rounded-[7px]"
                  >
                    Continue
                  </button>
                </ModalBody>
              </Modal>
            </Offcanvas.Body>
          </Offcanvas>
      <div className='w-[100%]'>
<table className='w-[100%] px-[10px]'>
<thead>
  <tr className='flex items-center justify-center gap-[50px] h-[52px]'>
    <th className='text-[12px] w-[50px]'></th>
    <th className='text-[12px] w-[150px]'>TRANSACTION TYPE</th>
    <th className='text-[12px] w-[150px]'>TOTAL AMOUNT</th>
    <th className='text-[12px] w-[150px]'>DIRECTION</th>
    <th className='text-[12px] w-[150px]'>TRANSACTION DATE</th>
    <th className='text-[12px] w-[130px]'>STATUS</th>
    <th className='text-[12px] w-[10px]'></th>
  </tr>
</thead>
<tbody>
{trans?.map((transaction) => (
                <tr className="flex items-center justify-center gap-[50px] h-[60px] bg-white px-[15px] border-b-[1px]" key={transaction.id}>
                <td className="w-[45px] h-[45px] rounded-[50%] bg-[#D1F1BD] flex items-center justify-center">
                  {transaction.direction === "Debit" ? <img src="/assets/debit.png" alt="" /> : <img src="/assets/credit.png" alt="" />}
                </td>
                <td className="text-[12px] w-[150px]">{transaction.narration}</td>
                <td className="text-[12px] w-[150px]"><p><NumericFormat value={transaction.transaction_amount}  thousandSeparator="," prefix='₦' disabled className='text-black bg-transparent'/></p></td>
                <td className="text-[12px] w-[150px]">{transaction.direction}</td>
                <td className="text-[12px] w-[150px]">{new Date (transaction.date_created).toLocaleDateString()}  {new Date (transaction.date_created).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</td>
                <td className={transaction.status === "Successful" ? "text-[12px] text-[#4CC800] px-[20px] py-[8px] bg-[#4CC800]/[0.1] w-[130px] rounded-[33px] text-center" : transaction.status === "Reversed" ? "text-[12px] text-[#333] px-[20px] py-[8px] bg-[#333]/[0.1] w-[130px] rounded-[33px] text-center" : transaction.status === "Processing" ? "text-[12px] text-[#FFC025] px-[20px] py-[8px] bg-[#FFC025]/[0.1] w-[130px] rounded-[33px] text-center" : "text-[12px] text-red-600 px-[20px] py-[8px] bg-red-100 w-[130px] rounded-[33px] text-center"}>
                  {transaction.status}
                </td>
                <td  className="text-[12px] w-[10px] relative"
                    value={transaction.id}
                    onClick={(e) => setTransId(transaction.id)}
                  >
                    <FaChevronRight onClick={handleTransShow} />
                    
                    <Offcanvas
                      show={showTrans}
                      onHide={handleTransClose}
                      placement="end"
                      backdrop={true}
                      backdropClassName="opacity-[.01]"
                      className="py-[50px] pl-[30px] flex flex-col w-[505px] h-auto"
                      scroll={true}
                    >
                      <Offcanvas.Header closeButton className="w-[100%]">
                      <Offcanvas.Title className="mt-[60px]">
                          View Transaction
                        </Offcanvas.Title>
                        <Close
                          onClick={handleTransClose}
                          className="mr-[-200px]"
                        />
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                      <div className="flex flex-col items-center justify-center w-[100%] h-[] mx-auto" ref={pdfRef} style={{alignSelf:"center"}}>
                          <h1 className="font-bold text-[18px]">
                            Transaction Receipt
                          </h1>
                          <div className="w-[100%] h-[110px] bg-gray-300 mt-[20px] rounded-[30px] flex items-center justify-center relative">
                            <div className="w-[100%] bg-gray-100 h-[60px] rounded-[30px] flex items-center justify-center absolute top-0">
                              <p className="font-semibold">
                                {transDetail?.transaction?.narration}
                              </p>
                            </div>
                            <div>
                              <p className="mt-[50px] font-bold">
                                {transDetail?.transaction?.currency + " " + transDetail?.transaction?.transaction_amount}
                              </p>
                            </div>
                          </div>
                          <div className="w-[100%] h-[300px] bg-gray-300 mt-[20px] rounded-[30px] flex flex-col items-start justify-start px-[30px] py-[40px]">
                            <div className="w-[100%] flex justify-between items-center mb-[5px]">
                              <p className="text-[14px] text-[#070707] font-bold">
                                Date
                              </p>
                              <h4 className="text-[#535456] text-[14px]">
                                {new Date(
                                  transDetail?.transaction?.date_created
                                ).toLocaleDateString()}{" "}
                              </h4>
                            </div>
                            <div className="w-[100%] flex justify-between items-start mb-[5px]">
                              <p className="text-[14px] text-[#070707] font-bold">
                                Reference
                              </p>
                              <h4 className="text-[#535456] text-[12px] w-[250px] text-right">
                                {transDetail?.transaction?.transaction_reference}
                              </h4>
                            </div>
                            <div className="w-[100%] flex justify-between items-start mb-[5px]">
                              <p className="text-[14px] text-[#070707] font-bold">
                                Amount
                              </p>
                              <h4 className="text-[#535456] text-[12px] w-[250px] text-right">
                              <p>{transDetail?.transaction?.transaction_amount}</p>
                              </h4>
                            </div>
                            <div className="w-[100%] flex justify-between items-center mb-[5px]">
                              <p className="text-[14px] text-[#070707] font-bold">
                                Sender's Name
                              </p>
                              <h4 className="text-[#535456] text-[14px]">
                                {transDetail?.transaction?.customer_email}
                              </h4>
                            </div>
                            <div className="w-[100%] flex justify-between items-center mb-[5px]">
                              <p className="text-[14px] text-[#070707] font-bold">
                                Recipient's Name
                              </p>
                              <h4 className="text-[#535456] text-[14px]">
                                {transDetail?.other_details?.recipient_name || ""}
                              </h4>
                            </div>
                            <div className="w-[100%] flex justify-between items-center mb-[5px]">
                              <p className="text-[14px] text-[#070707] font-bold">
                                Recipient's Number
                              </p>
                              <h4 className="text-[#535456] text-[14px]">
                                {transDetail?.other_details?.recipient_number ||
                                  ""}
                              </h4>
                            </div>
                            <div className="w-[100%] flex justify-between items-center mb-[5px]">
                              <p className="text-[14px] text-[#070707] font-bold">
                                Payment Status
                              </p>
                              <h4 className="text-[#535456] text-[14px]">
                                {transDetail?.transaction?.status ||
                                  transDetail?.other_details?.payment_status}
                              </h4>
                            </div>
                            <div className="w-[100%] flex justify-between items-center mb-[5px]">
                              <p className="text-[14px] text-[#070707] font-bold w-[250px]">
                                Remark
                              </p>
                              <h4 className="text-[#535456] text-[14px]">
                                {transDetail?.other_details?.remarks ||
                                  transDetail?.transaction?.response_message}
                              </h4>
                            </div>
                            <div className="w-[100%] flex justify-between items-center mb-[5px]">
                              <p className="text-[14px] text-[#070707] font-bold">
                                Transaction Type
                              </p>
                              <h4 className="text-[#535456] text-[14px]">
                                {transDetail?.transaction?.direction}
                              </h4>
                            </div>
                            
                          </div>
                          <div className="flex items-center justify-center w-[200px] mt-[2px]">
                              <img src="/assets/powered.png" alt="" />
                            </div>
                        </div>

                        <div className="flex items-center justify-center mt-[20px]">
                          <button
                            className="bg-[#4CC800] text-[#fff] px-[20px] py-[10px] rounded-[15px]"
                            onClick={handleDownload}
                          >
                            Download
                          </button>
                        </div>
                      </Offcanvas.Body>
                    </Offcanvas>
              
                  
                </td>
              </tr>
              ))
              }
</tbody>
</table>
      </div>
    </div>
  </div>
  )
}

export default MerchantTransactions
