import { publicRequest, userRequest, signUp } from "../requestMethod";
import { LoginFailure, loginStart, loginSuccess, otpStart,otpSuccess,otpFailure,createOtpStart, createOtpSuccess, createOtpFailure } from "./userRedux";

export const login = async (dispatch, data) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post("api/v2/customer/sign-in", data);
    dispatch(loginSuccess(res.data));
  } catch (error) {
    dispatch(LoginFailure(error?.data))
    window.localStorage.setItem("error", error)
    
    throw error
  }
};

export const OtpApi = async (dispatch, data) => {
  dispatch(otpStart());
  try {
    const res = await publicRequest.post("api/v2/customer/sign-in/complete", data);
    dispatch(otpSuccess(res.data));
  } catch (error) {
    dispatch(otpFailure(error.data));
    throw error
  }
};

export const CreateAccountOtp = async (dispatch, data) => {
  dispatch(createOtpStart());
  try {
    const res = await signUp.post("api/v2/customer/sign-up/complete", data);
    dispatch(createOtpSuccess(res.data));
  } catch (error) {
    dispatch(createOtpFailure(error.data));
    throw error
  }
};



