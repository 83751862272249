import React, { useState, useEffect } from "react";
import { userRequest } from "../../../requestMethod";
import { useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";

function UserPortfolio() {
  const [port, setPort] = useState(null);
  const [target, setTarget] = useState(null);
  const [locked, setLocked] = useState(null);
  const token = useSelector(
    (state) => state?.user?.currentUser?.token?.access_token
  );
  const getPortFolio = async () => {
    const response = await userRequest.get("api/v2/customer/portfolio/get", {
      headers: { Authorization: `Bearer ${token}` },
    });
    setPort(response.data.data);
    setLocked(response.data.data.investments);
    setTarget(response.data.data.target_savings);
  };
  useEffect(() => {
    getPortFolio();
  }, []);



  const singleTarget = target?.slice(0, 1);
  const singleLocked = locked?.slice(0, 1);

  console.log(singleTarget)
  return (
    <div className="flex flex-col mt-[42px]">
      <h1 className="text-[14px] font-bold mb-[24px]">Your Portfolio</h1>
      {target?.length === 0 && locked?.length === 0 ? (
        <div className="w-[100%] bg-[#fff] h-[150px] flex items-center justify-center rounded-[12px]">
          <p className="font-bold text-center">No Active Portfolio</p>
        </div>
      ) : (
        <div>
          {singleTarget?.map((target) => (
            <div className="flex justify-between items-center mb-[23px]">
              <div className="w-[45px] h-[45px] bg-white flex items-center justify-center rounded-[12px] shadow-[0px 14px 54px rgba(0, 0, 0, 0.03);]">
                <img
                  src="/assets/target.png"
                  alt=""
                  className="w-[20px] h-[14px]"
                />
              </div>
              <div>
                <p className="text-[14px] font-bold">{target?.purpose}</p>
                <p className="text-[14px] font-normal">Target Savings</p>
                <p className="text-[12px] font-normal text-[#a7a7a7]">
                {new Date(target?.start_date).toLocaleDateString()}{" "} {new Date (target?.start_date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                </p>
              </div>
              <div className="max-w-[100px]">
              <p className='text-[14px] text-[#070707] font-bold w-[100px]'><NumericFormat value={target?.target_amount}  thousandSeparator="," disabled prefix='₦' className='text-black bg-transparent w-[100px]'/></p>
              </div>
            </div>
          ))}
           {singleLocked?.map((locked) => (
            <div className="flex justify-between items-center mb-[23px]">
              <div className="w-[45px] h-[45px] bg-white flex items-center justify-center rounded-[12px] shadow-[0px 14px 54px rgba(0, 0, 0, 0.03);]">
                <img
                  src="/assets/locked.png"
                  alt=""
                  className="w-[20px] h-[14px]"
                />
              </div>
              <div>
                <p className="text-[14px] font-bold">{locked?.purpose}</p>
                <p className="text-[14px] font-normal">Locked Funds</p>
                <p className="text-[12px] font-normal text-[#a7a7a7]">
                {new Date(locked?.start_date).toLocaleDateString()}{" "} {new Date (locked?.start_date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                </p>
              </div>
              <div className="max-w-[100px]">
              <p className='text-[14px] text-[#070707] font-bold w-[100px]'><NumericFormat value={locked?.target_amount}  thousandSeparator="," disabled prefix='₦' className='text-black bg-transparent w-[100px]'/></p>
              </div>
            </div>
          ))}
        </div>
      )}
      {/* <div className='flex justify-between items-center mb-[23px]'>
        <div className='w-[45px] h-[45px] bg-white flex items-center justify-center rounded-[12px] shadow-[0px 14px 54px rgba(0, 0, 0, 0.03);]'>
            <img src="/assets/target.png" alt="" className='w-[20px] h-[14px]' />
        </div>
        <div>
            <p className='text-[14px] font-bold'>Japa</p>
            <p className='text-[14px] font-normal'>Target Savings</p>
            <p className='text-[12px] font-normal text-[#a7a7a7]'>25 Apr 2023, 14:40 pm</p>
        </div>
        <div>
            <p  className='text-[16px] font-bold'>₦10,986.32</p>
        </div>
      </div>
      <div className='flex justify-between items-center mb-[23px]'>
        <div className='w-[45px] h-[45px] bg-white flex items-center justify-center rounded-[12px] shadow-[0px 14px 54px rgba(0, 0, 0, 0.03);]'>
            <img src="/assets/locked.png" alt="" className='w-[11.24px] h-[14.7px]' />
        </div>
        <div>
            <p className='text-[14px] font-bold'>School Fees</p>
            <p className='text-[14px] font-normal'>Locked Funds</p>
            <p className='text-[12px] font-normal text-[#a7a7a7]'>25 Apr 2023, 14:40 pm</p>
        </div>
        <div>
            <p  className='text-[16px] font-bold'>₦50,000.00</p>
        </div>
      </div> */}
      {/* <div className='flex justify-between items-center mb-[23px]'>
        <div className='w-[45px] h-[45px] bg-white flex items-center justify-center rounded-[12px] shadow-[0px 14px 54px rgba(0, 0, 0, 0.03);]'>
            <img src="/assets/wallet.png" alt="" className='w-[21px] h-[14px]' />
        </div>
        <div>
            <p className='text-[14px] font-bold'>Savings Account</p>
            <p className='text-[14px] font-normal'>Wallet Balance</p>
            <p className='text-[12px] font-normal text-[#a7a7a7]'>25 Apr 2023, 14:40 pm</p>
        </div>
        <div>
            <p  className='text-[16px] font-bold'>₦350,000.00</p>
        </div>
      </div> */}
    </div>
  );
}

export default UserPortfolio;
