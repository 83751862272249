import { Route, Routes, useNavigate} from "react-router-dom";
import React, {useEffect, useState } from "react";
import Login from "./pages/Login/Login";
import Individual from "./pages/Individual/Individual";
import Merchant from "./pages/Merchant/Merchant";
import Otp from "./pages/Otp/otp";
import ForgotPassword from "./pages/Login/ForgotPassword";
import CreateAccount from "./pages/Login/CreateAccount";
import CreateOtp from "./pages/Otp/createOtp";
import ForgotPasswordComplete from "./pages/Login/ForgotPasswordComplete";
import { userRequest } from "./requestMethod";
import { useSelector } from "react-redux";
import SetPin from "./pages/Login/SetPin";

function App() {
  const [userType, setUserType] = useState("individual");
  const isIndividual = userType === "individual";
  const navigate = useNavigate();
 
 
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/create-account" element={<CreateAccount/>} />
        <Route path="/otp" element={<Otp/>}/>
        <Route path="/pin" element={<SetPin/>}/>
        <Route path="/new-account-otp" element={<CreateOtp/>}/>
        <Route path="/forgot-password" element={<ForgotPassword/>}/>
        <Route path="/forgot-password-complete" element={<ForgotPasswordComplete/>}/>
        <Route
          exact
          path="*"
          element={isIndividual ? <Individual setUserType={setUserType} userType={userType}/> : <Merchant setUserType={setUserType} userType={userType} />}
        />
      </Routes>
    </>
  );
}

export default App;
