import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Close } from "@mui/icons-material";
import { Input, Label } from "reactstrap";

function CompanyInfo({ showCompanyInfo, handleShowCompanyInfo, merchant }) {
    
  return (
    <>
      <Offcanvas
        show={showCompanyInfo}
        onHide={handleShowCompanyInfo}
        placement="end"
        className="py-[20px] pl-[30px] flex flex-col w-[505px]"
      >
        <Offcanvas.Header closeButton className="w-[100%]">
          <Offcanvas.Title className="mt-[60px]">
            Company Information
          </Offcanvas.Title>
          <Close onClick={handleShowCompanyInfo} className="mr-[-200px]" />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <div className="w-[100%] h-[110px] bg-gray-300 mt-[20px] rounded-[30px] flex items-center justify-center relative">
              <div className="w-[100%] bg-gray-100 h-[60px] rounded-[30px] flex items-center justify-center absolute top-0">
                <p className="font-semibold">Logo</p>
              </div>
              <div>
                <p className="mt-[50px] font-bold"></p>
              </div>
            </div>

            <div className="w-[100%] h-[110px] bg-gray-300 mt-[20px] rounded-[30px] flex items-center justify-center relative">
              <div className="w-[100%] bg-gray-100 h-[60px] rounded-[30px] flex items-center justify-center absolute top-0">
                <p className="font-semibold">Signature</p>
              </div>
              <div>
                <p className="mt-[50px] font-bold"></p>
              </div>
            </div>

            <div className="w-[100%] h-[900px] bg-gray-300 mt-[20px] rounded-[30px] flex items-center justify-center relative">
              <div className="w-[100%] bg-gray-100 h-[60px] rounded-[30px] flex items-center justify-center absolute top-0">
                <p className="font-semibold">Company Information</p>
              </div>
              <div className="w-[400px]">

            <Label for="company" className="text-black">
              Company Name
            </Label>
            <Input
              id="company"
              name="company"
              type="text"
              className="w-[400px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              readOnly
              value={merchant?.business_name}
            />
            <Label for="email" className="text-black">
             Email Address
            </Label>
            <Input
              id="email"
              name="email"
              type="text"
              className="w-[400px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              readOnly
              value={merchant?.support_email_address}
            />
            <Label for="phone" className="text-black">
             Phone Number
            </Label>
            <Input
              id="phone"
              name="phone"
              type="text"
              className="w-[400px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              readOnly
              value={merchant?.support_mobile_number}
            />
            <Label for="Address" className="text-black">
            Address
            </Label>
            <Input
              id="Address"
              name="Address"
              type="text"
              className="w-[400px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              readOnly
              value={merchant?.address}
            />
            <Label for="city" className="text-black">
             City
            </Label>
            <Input
              id="city"
              name="city"
              type="text"
              className="w-[400px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              readOnly
              value={merchant?.city}
            />
            <Label for="state" className="text-black">
             State
            </Label>
            <Input
              id="state"
              name="state"
              type="text"
              className="w-[400px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              readOnly
              value={merchant?.state}
            />
            <Label for="country" className="text-black">
             Country
            </Label>
            <Input
              id="country"
              name="country"
              type="text"
              className="w-[400px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              readOnly
              value={merchant?.country}
            />
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default CompanyInfo;
