/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Close } from "@mui/icons-material";
import { Input } from "reactstrap";
import {FaChevronDown,FaMoneyBill} from 'react-icons/fa'
import { Tab, Tabs } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import './QuickTransactions.css'
import TransferCanvas from "./TransferCanvas";
import ReceiptCanvas from "../../pages/Receipts/ReceiptCanvas";
import InvoiceCanvas from "../../pages/Invoice/InvoiceCanvas";
import { userRequest } from "../../requestMethod";

function QuickTransactions({merchant, chooseBus, roleName}) {
  const [openQuickModal, setOpenQuickModal] = useState(false);
  const[airtimeProvider, setAirtimeProvider] = useState(null);
  const[cableProvider, setCableProvider] = useState(null);
  const[discoProvider, setDiscoProvider] = useState(null);
  const[betProvider,setBetProvider] = useState(null);
  const[dataProvider, setDataProvider] = useState(null);
  const[ showBills, setShowBills] = useState(false)
  const handleCloseBills = () => setShowBills(false);
  const handleOpenBills = () => setShowBills(true);
  const[ showAirtime, setShowAirtime] = useState(false)
  const handleCloseAirtime = () => setShowAirtime(false);
  const handleOpenAirtime = () => setShowAirtime(true);
  const [key, setKey] = useState("home");
  const [showTransfer, setShowTransfer] = useState(false);
  const handleCloseTransfer = () => setShowTransfer(false);
  const handleShowTransfer = () => setShowTransfer(true);
  const [showReceipt, setShowReceipt] = useState(false);
  const handleCloseReceipt = () => setShowReceipt(false);
  const handleShowReceipt = () => setShowReceipt(true);
  const [showInvoice, setShowInvoice] = useState(false);
  const handleCloseInvoice = () => setShowInvoice(false);
  const handleShowInvoice = () => setShowInvoice(true);
  const token = useSelector(
    (state) => state?.user?.currentUser?.token?.access_token
  );

  const modalHandler = () => {
    setOpenQuickModal(!openQuickModal)
  }

  const getAirtimeProvider = async () => {
    const response = await userRequest.get("api/v2/customer/bills/categories/A001/products",{
      headers: {"Authorization" : `Bearer ${token}`}
    })
    setAirtimeProvider(response.data.data)
}

const getDataProvider = async () => {
  const response = await userRequest.get("api/v2/customer/bills/categories/B001/products",{
    headers: {"Authorization" : `Bearer ${token}`}
  })
  setDataProvider(response.data.data)
}

const getCableProvider = async () => {
  const response = await userRequest.get("api/v2/customer/bills/categories/E001/products",{
    headers: {"Authorization" : `Bearer ${token}`}
  })
  setCableProvider(response.data.data)
}

const getDiscoProvider = async () => {
  const response = await userRequest.get("api/v2/customer/bills/categories/C001/products",{
    headers: {"Authorization" : `Bearer ${token}`}
  })
  setDiscoProvider(response.data.data)
}

const getBetProvider = async () => {
  const response = await userRequest.get("api/v2/customer/bills/categories/F001/products",{
    headers: {"Authorization" : `Bearer ${token}`}
  })
  setBetProvider(response.data.data)
}

useEffect(() => {
  getAirtimeProvider();
},[]);

useEffect(() => {
  getDataProvider();
},[]);

useEffect(() => {
  getCableProvider();
},[]);

useEffect(() => {
  getDiscoProvider();
},[]);

useEffect(() => {
  getBetProvider();
},[]);


  
  return (
    <div className="w-[674px] h-[181px] bg-white rounded-[14px] flex flex-col justify-center  pl-[48px] relative">
      <h1 className="text-[20px] mb-[19px]">Quick Transactions</h1>
      <div className="rounded-[32px] border-none outline-none bg-[#F5F6FA] w-[604px] h-[52px] flex justify-between items-center px-[24px]" onClick={modalHandler}>
          <h1>What do you want to do?</h1>
          <FaChevronDown onClick={modalHandler}/>
      </div>
      {openQuickModal ?  <div className="w-[596px] h-[120px] bg-[#F5F6FA] z-20 absolute top-[145px] rounded-[10px] flex flex-col justify-center pl-[15px]">
          {(chooseBus || roleName === "Owner") && <div className="flex gap-[20px] mb-[8px] items-center" onClick={handleShowTransfer}>
            <img src="/assets/transfer.png"  className="w-[14px] h-[16px]" alt="" />
            <p>Transfer</p>
          </div>}
          <div className="flex gap-[20px] mb-[8px] items-center" onClick={() => {handleShowReceipt();modalHandler()}}>
            <img src="/assets/receipt.png" alt="" className="w-[14px] h-[16px]" />
            <p>Generate Receipt</p>
          </div>
          <div className="flex gap-[20px] mb-[8px] items-center" onClick={() => {handleShowInvoice();modalHandler()}}>
          <img src="/assets/invoice.png" alt="" className="w-[14px] h-[16px]"/>
            <p>Create Invoice</p>
          </div>
          <div className="flex gap-[30px] mb-[8px] items-center cursor-pointer" onClick={() => {handleOpenBills();modalHandler()}}>
            <FaMoneyBill/>
            <p>Pay Bills</p>
          </div>
      </div>: "" }

      <TransferCanvas showTransfer={showTransfer} handleCloseTransfer={handleCloseTransfer} handleShowTransfer={handleShowTransfer}  merchant={merchant} />
       <ReceiptCanvas showReceipt={showReceipt} handleCloseReceipt={handleCloseReceipt} handleShowReceipt={handleShowReceipt}/>
       <InvoiceCanvas showInvoice={showInvoice} handleCloseInvoice={handleCloseInvoice} handleShowInvoice={handleShowInvoice}/>
      <Offcanvas
          show={showBills}
          onHide={handleCloseBills}
          placement="end"
          className="py-[20px] pl-[30px] flex flex-col w-[505px] quick"
        >
          <Offcanvas.Header closeButton className="w-[100%]">
            <Offcanvas.Title className="mt-[60px]">Pay Bills</Offcanvas.Title>
            <Close onClick={handleCloseBills} className="mr-[-200px]" />
          </Offcanvas.Header>
          <Offcanvas.Body>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-[40px]"
          >
            <Tab eventKey="home" title="Cable">
            <Input
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                type="select"
              >
                <option value="">Select Type</option>
              </Input>
              <Input
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                type="select"
              >
                <option value="">Select Provider</option>
              </Input>
              <input
                type="text"
                placeholder=""
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              />
              <input
                type="text"
                placeholder=""
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              />
              <input
                type="text"
                placeholder=""
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              />
              <p>Wallet Balance: <NumericFormat
            value={merchant?.wallet?.available_balance}
            thousandSeparator=','
            prefix='₦'
            disabled
            className='text-black bg-transparent'
          /></p>
              <button className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[5rem]">Proceed</button>
            </Tab>
            <Tab eventKey="profile" title="Electricity" className="">
            <Input
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                type="select"
              >
                <option value="">Select Type</option>
              </Input>
              <Input
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                type="select"
              >
                <option value="">Select Provider</option>
              </Input>
              <input
                type="text"
                placeholder=""
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              />
              <input
                type="text"
                placeholder=""
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              />
              <input
                type="text"
                placeholder=""
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              />
              <p>Wallet Balance: <NumericFormat
            value={merchant?.wallet?.available_balance}
            thousandSeparator=','
            prefix='₦'
            disabled
            className='text-black bg-transparent'
          /></p>
              <button className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[5rem]">Proceed</button>
            </Tab>
            <Tab eventKey="contact" title="Betting">
            <Input
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                type="select"
              >
                <option value="">Select Type</option>
              </Input>
              <Input
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                type="select"
              >
                <option value="">Select Provider</option>
              </Input>
              <input
                type="text"
                placeholder=""
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              />
              <input
                type="text"
                placeholder=""
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              />
              <input
                type="text"
                placeholder=""
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              />
              <p>Wallet Balance: <NumericFormat
            value={merchant?.wallet?.available_balance}
            thousandSeparator=','
            prefix='₦'
            disabled
            className='text-black bg-transparent'
          /></p>
              <button className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[5rem]">Proceed</button>
            </Tab>
          </Tabs>
          </Offcanvas.Body>
        </Offcanvas>
        
        <Offcanvas
          show={showAirtime}
          onHide={handleCloseAirtime}
          placement="end"
          className="py-[20px] pl-[30px] flex flex-col w-[505px] quick"
        >
          <Offcanvas.Header closeButton className="w-[100%]">
            <Offcanvas.Title className="mt-[60px]">Buy Airitme/ Data</Offcanvas.Title>
            <Close onClick={handleCloseAirtime} className="mr-[-200px]" />
          </Offcanvas.Header>
          <Offcanvas.Body>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-[40px]"
          >
            <Tab eventKey="home" title="Buy Airtime">
            <Input
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                type="select"
              >
                <option value="">Select Type</option>
              </Input>
              <Input
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                type="select"
              >
                <option value="">Select Provider</option>
              </Input>
              <input
                type="text"
                placeholder=""
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              />
              <input
                type="text"
                placeholder=""
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              />
              <input
                type="text"
                placeholder=""
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              />
              <p>Wallet Balance: <NumericFormat
            value={merchant?.wallet?.available_balance}
            thousandSeparator=','
            prefix='₦'
            disabled
            className='text-black bg-transparent'
          /></p>
              <button className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[5rem]">Proceed</button>
            </Tab>
            <Tab eventKey="profile" title="Data" className="">
            <Input
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                type="select"
              >
                <option value="">Select Type</option>
              </Input>
              <Input
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
                type="select"
              >
                <option value="">Select Provider</option>
              </Input>
              <input
                type="text"
                placeholder=""
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              />
              <input
                type="text"
                placeholder=""
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              />
              <input
                type="text"
                placeholder=""
                className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] mb-[20px] text-[14px]"
              />
              <p>Wallet Balance: <NumericFormat
            value={merchant?.wallet?.available_balance}
            thousandSeparator=','
            prefix='₦'
            disabled
            className='text-black bg-transparent'
          /></p>
              <button className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px] mt-[5rem]">Proceed</button>
            </Tab>
          </Tabs>
          </Offcanvas.Body>
        </Offcanvas>
    </div>
  );
}

export default QuickTransactions;
