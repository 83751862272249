import { Close } from "@mui/icons-material";
import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import MerchantVfd from "./MerchantVfd";
import { NumericFormat } from "react-number-format";
import { Spinner } from "reactstrap";

function AccountBalance({ merchantBank, merchant, loading }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="w-[273px] h-[256px] rounded-[12px] green-gradient flex flex-col justify-center pl-[19px]">
      <p className="text-[15px] text-white mb-[72px]">Account Balance</p>
      {loading ? (
        <Spinner color="light" size="sm" className="mb-[35px]"/>
      ) : (
        <p className="text-[24px] text-white mb-[35px]">
          <NumericFormat
            value={merchant?.wallet?.available_balance}
            thousandSeparator=","
            prefix="₦"
            disabled
            className="text-white bg-transparent"
          />
        </p>
      )}

      <div>
        <button
          onClick={handleShow}
          className="w-[129px] h-[31px] rounded-[32px] bg-white border-none outline-none"
        >
          <p className="text-[#070707] text-[12px]">Fund Wallet</p>
        </button>

        <Offcanvas
          show={show}
          onHide={handleClose}
          placement="end"
          className="py-[20px] pl-[30px] flex flex-col w-[505px]"
        >
          <Offcanvas.Header closeButton className="w-[100%]">
            <Offcanvas.Title className="mt-[60px]">Fund Wallet</Offcanvas.Title>
            <Close onClick={handleClose} className="mr-[-200px]" />
          </Offcanvas.Header>
          <Offcanvas.Body>
            <input
              type="text"
              placeholder="Enter Amount"
              className="w-[421px] h-[52px] rounded-[32px] bg-[#F5F6FA] pl-[20px] my-[72px]"
            />
            <button className="bg-[#4CC800] text-center text-white w-[421px] h-[66px] rounded-[30px] text-[20px]">
              Fund
            </button>
            <div className="flex items-center justify-center flex-col mt-[28px]">
              <p>Or</p>
              <p>
                Copy your virtual account number and make a direct bank
                transfer.{" "}
              </p>
              <div className="w-[421px] h-[52px] my-[20px]">
                <MerchantVfd merchantBank={merchantBank} />
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </div>
  );
}

export default AccountBalance;
